import React from 'react';
import './MarkComplete.scss'

type MarkCompleteProps = {
    completed: boolean,
    onComplete: ()=>void,
    onInComplete: ()=>void,
    className?: string,
}

const MarkComplete = (props: MarkCompleteProps) => {

    const toggleComplete = () => {
        if(props.completed){
            props.onInComplete()
            return
        }

        props.onComplete()
    }

    return (
        <span
            onClick={toggleComplete}
            className={`mark-complete ${props.completed ? '' : 'not-complete'} ${props.className}`}
        >
            <div className="checkmark_circle" />
            <div className="checkmark_stem" />
            <div className="checkmark_kick" />
        </span>
    )
};

export default MarkComplete;