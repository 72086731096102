import React, {useEffect, useState} from "react";
import {Col, Row, Image, Table} from 'react-bootstrap'
import moment from 'moment';
import {useTranslation} from "react-i18next";
import persistentStore from "../../stores/persistentStore";
import {IUser} from "../../interfaces/IUser";
import {IInvoiceItem} from "../../interfaces/IInvoiceItem";
import uiStore from "../../stores/uiStore";
import {IInvoice} from "../../interfaces/IInvoice";

const InvoiceView = (props: any) => {
    const invoice: IInvoice = props.invoice
    const buyer: IUser = invoice.buyer
    const seller: IUser = invoice.seller
    const {t} = useTranslation()
    const [invoiceStatus, setInvoiceStatus] = useState('')

    const subtotalInCents: number = invoice.invoice_items.reduce((carry: number, invoiceItem: IInvoiceItem) => {
        carry += invoiceItem.quantity * invoiceItem.price_in_cents
        return carry
    }, 0)
    const vatInCents: number = invoice.invoice_items.reduce((sum: number, invoiceItem: IInvoiceItem) => {
        sum += invoiceItem.vat_in_cents
        return sum
    }, 0)
    const orderTotalInCents: number = invoice.total_price_in_cents

    const InvoiceLogo = (props: any) => {
        const {seller}: { seller: IUser } = props
        const InvoiceLogo = seller?.logo
            ? <Image src={`${persistentStore.apiUrl}/img/logo/${seller.logo}`} alt='logo' className='avatar'/>
            : <h2>{seller?.name}</h2>

        return (InvoiceLogo);
    }

    useEffect(() => {
        if(! invoice){
            return
        }

        switch(invoice.status){
            case 'paid':
                setInvoiceStatus(t('paid'))
                break;
            case 'open':
                setInvoiceStatus(t('open'))
                break;
            default:
                setInvoiceStatus(invoice.status)
        }
    }, [invoice, t])

    if (!invoice) {
        return null
    }

    return (
        <Row className='col-md-12'>
            <Col md={12} className="border p-3" style={{backgroundColor: 'white'}}>
                <Row>
                    <Col md={6}><InvoiceLogo seller={invoice?.seller}/></Col>
                    <Col md={6} className="text-right">
                        <h3 className="text-uppercase">{invoiceStatus}</h3>
                        <p>{t('Invoice')} #{invoice?.public_id}</p>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col md={12}>
                        <Row>
                            <Col md={12}>
                                {seller?.invoice_name && <div>{seller?.invoice_name}</div>}
                                {seller?.invoice_address && <div>{seller?.invoice_address}</div>}
                                {seller?.vat_number && <div>{t('VAT')}: {seller?.vat_number}</div>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pt-5">
                    <Col md={12}>
                        <Row>
                            <Col md={2}>
                                <strong>{t('Date of issue')}</strong>
                                <p>{moment(invoice?.created).format('DD-M-YYYY')}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col md={12}>
                        <Row>
                            <Col md={12}>
                                <strong>{t('Billed to')}</strong>
                                <div>{buyer?.name}</div>
                                {buyer?.invoice_name && (buyer?.invoice_name !== buyer?.name) && (
                                    <div>{buyer?.invoice_name}</div>
                                )}
                                {buyer?.invoice_address && <div>{buyer?.invoice_address}</div>}
                                {buyer?.vat_number && <div>{t('VAT')}: {buyer?.vat_number}</div>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col md={12}>
                        <Row>
                            <Col md={12} className="pt-3">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>{t('Description')}</th>
                                            <th className="text-right">{t('Qty')}</th>
                                            <th className="text-right">{t('Price')}</th>
                                            <th className="text-right">{t('Subtotal')}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {invoice.invoice_items.map((invoiceItem: IInvoiceItem, index: number) => (
                                            <tr key={index}>
                                                <td>{invoiceItem.description}</td>
                                                <td className="text-right">{invoiceItem.quantity}</td>
                                                <td className="text-right">{(invoiceItem.price_in_cents / 100).toLocaleString(uiStore.locale, uiStore.moneyOptions)}</td>
                                                <td className="text-right">{((invoiceItem.price_in_cents / 100) * invoiceItem.quantity).toLocaleString(uiStore.locale, uiStore.moneyOptions)}</td>
                                            </tr>
                                    ))}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">{t('Subtotal')}</td>
                                        <td className="text-right">{(subtotalInCents/100).toLocaleString(uiStore.locale, uiStore.moneyOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">{t('VAT')}</td>
                                        <td className="text-right">{(vatInCents / 100).toLocaleString(uiStore.locale, uiStore.moneyOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">{t('Total')}</td>
                                        <td className="text-right">{(orderTotalInCents / 100).toLocaleString(uiStore.locale, uiStore.moneyOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}></td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default InvoiceView;
