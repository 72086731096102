import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {trials} from "../../api/trials";
import persistentStore from "../../stores/persistentStore";
import {browserName, browserVersion, deviceType, getUA, osName, osVersion} from 'react-device-detect'
import {useQuery} from "react-query";
import {info as infoApi} from "../../api/info";
import './TrialPage.scss'
import Button from "react-bootstrap/Button";
import qs from "querystring";
import {toast} from "react-toastify";

const TrialPage = () => {
    const {t} = useTranslation()
    const [email, setEmail] = useState('')
    const infoQuery = useQuery('info', () => infoApi.ip())

    // when there is an email in the url, use it to pre-populate the inputfield
    useEffect(() => {
        const queryString = window.location.search.startsWith('?')
            ? window.location.search.slice(1)
            : window.location.search
        const queryParams = qs.parse(queryString)

        if (queryParams.hasOwnProperty('email') && typeof queryParams.email === 'string') {
            setEmail(queryParams.email)
        }
    }, [])

    const startTrial = (event: any) => {
        event.preventDefault()

        // remove all local cache and stuff
        persistentStore.emptyStore()

        if (infoQuery.data) {
            const browserInfo: BrowserInfo = {
                user_agent: getUA,
                browser: browserName,
                browser_version: browserVersion,
                os: osName,
                os_version: osVersion,
                // @ts-ignore
                timezone: infoQuery.data.time_zone.name,
                // @ts-ignore
                local_time: infoQuery.data.time_zone.current_time,
                device: deviceType,
                // @ts-ignore
                ip: infoQuery.data.ip,
                // @ts-ignore
                threat: infoQuery.data.threat,
                // @ts-ignore
                city: infoQuery.data.city,
                // @ts-ignore
                country: infoQuery.data.country_name,
            }

            trials.createTrialAccount(email, browserInfo)
                .then((result) => {
                    console.log(result)
                    window.location.href = window.location.origin + '/admin/course-manager'
                })
                .catch((error) => {
                    console.error(error.response)
                    toast.error(t('Could not create a trial account. Please try again'))
                })
        }
    }

    return (
        <div className="register">
            <div className="row">
                <div className="col-md-3 register-left">
                    <img src="https://image.ibb.co/n7oTvU/logo_white.png" alt=""/>
                    <h3>{t('Welcome')}</h3>
                    <p>{t('You are 10 seconds away from launching your first course!')}</p>
                </div>

                <div className="col-md-9 register-right">
                    <h3 className="register-heading col-12">
                        {t('Start your free week now')}
                    </h3>

                    <form className="register-form" onSubmit={startTrial}>
                        <p>
                            {t('You can tryout all functionalities of the TrainingsOmgeving for a whole week')}
                        </p>

                        <p>{t('Reasons to start your trial week now')}</p>
                        <ul className='benefits'>
                            <li>{t('You\'ll start immediately')}</li>
                            <li>{t('Create as many online trainings as you want')}</li>
                            <li>{t('Sell your training to your target audience')}</li>
                            <li>{t('Provide free trainings as a lead magnet to establish expert status')}</li>
                            <li>{t('Setup a free training as a lead magnet')}</li>
                            <li>{t('No costs, trial week stops automatically in 1 week')}</li>
                        </ul>

                        <div className="col-md-6 mt-4">
                            <label htmlFor='inputEmail' className='mb-3'>
                                {t('Provide your emailaddress and we log you right in')}
                            </label>

                            <div className="form-group">
                                <input
                                    type="email"
                                    id='inputEmail'
                                    className="form-control"
                                    placeholder={t('Your Email') + " *"}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    autoFocus
                                />
                                <small>{t('We do not share your emailaddress')}</small>
                            </div>
                            <Button className='btnRegister mt-3' type='submit'>{t('Start!')}</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default TrialPage;

