import {ILesson} from "../interfaces/ILesson";
import persistentStore from "../stores/persistentStore";
import axios, {AxiosResponse} from "axios";
import errorChecker from "./errorchecker";
import {IUserLesson} from "../interfaces/IUserLesson";

const lessonRequests = () => {
    const add = async (lesson: ILesson) => {
        const path = `/lessons`;
        const config = persistentStore.authHeader;

        return axios.post(persistentStore.apiUrl + path, lesson, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.lesson) {
                    console.log('The response did not contain lesson');
                    throw Error('The response did not contain lesson');
                }

                return data.lesson;
            });
    }

    const markInComplete = async (lessonId: string) => {
        console.log('clicked: markInComplete')
        return markComplete(lessonId, false)
    }

    const markComplete = async (lessonId: string, complete: boolean = true) => {
        // store.updateLessonInModule(lessonId, {completed: complete})
        const path = `/lessons-users/completed/${lessonId}`;
        const config = persistentStore.authHeader;

        return axios.post(persistentStore.apiUrl + path, [], config)
            .then(
                response => errorChecker(response)).then(response => {
                const data = response.data.data;
                if (!data.lesson_user) {
                    console.log('The response did not contain lesson_user');
                    throw Error('The response did not contain lesson_user');
                }

                return data.lesson_user
            });
    }

    /**
     * This gets the lessons that the user already (partly) followed.
     * It provides progress-info like whether or not the lesson is completed and how far the user has seen the movie.
     *
     * @param courseSlug
     */
    const getUserLessonsOfCourse = async (courseSlug: string) => {
        const path = `/lessons-users/course/${courseSlug}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config)
            .then(
                response => errorChecker(response)).then(response => {
                const data = response.data.data;
                if (!data['lessons-users']) {
                    console.log('The response did not contain users lessons');
                    throw Error('The response did not contain users lessons');
                }

                return data['lessons-users'];
            });
    }

    const getBySlug = async (lessonSlug: string) => {
        const path = `/lessons/get-by-slug/${lessonSlug}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config)
            .then(
                response => errorChecker(response)).then(response => {
                const data = response.data.data;
                if (!data.lesson) {
                    console.log('The response did not contain lesson');
                    throw Error('The response did not contain lesson');
                }

                return data.lesson;
            });
    }

    const getPreviewLessonsByCourseSlug = async (courseSlug: string) => {
        const path = `/lessons/preview-by-course-slug/${courseSlug}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config)
            .then(
                response => errorChecker(response)).then(response => {
                const data = response.data.data;
                if (!data.lessons) {
                    console.log('The response did not contain lessons');
                    throw Error('The response did not contain lessons');
                }

                return data.lessons;
            });
    }

    const getByCourse = async (courseId: string) => {
        const path = `/courses/${courseId}/lessons`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config)
            .then(
                response => errorChecker(response)).then(response => {
                const data = response.data.data;
                if (!data.lessons) {
                    console.log('The response did not contain lessons');
                    throw Error('The response did not contain lessons');
                }

                return data.lessons;
            });
    }

    const getById = async (lessonId: string) => {
        const path = `/lessons/${lessonId}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config)
            .then(
                response => errorChecker(response)).then(response => {
                const data = response.data.data;
                if (!data.lesson) {
                    console.log('The response did not contain lesson');
                    throw Error('The response did not contain lesson');
                }

                return data.lesson;
            });
    }

    const updateProgress = async (lessonId: string, secondsPlayed: number) => {
        // const updatedLesson: IUserLesson = Object.assign({}, store.selectedLesson, {last_position: secondsPlayed})
        // store.updateLessonInModule(lessonId, {last_position: secondsPlayed})
        const path = `/lessons-users/${lessonId}`;
        const config = persistentStore.authHeader;

        return axios.post(persistentStore.apiUrl + path, {last_position: secondsPlayed}, config)
            .then(
                response => errorChecker(response)).then(response => {
                const data = response.data.data;
                if (!data['lesson-user']) {
                    console.log('The response did not contain lesson user');
                    throw Error('The response did not contain lesson user');
                }

                return data['lesson-user']
            });
    }

    const updateMany = async (lessons: (ILesson | IUserLesson)[]) => {
        const path = `/lessons/update-many`;
        const config = persistentStore.authHeader;

        return axios.put(persistentStore.apiUrl + path, lessons, config)
            .then(
                response => errorChecker(response)).then(response => {
                const data = response.data.data;
                if (!data.lessons) {
                    console.log('The response did not contain lessons')
                    throw Error('The response did not contain lessons')
                }

                return data.lessons;
            });
    }

    const remove = async (id: string) => {
        const path = `/lessons/${id}`;
        const config = persistentStore.authHeader;

        return axios.delete(persistentStore.apiUrl + path, config)
            .then(
                response => errorChecker(response)).then(response => {
                const data = response.data.data;
                if (!data.lesson) {
                    console.log('The response did not contain lesson');
                    throw Error('The response did not contain lesson');
                }

                return data.lesson;
            });
    }

    const update = async (lesson: ILesson) => {
        const path = `/lessons/${lesson.slug}`;
        const config = persistentStore.authHeader;

        return axios.put(persistentStore.apiUrl + path, lesson, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.lesson) {
                console.log('The response did not contain lesson');
                throw Error('The response did not contain lesson');
            }

            return data.lesson;
        });
    }

    type uploadFileProps = {
        data: FormData,
        lessonId: string
    }
    const uploadFile = async (props: uploadFileProps) => {
        const path = `/lessons/upload-file/${props.lessonId}`
        return axios.post(persistentStore.apiUrl + path, props.data, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then((response: AxiosResponse) => {
                const data = response.data.data;
                if (!data.file) {
                    console.log('The response did not contain file');
                    throw Error('The response did not contain file');
                }

                return data.file;
            })
    }

    return {
        add,
        getByCourse,
        getPreviewLessonsByCourseSlug,
        getById,
        getBySlug,
        getUserLessonsOfCourse,
        markComplete,
        markInComplete,
        remove,
        update,
        updateMany,
        updateProgress,
        uploadFile,
    }
}

export const lessons = lessonRequests()
