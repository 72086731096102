import {INewQuiz, IQuiz} from "../interfaces/IQuiz";
import persistentStore from "../stores/persistentStore";
import axios, {AxiosResponse} from "axios";
import errorChecker from "./errorchecker";

const quizzesRequests = () => {

    const getAll = async () => {
        const path = `/quizzes/`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.quizzes) {
                console.log('The response did not contain quizzes');
                throw Error('The response did not contain quizzes');
            }

            return data.quizzes;
        });
    }


    const getByCourse = async (courseSlug: string) => {
        const path = `/quizzes/get-by-course/${courseSlug}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.quizzes) {
                console.log('The response did not contain quizzes');
                throw Error('The response did not contain quizzes');
            }

            return data.quizzes;
        });
    }

    const update = async (quiz: IQuiz) => {
        const path = `/quizzes/edit/${quiz.slug}`;
        const config = persistentStore.authHeader;

        return axios.put(persistentStore.apiUrl + path, quiz, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.quiz) {
                console.log('The response did not contain quiz');
                throw Error('The response did not contain quiz');
            }

            return data.quiz;
        });
    }

    const add = async (quiz: INewQuiz) => {
        const path = `/quizzes/add`;
        const config = persistentStore.authHeader;

        return axios.post(persistentStore.apiUrl + path, quiz, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.quiz) {
                console.log('The response did not contain quiz');
                throw Error('The response did not contain quiz');
            }

            return data.quiz;
        });
    }

    const get = async (quizSlug: string) => {
        const path = `/quizzes/view/${quizSlug}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.quiz) {
                    console.log('The response did not contain quiz');
                    throw Error('The response did not contain quiz');
                }

                return data.quiz;
            });
    }

    const del = async (quizSlug: string) => {
        const path = `/quizzes/${quizSlug}`;
        const config = persistentStore.authHeader;

        return axios.delete(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.quiz) {
                    console.log('The response did not contain quiz');
                    throw Error('The response did not contain quiz');
                }

                return data.quiz;
            });
    }

    const uploadImage = async (data: FormData) => {
        const path = `/quizzes/upload-image`
        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then((response: AxiosResponse) => {
                const data = response.data.data;
                if (!data.image) {
                    console.log('The response did not contain image');
                    throw Error('The response did not contain image');
                }

                return data.image;
            })
    }

    
    return {
        getAll,
        getByCourse,
        update,
        add,
        get,
        del,
        uploadImage
    }
}

export const quizzes = quizzesRequests()
