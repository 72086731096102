import React from "react";
import {IQuestion} from "../interfaces/IQuestion";
import QuestionListItem from "../components/QuestionListItem";

type RenderQuizQuestionProps = {
    item: IQuestion,
}

const RenderQuizQuestion = (props: RenderQuizQuestionProps) => {
    const {item: question} = props

    return <QuestionListItem question={question} />
}

export default RenderQuizQuestion