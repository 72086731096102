import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ICourse} from '../../interfaces/ICourse';
import {courses as coursesApi} from "../../api/courses";
import Loader from "../../components/Loader";
import EnrollForm from "./EnrollForm";
import EnrollInfo from "./EnrollInfo";
import CourseContext from "../../contexts/CourseContext";
import {payments} from "../../api/payments";
import {toast} from "react-toastify";
import persistentStore from "../../stores/persistentStore";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons/faEnvelope";
import {faHeadset} from "@fortawesome/free-solid-svg-icons/faHeadset";
import {observer} from "mobx-react";
import './Enroll.scss'
import {INewOrder} from "../../interfaces/INewOrder";
import {ISetting} from "../../interfaces/ISetting";
import {useQuery} from "react-query";
import {settings as settingsApi} from "../../api/settings";
import SettingContext from "../../contexts/SettingContext";
import TestBadge from "../../components/TestBadge";
import {INewUser} from "../../interfaces/INewUser";

type EnrollProps = {}

const Enroll = (props: EnrollProps) => {
    const {courseSlug} = useParams<{ courseSlug: string }>()
    const [course, setCourse] = useState<ICourse | undefined>(undefined)
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const startLoading = () => setLoading(true)
    const stopLoading = () => setLoading(false)
    const [settings, setSettings] = useState<ISetting[]>([])
    const [isTestMode, setIsTestMode] = useState(false)
    const history = useHistory()

    const settingsQuery = useQuery(
        'courseSettings',
        () => settingsApi.getForCourse(courseSlug),
        {
            onSuccess: (settings: ISetting[]) => setSettings(settings)
        }
    )

    // if the course is public, send the user to the LoginStudent page where access can be requested via a loginlink
    useEffect(() => {
        if (course && course.type === 'public') {
            history.push(`/course/${courseSlug}/login`)
        }
        // we don't want eslint complaining about missing 'history' dependency
        // eslint-disable-next-line
    }, [course, courseSlug])

    useEffect(() => {
        if (settingsQuery.data) {
            const saleStatusSetting = settingsQuery.data.find((setting: ISetting) => setting.name === 'saleStatus')
            const testMode = saleStatusSetting
                ? saleStatusSetting.value === 'test'
                : false

            setIsTestMode(testMode)
        }
    }, [settingsQuery.data])

    useEffect(() => {
        startLoading()
        coursesApi.get(courseSlug)
            .then((course: ICourse) => {
                setCourse(course)
                stopLoading()
            })
            .catch((error) => {
                stopLoading()
                console.error(error.response)
            })
        // eslint-disable-next-line
    }, [courseSlug])

    if (!course) {
        return <Loader/>
    }

    const handleOrderSubmit = (props: INewUser) => {
        startLoading()

        const order: INewOrder = {
            customer: {
                email: props.email,
                name: props.name,
                invoice_name: props.invoice_name,
                invoice_address: props.invoice_address,
                vat_number: props.vat_number
            },
            order: [{quantity: 1, course_slug: courseSlug}],
            payment_method: 'ideal'
        }

        payments.place(order)
            .then((response: any) => {
                if (response.success === false) {
                    toast.error(response.errors.title)
                    return
                }

                if (response.data.testOrderMsg) {
                    toast.warning(response.data.testOrderMsg)
                }

                // store the order for when the user gets back
                // The user should not provide all info again
                persistentStore.setOrder(response.data.order)

                window.location = response.data.checkout_url
            })
            .catch((error) => {
                if (!error.response.data.errors) {
                    toast.error('Er is iets fout gegaan bij het betalen')
                    return
                }
                const errors = error.response.data.errors
                const detail = errors.detail

                if (detail === 'You had already purchased this course.') {
                    toast.error(t('You already purchased this course. Try logging in'))
                    return
                }

                toast.error(detail)
            })
            .finally(() => stopLoading())
    }

    return (
        <CourseContext.Provider value={course}>
            <SettingContext.Provider value={settings}>
                <div className="container-fluid page__container">

                    <div className='d-flex flex-row enroll-header'>

                        {course?.logo_url && (
                            <div className='logo'>
                                <img
                                    src={persistentStore.apiUrl + course.logo_url}
                                    alt='logo'
                                    style={{maxWidth: '45px'}}
                                />
                            </div>
                        )}

                        <div className='push mr-2 mt-3'>
                            <FontAwesomeIcon icon={faHeadset} className='mr-1'/>
                            {t('Need help?')}
                        </div>

                        <div className='mt-3'>
                            <FontAwesomeIcon icon={faEnvelope} className='mr-1'/>
                            support@trainingsomgeving.local
                        </div>
                    </div>

                    <Card>
                        <Card.Body className='d-flex d-row enroll-card'>
                            <EnrollForm
                                className='mt-4 col-6'
                                handleOrder={handleOrderSubmit}
                                loading={loading}
                            />

                            <EnrollInfo
                                className='col-6'
                            />
                        </Card.Body>
                    </Card>

                    {isTestMode && (
                        <div className='mt-3'>
                            <TestBadge text={t('payments are in testmode')}/>
                        </div>
                    )}

                </div>
            </SettingContext.Provider>
        </CourseContext.Provider>
    )
};

export default observer(Enroll);