import React from 'react';
import {useQuery} from "react-query";
import {mails} from "../../../api/mails";
import DataTable from "react-data-table-component";
import persistentStore from "../../../stores/persistentStore";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {IMail} from "../../../interfaces/IMail";

type MailTableProps = {
    userId: string,
}

const MailTable = (props: MailTableProps) => {
    const {userId} = props
    const {t} = useTranslation()
    const mailQuery = useQuery(
        ['mails', userId],
        () => mails.fromUser(userId)
    )

    const columns = [
        {
            name: t('Sent'),
            selector: (mail: IMail) => mail.sent_date,
            sortable: true,
            // width: '200px',
            cell: (mail: IMail) => moment(mail.sent_date).format('Y-MM-DD H:mm:ss')
        },
        {
            name: t('Subject'),
            selector: (mail: IMail) => mail.subject,
            sortable: true,
        },
        {
            name: t('Content'),
            // width: '200px',
            cell: (mail: IMail) => (
                <p dangerouslySetInnerHTML={{__html: mail.content}}></p>
            )
        },
    ]

    return (
        <DataTable
            className=''
            columns={columns}
            data={mailQuery.data || []}
            pagination
            paginationPerPage={persistentStore.paginationLength}
            paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
            onChangeRowsPerPage={(rowCount) => persistentStore.setPaginationLength(rowCount)}
            noHeader={true}
            defaultSortFieldId={'sent_date'}
            defaultSortAsc={false}
            pointerOnHover={true}
        />
    )
};

export default MailTable;