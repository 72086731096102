import {autoSave} from './autoStore'
import {makeAutoObservable} from "mobx";
import {IModule} from "../interfaces/IModule";
import {IUser} from "../interfaces/IUser";
import {IOrder} from "../interfaces/IOrder";

class PersistentStore {
    apiUrl: string
    token: string
    IPDToken: string
    openModules: string[]
    autoplay: boolean
    selectedLessonId: number | undefined
    template: string = 'TF'
    playFullScreen: boolean
    user: IUser | undefined
    paginationLength:number = 10
    pageLengthOptions: number[] = [10,20,50,100,150]
    order: IOrder | undefined = undefined
    urlToReturnTo: string = '/'

    isImpersonating = false
    userWhoIsImpersonating: {user: IUser, token: string} | undefined = undefined

    setPaginationLength(newLength: number) {
        this.paginationLength = newLength
    }

    constructor() {
        // store changes to the state in localStorage
        makeAutoObservable(this)
        this.apiUrl = '' // initial value setup must be placed here before update by autoSave
        this.apiUrl = ''
        this.token= ''
        this.IPDToken= ''
        this.openModules = []
        this.autoplay = true
        this.selectedLessonId = undefined
        this.template = 'TF'
        this.playFullScreen = false
        autoSave(this, 'persistentStore')
    }

    /**
     * When we're impersonating, we want to become an other user.
     *
     * To do that:
     * - set aside the information of currently logged in user
     * - taken on the information of the user we want to become
     *
     * @param user
     * @param token
     * @param urlToReturnTo
     */
    impersonate(user: IUser, token: string, urlToReturnTo: string)
    {
        // can only impersonate when someone is logged in
        if(persistentStore.user === undefined){
            console.error('Cannot impersonate when no user is logged in')
            return
        }

        if(persistentStore.token === undefined){
            console.error('Cannot impersonate when no token is set')
            return
        }

        // backup the token and user information
        this.userWhoIsImpersonating = {
            user: persistentStore.user,
            token: persistentStore.token,
        }

        this.urlToReturnTo = urlToReturnTo

        // 'login' as the other user
        persistentStore.user = user
        persistentStore.setToken(token)
        this.isImpersonating = true
    }

    /**
     * Reset everything done in impersonate()
     */
    stopImpersonating(){
        if(this.userWhoIsImpersonating === undefined){
            console.error('Cannot stop impersonating as there is no info about the original user')
            return
        }

        // 'login' as yourself again
        persistentStore.user = this.userWhoIsImpersonating.user
        persistentStore.setToken(this.userWhoIsImpersonating.token)

        this.userWhoIsImpersonating = undefined
        this.isImpersonating = false
    }

    get isAdmin() {
        return this.user?.is_admin === true
    }

    get loggedIn() {
        return this.user !== undefined
    }

    setPlayFullScreen(setFullScreen: boolean) {
        this.playFullScreen = setFullScreen
    }

    setAutoplay(doAutoplay: boolean) {
        this.autoplay = doAutoplay
    }

    setUser (user: IUser) {
        this.user = user
    }

    setApiUrl(url: string){
        this.apiUrl = url
    }

    changeOpenModule(module: IModule, open: boolean){
        if(open && module.id){
            // add the module
            const updatedOpenModules = this.openModules
            updatedOpenModules.push(module.id)
            this.openModules = updatedOpenModules
            return
        }

        // remove the module
        this.openModules = this.openModules.filter((openModuleId: string) => openModuleId !== module.id)
    }

    toggleModule(moduleId: string){
        if(this.openModules.includes(moduleId)){
            return this.closeModule(moduleId)
        }

        return this.openModule(moduleId)
    }

    closeModule(moduleId: string){
        if(this.openModules.includes(moduleId)){
            this.openModules = this.openModules.filter((openModuleId: string) => openModuleId !== moduleId)
        }
    }

    openModule(moduleId: string){
        if(! this.openModules.includes(moduleId)){
            this.openModules = [...this.openModules, moduleId]
        }
    }

    // empty...
    emptyStore() {
        this.token = ''
        this.user = undefined
        this.openModules = []
        this.selectedLessonId = undefined
        this.token = ''
    }

    setSelectedLessonId(lessonId: number | undefined) {
        this.selectedLessonId = lessonId
    }

    setToken(token: string) {
        this.token = token
    }

    setIPDToken(token: string) {
        this.IPDToken = token
    }

    get authHeader() {
        return {
            headers: {
                Accept: 'application/json',
                Authorization: 'bearer ' + this.token
            }
        }
    }

    setOrder(order: IOrder | undefined){
        this.order = order
    }
}

const persistentStore = new PersistentStore()
export default persistentStore
