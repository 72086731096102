import React from 'react';
import './Loader.scss'

type LoaderProps = {}

const Loader = (props: LoaderProps) => {

    return (
        <div className='loading'>Loading&#8230; </div>
    )
};

export default Loader;