import React, {FormEvent, useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import CourseContext from "../../contexts/CourseContext";
import {INewUser} from "../../interfaces/INewUser";

type EnrollFormProps = {
    className?: string,
    loading: boolean,
    handleOrder: (newUser: INewUser) => void
}

const EnrollForm = (props: EnrollFormProps) => {
    const {t} = useTranslation()
    const [isBusinessExpense, setIsBusinessExpense] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [invoiceName, setInvoiceName] = useState('')
    const [invoiceAddress, setInvoiceAddress] = useState('')
    const [vatNumber, setVatNumber] = useState('')
    const course = useContext(CourseContext)

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        props.handleOrder({
            name: name,
            email: email,
            invoice_address: invoiceAddress,
            invoice_name: invoiceName,
            vat_number: vatNumber,
        })
    }

    return (
        <>
            <div className={`d-flex flex-column ${props.className}`}>
                <h4>{t('Enroll for course')}: {course.title}</h4>
                <span>{course.description}</span>
                {/*<EnrollPromises/>*/}

                <form onSubmit={handleSubmit} autoComplete='off'>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputName">{t('Name')}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputName"
                                placeholder={t('John Doe')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputEmail">{t('Email')}</label>
                            <input
                                type="email"
                                className="form-control"
                                id="inputEmail"
                                placeholder={t('Your emailaddress')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="defaultCheck1"
                            checked={isBusinessExpense}
                            onChange={(e) => setIsBusinessExpense(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="defaultCheck1">
                            {t('Is this a business expense?')}
                        </label>
                    </div>

                    {isBusinessExpense && (
                        <>
                            <div className="form-group mt-3">
                                <label htmlFor="inputInvoiceName">{t('Company name')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputInvoiceName"
                                    value={invoiceName}
                                    onChange={(e) => setInvoiceName(e.target.value)}
                                    placeholder={t("My Company")}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputInvoiceAddress">{t('Address, city and zipcode')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputInvoiceAddress"
                                    value={invoiceAddress}
                                    onChange={(e) => setInvoiceAddress(e.target.value)}
                                    placeholder={t("1234 Main St, Roseville NH 11523")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputVatNumber">{t('VAT ID')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputVatNumber"
                                    value={vatNumber}
                                    onChange={(e) => setVatNumber(e.target.value)}
                                    placeholder={t("NL001234567B01")}
                                />
                            </div>
                        </>
                    )}

                    <Button type="submit" className="btn btn-primary mt-4">
                        {! props.loading && t('Place your order now')}
                        {props.loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                    </Button>
                </form>
            </div>
        </>
    )
};

export default EnrollForm;