import {useState} from "react";

export const useLoading = (defaultValue: boolean = false) => {
    const [loading, setLoading ] = useState(defaultValue)

    const startLoading: (() => void) = () => setLoading(true)
    const stopLoading: (() => void) = () => setLoading(false)

    return [
        loading,
        startLoading,
        stopLoading
    ] as const
}

