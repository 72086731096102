import {IModule} from "../interfaces/IModule";
import {useTranslation} from "react-i18next";
import React, {ChangeEvent, useContext, useState} from "react";
import CourseContext from "../contexts/CourseContext";
import {useQueryClient} from "react-query";
import {ILesson} from "../interfaces/ILesson";
import {modules} from "../api/modules";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {Button} from "react-bootstrap";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";

type ModuleListItemProps = {
    module: IModule
}
const ModuleListItem = (props: ModuleListItemProps) => {
    const {module} = props
    const {t} = useTranslation()
    const course = useContext(CourseContext)
    const [title, setTitle] = useState(module.title)
    const queryClient = useQueryClient()
    const [edit, setEdit] = useState(false)

    const deleteModule = (id: string) => {
        // console.log(id)
        // console.log(course.lessons)
        // check whether this module has lessons
        const lessonsInThisModule: ILesson[] = course.lessons.filter((lesson) => lesson.module_id === id)

        if(lessonsInThisModule.length > 0){
            const lessonsInModule = lessonsInThisModule
                .map((lesson) => lesson.title)
                .join(', ')
            alert(`Note: the lessons in this module will be deleted as well: ${lessonsInModule}. Please move them out of the module first and try again.`)

            return
        }

        modules.remove(id)
            .then((result) => {
                queryClient.invalidateQueries('courses')
                toast.success(t('Module removed'))
            })
            .catch((error) => {
                console.error(error.response)
                toast.error(t('Module could not be removed'))
            })
    }

    const selectAll = (event: any) => {
        event.target.select()
    }

    const shouldSave = (event: any) => {
        if(event.code === 'Enter'){
            event.preventDefault()
            saveModule()
        }
    }

    const saveModule = () => {

        const updatedModule: IModule = Object.assign(
            module,
            {title: title}
        )

        modules.update(updatedModule)
            .then((result) => {
                queryClient.invalidateQueries('courses')
                setEdit(false)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const updateTitle = (event: ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value)
    }

    return <div className="nestable-item nestable-item-handle">
        <div className="nestable-handle">
            <FontAwesomeIcon icon={faBars} className="material-icons"/>
        </div>
        <div className="nestable-content">
            <div className="media align-items-center">
                <div className="media-left"/>
                <div className="media-body">
                    {!edit && (
                        <h5 className="card-title h6 mb-0">
                            Module: {module.title}
                        </h5>
                    )}

                    {edit && (
                        <input
                            type='text'
                            value={title}
                            onChange={updateTitle}
                            autoFocus={true}
                            onFocus={selectAll}
                            onKeyUp={shouldSave}
                            onBlur={() => setEdit(false)}
                        />
                    )}
                </div>
                <div className="media-right">
                    {! edit && (
                        <Button variant={"link"} onClick={() => setEdit(true)}>
                            <FontAwesomeIcon icon={faPencilAlt} className="material-icons"/>
                        </Button>
                    )}
                    {edit && (
                        <Button variant={"link"} onClick={() => saveModule()}>
                            <FontAwesomeIcon icon={faSave} className="material-icons"/>
                        </Button>
                    )}
                    <Button variant={"link"} onClick={() => module.id && deleteModule(module.id)}>
                        <FontAwesomeIcon icon={faTrashAlt} className="material-icons text-danger"/>
                    </Button>
                </div>
            </div>
        </div>
    </div>
}

export default ModuleListItem