import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from "react-dropzone";
import {Image, Alert, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import store from "../../../stores/store";
import {toast} from "react-toastify";
import {quizzes} from "../../../api/quizzes";
import persistentStore from "../../../stores/persistentStore";

type QuizImageProps = {
    imageUrl: string | undefined,
    setImageUrl: (url: string) => void,
}

const QuizImage = (props: QuizImageProps) => {
    const {setImageUrl, imageUrl} = props
    const [selectedFile, setSelectedFile] = useState('')
    const [selectedFileName, setSelectedFileName] = useState('')
    const [selectedFileNamePreview, setSelectedFileNamePreview] = useState('')
    const [uploadError, setUploadError] = useState<string | undefined>()
    const [imageSource, setImageSource] = useState<string|undefined>()
    const [deletePoster, setDeletePoster] = useState(false)
    const {t} = useTranslation()

    const onDrop = useCallback(acceptedFiles => {
        // clear any upload errors
        setUploadError(undefined)

        const fileToUpload = acceptedFiles[0]
        if (!fileToUpload) {
            return
        }

        const sizeInMB: number = fileToUpload.size / 1000000;
        const fileTooLarge = sizeInMB > store.quizImageSizeLimit

        if (fileTooLarge) {
            const errorMessage: string = t('This file is over the maximum file limit of')
                + ' ' + store.quizImageSizeLimit + 'MB '
                + `): ${sizeInMB.toFixed(2)}M. ` +
                t('Please select a smaller image and try again')
            // get the file size in standard format
            setUploadError(errorMessage)
            return
        }

        setSelectedFileName(fileToUpload.name)
        setSelectedFile(fileToUpload)
        setSelectedFileNamePreview(URL.createObjectURL(fileToUpload))
        // eslint-disable-next-line
    }, [])

    const removeFile = () => {
        // empty uploaded resources
        setSelectedFileName('')
        setSelectedFile('')
        setSelectedFileNamePreview('')

        // clear out set image
        setImageUrl('')
        setImageSource(undefined)
        setDeletePoster(true)
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png, image/gif, image/bmp'
    });

    // upload image when was dropped
    useEffect(() => {
        if (!selectedFile || ! selectedFileName) {
            return
        }

        const formData = new FormData();
        formData.append(
            "image",
            selectedFile,
            selectedFileName
        )

        quizzes.uploadImage(formData as any)
            .then((image: any) => {
                toast.success(t('Quiz image updated'))
                setImageUrl(image.url)
            })
            .catch((error: any) => {
                console.error(error.response)
                toast.error(t('Could not update Quiz image'))
            })
        // eslint-disable-next-line
    }, [selectedFile, selectedFileName])

    useEffect(() => {
        if(deletePoster){
            setImageSource(undefined)
            setImageUrl('')
        }
        else if(selectedFile){
            setImageSource(selectedFileNamePreview)
        }
        else if(imageUrl){
            setImageSource(persistentStore.apiUrl + imageUrl)
        }
        // eslint-disable-next-line
    }, [selectedFile, imageUrl, selectedFileNamePreview])

    return (
        <Card>
            {uploadError && <Alert variant='danger'>{uploadError}</Alert>}

            <div className='' {...getRootProps()} style={{minHeight: "150px"}}>
                {imageSource && ! deletePoster && (
                    <>
                        <Image
                            thumbnail
                            width='100%'
                            alt={selectedFileName}
                            src={imageSource}
                        />
                        <span className="d-flex btn text-danger" onClick={removeFile}>
                            {!isDragActive && t('Remove')}
                            {isDragActive && isDragReject && t('This file extension is not allowed')}
                            {isDragActive && !isDragReject && t('Drop it')}
                        </span>
                    </>
                )}

                {(!imageSource || deletePoster) && (
                    <div style={{display: 'flex', height: '150px', alignItems: 'center', justifyContent: 'center', backgroundColor: '#89d1f6'}}>
                        <div style={{width: '80%', color: '#fafafa', fontSize: '30px', fontWeight: 'bolder', textAlign: 'center'}}>
                            {!isDragActive && t('Drag an image here')}
                            {isDragActive && isDragReject && t('This file extension is not allowed')}
                            {isDragActive && !isDragReject && t('Drop it')}
                        </div>
                    </div>
                )}
            </div>
            <input {...getInputProps()} />
        </Card>
    )
};

export default QuizImage;