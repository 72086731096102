import React from 'react';
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ToggleSlider from "../../../components/ToggleSlider";
import './BasicInformation.scss'

type BasicInformationProps = {
    title: string,
    type: 'public' | 'private',
    setType: (type: 'public' | 'private') => void,
    setTitle: (title: string) => void,
    description: string,
    setDescription: (description: string) => void,
    saveCourse: () => void,
}

const BasicInformation = (props: BasicInformationProps) => {
    const {t} = useTranslation()

    return (
        <Card className="card">
            <Card.Header>
                <h4 className="card-title">{t('Basic Information')}</h4>
            </Card.Header>

            <Card.Body>
                <form onSubmit={(e) => {e.preventDefault(); props.saveCourse()}}>
                    <div className="form-group">
                        <label className="form-label" htmlFor="title">{t('Title')}</label>
                        <input
                            type="text"
                            id="title"
                            className="form-control"
                            placeholder={t("Write a title")}
                            value={props.title}
                            onChange={(event) => props.setTitle(event.target.value)}
                            autoComplete='off'
                        />
                    </div>

                    <div className="form-group">
                        <label className="form-label">{t('Description')}</label>
                        <textarea
                            className='form-control'
                            rows={3}
                            value={props.description}
                            onChange={(event) => props.setDescription(event.target.value)}
                            placeholder={t('Write a short course description here')}
                        >
                    </textarea>
                    </div>

                    <div className="form-group mb-0">
                        <label className="form-label">{t('Public')}?</label>
                        <p>
                            {t('When the course is public, everybody can enroll')}.<br/>
                            {t('Set to private to restrict access to paying students only')}.
                        </p>
                        <ToggleSlider
                            id='public-private-slider'
                            type={'square'}
                            checked={props.type === 'public'}
                            onChange={(checked) => checked ? props.setType('public') : props.setType('private')}
                            text={props.type === 'public' ? t('public') : t('private')}
                        />
                    </div>
                </form>
            </Card.Body>
        </Card>
    )
};

export default BasicInformation;