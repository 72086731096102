const errorChecker = (response:any) => {
    if (response === undefined) {
        // what does this mean?? An error?
        return response
    }

    if (![200, 201].includes(response.status)) {
        return
    }

    const errorMessage = response.config.path + ': ' + response.data.errorMessage
    if (response.data.errorMessage !== undefined) {
        console.error(errorMessage)
        // toast.error(errorMessage)
    }

    return response
}

export default errorChecker
