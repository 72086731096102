import React, {useState } from 'react';
import {useTranslation} from "react-i18next";
import './AccountEdit.scss'
import {Button, Card} from "react-bootstrap";
import BreadCrumb from "../../../components/BreadCrumb";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeSquare} from '@fortawesome/free-solid-svg-icons';
import persistentStore from "../../../stores/persistentStore";
import {users} from "../../../api/users";
import {IUser} from "../../../interfaces/IUser";
import {toast} from "react-toastify";

type TrainerAccountEditProps = {}

const AccountEdit = (props: TrainerAccountEditProps) => {
    const {t} = useTranslation()

    const path: IBreadCrumb[] = [
        {name: 'Home', link: '#', active: false},
        {name: 'Admin', link: '/admin', active: false},
        {name: t('Account Edit'), link: '/admin/edit', active: true}
    ]

    // const creditCards = [
    //     {
    //         id:"1",
    //         cardNumber:"**** **** **** 2422",
    //         updatdOn:"12/02/2021"
    //     },
    //     {
    //         id:"2",
    //         cardNumber:"**** **** **** 6321 ",
    //         updatdOn:"11/01/2021"
    //     }
    // ]

    const saveUserInfo = () => {
        const updatedUser: IUser = Object.assign({}, persistentStore.user, {name: name, email: email, invoice_name: invoiceName, invoice_address: invoiceAddress, vat_number: vatNumber})

        console.log(updatedUser)

        users.update(updatedUser)
            .then((result) => {
                toast.success(t('Information updated'))

                // update in localStorage as well
                console.log(result)
                persistentStore.setUser(result.data.user)

            })
            .catch((error) => {
                console.error(error)
                toast.error(error.response.data.errors.detail)
            })
    }

    const [name, setName] = useState<string>(persistentStore.user?.name || '')
    const [email, setEmail] = useState<string>(persistentStore.user?.email || '')
    const [invoiceName, setInvoiceName] = useState<string>(persistentStore.user?.invoice_name || '')
    const [vatNumber, setVatNumber] = useState(persistentStore.user?.vat_number || '')
    const [invoiceAddress, setInvoiceAddress] = useState<string>(persistentStore.user?.invoice_address || '')

    return (
        <div className="container-fluid page__container">
            <BreadCrumb crumbs={path}/>

            <h1 className="h2">{t('Edit Account')}</h1>

            <Card >
                <Tabs defaultActiveKey="account" id="uncontrolled-tab-example" className="nav-tabs-card">       
                    <Tab eventKey="account" title="Account" className="card-body tab-content" >
                        <form className="form-horizontal" onSubmit={saveUserInfo}>
                            {/*<div className="form-group row">*/}
                            {/*    <label htmlFor="avatar" className="col-sm-3 col-form-label form-label">{t('Avatar')}</label>*/}
                            {/*    <div className="col-sm-9">*/}
                            {/*        <div className="media align-items-center">*/}
                            {/*            <div className="media-left">*/}
                            {/*                <div className="icon-block rounded">*/}
                            {/*                   <FontAwesomeIcon icon={faImage} size="lg" />*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="media-body">*/}
                            {/*                <div className="custom-file" style={{width: "auto;"}}>*/}
                            {/*                    <input type="file" id="avatar" className="custom-file-input" />*/}
                            {/*                        <label htmlFor="avatar" className="custom-file-label">{t('Choose file')}</label>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="form-group row">
                                <label htmlFor="name" className="col-sm-3 col-form-label form-label">{t('Full Name')}</label>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input id="name" type="text" className="form-control"
                                                placeholder={t("First Name")} value={name} onChange={(e) => setName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="email" className="col-sm-3 col-form-label form-label">Email</label>
                                <div className="col-sm-6 col-md-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faEnvelopeSquare}
                                                    className="material-icons md-18 text-muted" />
                                            </div>
                                        </div>
                                        <input type="text" id="email" className="form-control"
                                            placeholder={t('Email Address')} value={email} onChange={(e) => setEmail(e.target.value)} disabled={true} />
                                    </div>
                                </div>
                            </div>
                            {/*<div className="form-group row">*/}
                            {/*    <label htmlFor="website" className="col-sm-3 col-form-label form-label">Website</label>*/}
                            {/*    <div className="col-sm-6 col-md-4">*/}
                            {/*        <div className="input-group">*/}
                            {/*            <div className="input-group-prepend">*/}
                            {/*                <div className="input-group-text">*/}
                            {/*                   <FontAwesomeIcon icon={faGlobe} className="material-icons md-18 text-muted" />*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <input type="text" id="website" className="form-control" placeholder="www."*/}
                            {/*                value={website} onChange={(e) => setWebsite(e.target.value)}/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="form-group row">*/}
                            {/*    <label htmlFor="password" className="col-sm-3 col-form-label form-label">{t('Change Password')}</label>*/}
                            {/*    <div className="col-sm-6 col-md-4">*/}
                            {/*        <div className="input-group">*/}
                            {/*            <div className="input-group-prepend">*/}
                            {/*                <div className="input-group-text">*/}
                            {/*                    <FontAwesomeIcon icon={faLock} />*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <input type="password" id="password" className="form-control"*/}
                            {/*                   placeholder="Enter new password" />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="form-group row">*/}
                            {/*    <label htmlFor="password" className="col-sm-3 col-form-label form-label">{t('Change Password')}</label>*/}
                            {/*    <div className="col-sm-6 col-md-4">*/}
                            {/*        <div className="input-group">*/}
                            {/*            <div className="input-group-prepend">*/}
                            {/*                <div className="input-group-text">*/}
                            {/*                    <FontAwesomeIcon icon={faLock} />*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <input type="password" id="password" className="form-control"*/}
                            {/*                placeholder="Enter new password" />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="form-group row">
                                <div className="col-sm-8 offset-sm-3">
                                    <div className="media align-items-center">
                                        <div className="media-left">
                                            <Button onClick={saveUserInfo} className="btn btn-success">{t('Save Changes')}</Button>
                                        </div>
                                        {/*<div className="media-body pl-1">*/}
                                        {/*    <div className="custom-control custom-checkbox">*/}
                                        {/*        <input id="subscribe" type="checkbox" className="custom-control-input"*/}
                                        {/*            checked={subscribeNewslettr} onChange={(event) => { setSubscribeNewslettr(event.target.checked) }} />*/}
                                        {/*            <label htmlFor="subscribe" className="custom-control-label">{t('Subscribe to Newsletter')}</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Tab>

                    <Tab eventKey="billing" title={t("Billing")} className="card-body tab-content">
                        <form action="#" className="form-horizontal">
                            <div className="form-group row">
                                <label htmlFor="name_on_invoice" className="col-sm-3 col-form-label form-label">{t('Name on Invoice')}</label>
                                <div className="col-sm-6 col-md-4">
                                    <input
                                        id="name_on_invoice"
                                        type="text"
                                        className="form-control"
                                        value={invoiceName}
                                        onChange={(e) => setInvoiceName(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label
                                    htmlFor="billing_address"
                                    className="col-sm-3 col-form-label form-label"
                                >
                                    {t('Address')}
                                </label>
                                <div className="col-sm-6 col-md-4">
                                    <input
                                        id="billing_address"
                                        type="text"
                                        className="form-control"
                                        value={invoiceAddress}
                                        onChange={(e) => setInvoiceAddress(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label
                                    htmlFor="vat-number"
                                    className="col-sm-3 col-form-label form-label"
                                >
                                    {t('VAT Number')}
                                </label>
                                <div className="col-sm-6 col-md-4">
                                    <input
                                        id="vat_number"
                                        type="text"
                                        className="form-control"
                                        value={vatNumber}
                                        onChange={(e) => setVatNumber(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*<div className="form-group row">*/}
                            {/*    <label htmlFor="billing_country"*/}
                            {/*           className="col-sm-3 col-form-label form-label">{t('Country')}</label>*/}
                            {/*    <div className="col-sm-6 col-md-4">*/}
                            {/*        <select id="billing_country" className="custom-control custom-select form-control" value={invoiceCountry} onChange={(e) => setInvoiceCountry(e.target.value)}>*/}
                            {/*            <option value="1" selected>USA</option>*/}
                            {/*            <option value="2">India</option>*/}
                            {/*            <option value="3">United Kingdom</option>*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="form-group row">
                                <div className="col-sm-6 col-md-4 offset-sm-3">
                                    <Button onClick={saveUserInfo} className="btn btn-success"> {t('Update Information')}</Button>
                                </div>
                            </div>
                        </form>

                        {/*<Card className="mt-2">*/}
                        {/*    <Card.Header >*/}
                        {/*        <div className="media align-items-center">*/}
                        {/*            <div className="media-body">*/}
                        {/*                <h4 className="card-title">{t('Payment Info')}</h4>*/}
                        {/*            </div>*/}
                        {/*            <div className="media-right">*/}
                        {/*                <Link to="#" className="btn btn-sm btn-outline-primary">*/}
                        {/*                    <FontAwesomeIcon icon={faPlus} className="material-icons" />*/}
                        {/*                </Link>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </Card.Header>*/}
                        {/*    <ul className="card-footer p-0 list-group list-group-fit">*/}
                        {/*        {creditCards.map((card, index) => (*/}
                        {/*            <li className="list-group-item active">*/}
                        {/*                <div className="media align-items-center">*/}
                        {/*                    <div className="media-left">*/}
                        {/*                        <span className="btn btn-primary btn-circle">*/}
                        {/*                        <FontAwesomeIcon icon={faCreditCard} className="material-icons" />*/}
                        {/*                        </span>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="media-body">*/}
                        {/*                        <p className="mb-0">{card.cardNumber}</p>*/}
                        {/*                        <small>{t('Updated on')} {card.updatdOn}</small>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="media-right">*/}
                        {/*                        <button className="btn btn-primary btn-sm">*/}
                        {/*                        <FontAwesomeIcon icon={faPencilAlt} className="material-icons btn__icon--left" />{t('EDIT')}*/}
                        {/*                        </button>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </li>*/}
                        {/*        ))}*/}
                        {/*    </ul>*/}
                        {/*</Card>*/}
                    </Tab>
                </Tabs>
            </Card>
        </div>

    )
};

export default AccountEdit;