import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";

const invoceRequests = () => {

    const get = async (id: string) => {
        const path = `/invoices/${id}`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => response.data.data.invoice)
    };

    return {
        get
    }
}


export const invoice = invoceRequests()
