import React from 'react';
import EnrollOrder from "./EnrollOrder";
import EnrollBenefits from "./EnrollBenefits";
import {useTranslation} from "react-i18next";

type EnrollInfoProps = {
    className?: string,
}

const EnrollInfo = (props: EnrollInfoProps) => {
    const {className} = props
    const {t} = useTranslation()

    return (
        <div className={`d-flex flex-column ${className}`}>
            <EnrollBenefits title={t('Benefits of TrainingsOmgeving')}/>
            <EnrollOrder className='mt-4'/>
        </div>
    )
};

export default EnrollInfo;