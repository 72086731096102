import React, {useEffect, useState} from 'react';
import store from "../../stores/store";
import uiStore from "../../stores/uiStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {faBolt} from "@fortawesome/free-solid-svg-icons/faBolt";

type MenuAccountProps = {}

const MenuAdmin = (props: MenuAccountProps) => {
    const [slug, setSlug] = useState(window.location.pathname.substring('/admin/'.length))
    const {t} = useTranslation()

    useEffect(() => {
        setSlug(window.location.pathname.substring('/admin/'.length))
    }, [])

    const menuItemClicked = (item: any) => {
        if (store.isMobile()) {
            uiStore.setShowSidebar(false)
        }
    }

    return (
        <>
            <div className="sidebar-heading">{t('Admin')}</div>
            <ul className="sidebar-menu">
                <li className={`sidebar-menu-item ${slug === 'users' ? 'open' : ''}`}>
                    <Link
                        className="sidebar-menu-button"
                        data-toggle="collapse"
                        to="/admin/users"
                        onClick={menuItemClicked}
                    >
                        <FontAwesomeIcon
                            icon={faUsers}
                            className="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                        />
                        {t('Users')}
                    </Link>
                </li>
                <li className={`sidebar-menu-item ${slug === 'events' ? 'open' : ''}`}>
                    <Link
                        className={`sidebar-menu-button`}
                        data-toggle="collapse"
                        to="/admin/events"
                        onClick={menuItemClicked}
                    >
                        <FontAwesomeIcon
                            icon={faBolt}
                            className="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                        />
                        {t('Events')}
                    </Link>
                </li>
            </ul>
        </>
    )
};

export default MenuAdmin;