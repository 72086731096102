import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import CourseFilter from "./CourseFilter";
import Alert from "../../../components/Alert";
import CourseTile from "./CourseTile";
import {ICourse} from "../../../interfaces/ICourse";
import {observer} from "mobx-react";
import CoursesContext from "../../../contexts/CoursesContext";

type AdminCoursesPageProps = {}

const CourseManager = (props: AdminCoursesPageProps) => {
    const {t} = useTranslation()
    const courses: ICourse[] | undefined = useContext<ICourse[]>(CoursesContext)
    const [filteredCourses, setFilteredCourses] = useState<ICourse[]>(courses)

    const path: IBreadCrumb[] = [
        {name: 'Home', link: '/admin/dashboard', active: false},
        {name: 'Courses', link: '/admin/courses', active: true}
    ]

    const [searchKeyword, setSearchKeyword] = useState<string>('');

    useEffect(() => {
        if (courses.length > 0) {
            //console.log(courses)
            if (searchKeyword === '') {
                setFilteredCourses(courses)
            } else {
                let serchedCourses = courses.filter(function (course: ICourse) {
                    return (course.title.toLowerCase().includes(searchKeyword.toLowerCase()) || course.description.toLowerCase().includes(searchKeyword.toLowerCase()))
                })

                setFilteredCourses(serchedCourses)
            }
        }
    }, [courses, searchKeyword])

    return (
        <div className="container-fluid page__container">
            <BreadCrumb crumbs={path}/>

            <div
                className="d-flex flex-column flex-sm-row flex-wrap mb-headings align-items-start align-items-sm-center">
                <div className="flex mb-2 mb-sm-0">
                    <h1 className="h2">{t('Manage Courses')}</h1>
                </div>
                <Link to="/admin/courses/add" className="btn btn-success">{t('Add course')}</Link>
            </div>

            {courses.length > 10 && <CourseFilter searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword}/>}

            {filteredCourses?.length === 0 && (
                <Alert
                    message={`${t('Ohh no')}! ${t('No courses to display')}. ${t('Add a course by clicking the big green button at the top')}.`}
                />
            )}

            <div className="row">
                {filteredCourses.map((course: ICourse, index: number) => (
                    <div className="col-md-6" key={index}>
                        <CourseTile course={course}/>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default observer(CourseManager)