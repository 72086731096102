import React, {useEffect, useState} from 'react';
import {ICourse} from "../../../interfaces/ICourse";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import swal from "sweetalert";
import {courses} from "../../../api/courses";
import {useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {useLoading} from "../../../utils/useLoading";
import persistentStore from "../../../stores/persistentStore";
import emptyCourseImage from "../../../assets/img/empty_video.jpg";

type CourseTileProps = {
    course: ICourse
}

const CourseTile = (props: CourseTileProps) => {
    const [showOptions, setShowOptions] = useState(false)
    const queryClient = useQueryClient()
    const [loading, startLoading, stopLoading] = useLoading()
    const {t} = useTranslation()

    const toggleShowOptions = () => {
        setShowOptions(!showOptions)
    }

    const closeSubMenu = (event: MouseEvent) => {
        const container = document.getElementById(`course-submenu-${props.course.slug}`);
        const toggleElement = document.getElementById(`course-toggle-submenu-${props.course.slug}`);
        const clickedElement = event.target as Node
        const clickedToggleElement = toggleElement?.contains(clickedElement)
        const clickedOutside = !container?.contains(clickedElement) && !clickedToggleElement

        if (clickedOutside) {
            // hide the submenu
            setShowOptions(false)
        }
    }

    const deleteCourse = () => {
        startLoading()
        if(! props.course.slug){
            toast.error(t('No slug known for this course'))
            return
        }

        courses.del(props.course.slug)
            .then((result) => {
                stopLoading()
                toast.success(t('Course removed'))

                // invalidate courses query
                queryClient.invalidateQueries('courses')
            })
            .catch((error) => {
                stopLoading()
                toast.error(t('Error while deleting course'))
            })
    }

    const showCourseDeleteMessage = () => {
        swal({
            title: t('Are you sure you want to delete the course?'),
            text: t('This cannot be undone'),
            buttons: {
                cancel: {
                    text: t("Cancel"),
                    value: 'cancel',
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: t("OK"),
                    value: 'confirm',
                    visible: true,
                    className: "",
                }
            }
        })
            .then((result) => {
                if(result === 'confirm'){
                    deleteCourse()
                }
            })
    }

    useEffect(() => {
        if (showOptions) {
            // add event listener which listens for a click outside of the opened element
            window.addEventListener('mouseup', closeSubMenu);
        } else {
            // remove event listener
            document.removeEventListener('mouseup', closeSubMenu)
        }

        return document.removeEventListener('mouseup', closeSubMenu)
    // disable eslint as it wants closeSubmenu() inside the dependencies
    // eslint-disable-next-line
    }, [showOptions])

    return (
        <Card>
            <Card.Body>
                <div className="d-flex flex-column flex-sm-row">
                    <Link
                        to={`/admin/courses/${props.course.slug}/edit`}
                        className="avatar avatar-lg avatar-4by3 mb-3 w-xs-plus-down-100 mr-sm-3"
                    >
                        {props.course.image_url && (
                            <img src={persistentStore.apiUrl + props.course.image_url} alt="course poster" className="avatar-img rounded"/>
                        )}
                        {! props.course.image_url && (
                            <img src={emptyCourseImage} alt="course poster" className="avatar-img rounded"/>
                        )}
                    </Link>

                    <div className="flex" style={{minWidth: '200px'}}>
                        <h4 className="card-title mb-1">
                            <Link to={`/admin/courses/${props.course.slug}/edit`}>
                                {props.course.title}
                            </Link>
                        </h4>
                        <p className="text-black-70">{props.course.description}</p>
                        <div className="d-flex align-items-end">
                            <div className="d-flex flex flex-column mr-3">
                                <div className="d-flex align-items-center py-1 border-bottom">
                                    <small className="text-black-70 mr-2">
                                        € {((props.course.price_in_cents||0) / 100).toLocaleString('nl', {minimumFractionDigits: 2})}
                                    </small>
                                    <small
                                        className="text-black-50">{props.course.sales} {t('SALES')}</small>
                                </div>
                            </div>
                            <div className="text-center">
                                <Link
                                    to={`/admin/courses/${props.course.slug}/edit`}
                                    className="btn btn-sm btn-white"
                                >
                                    {t('Edit')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Body>

            <div className="card__options dropdown right-0 pr-2">
                <Button
                    id={`course-toggle-submenu-${props.course.slug}`}
                    variant='link'
                    onClick={toggleShowOptions}
                    className="dropdown-toggle text-muted"
                    data-caret="false"
                    data-toggle="dropdown"
                >
                    <FontAwesomeIcon icon={faEllipsisV} className='material-icons'/>
                </Button>
                <div
                    id={`course-submenu-${props.course.slug}`}
                    className={`dropdown-menu dropdown-menu-right ${showOptions && 'show'}`}
                >
                    <Link
                        target="_blank"
                        className="dropdown-item"
                        to={`/course/${props.course.slug}`}
                    >
                        {t('View course')}
                    </Link>
                    <Link
                        className="dropdown-item"
                        to={`/admin/courses/${props.course.slug}/edit`}
                    >
                        {t('Edit course')}
                    </Link>

                    <div className="dropdown-divider"/>

                    <Button
                        className="dropdown-item text-danger btn__icon--left"
                        onClick={showCourseDeleteMessage}
                        variant='link'
                    >
                        {loading && t('Deleting course') + '...'}
                        {! loading && t('Delete course')}
                    </Button>
                </div>
            </div>
        </Card>
    )
};

export default CourseTile;