import React from 'react';
import './EnrollBenefits.scss'
import {useTranslation} from "react-i18next";

type EnrollBenefitsProps = {
    title?: string,
}

const EnrollBenefits = (props: EnrollBenefitsProps) => {
    const {t} = useTranslation()

    const benefits = [
        t('Direct access'),
        t('Learn at your own pace'),
        t('24/7 available'),
        t('Learn on any device')
    ]

    return (
        <div className={'benefits'}>
            <h4>{props.title}</h4>
            <ul>
                {benefits.map((benefit: string, index) => (
                    <li key={index} className={'benefit'}>
                        {benefit}
                    </li>
                ))}
            </ul>
        </div>
    )
};

export default EnrollBenefits;