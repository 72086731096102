import React, {useEffect, useState} from 'react';
import history from "../../utils/history";
import {clearCacheAsync} from "../../utils/clearCache";
import {clearLocalStorage} from "../../utils/clearLocalStorage";
import persistentStore from "../../stores/persistentStore";

const ClearCache = () => {
    const [status, setStatus] = useState('loading')

    useEffect(() => {
        setStatus('cache legen')

        persistentStore.emptyStore()
        clearLocalStorage()

        // clear the cache
        clearCacheAsync()
            .then(() => setStatus('cache geleegd'))

    }, [])

    useEffect(() => {
        if(status === 'cache geleegd'){
            clearCacheAsync()
                .then(() => clearLocalStorage())
                .then(() => setStatus('localStorage geleegd'))
                .then(() => history.push('/login'))
        }
        // eslint-disable-next-line
    }, [status])

    return (
        <div>
            <div>Status: {status}</div>
        </div>
    )
}

export default ClearCache;
