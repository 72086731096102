import React, {useContext, useEffect, useState} from 'react';
import {Button, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign, faPlusSquare, faShoppingBag, faStar, faUser} from "@fortawesome/free-solid-svg-icons";
import uiStore from "../../../stores/uiStore";
import CourseContext from "../../../contexts/CourseContext";
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import ReactTooltip from "react-tooltip";

type MetaInfoProps = {
    priceInCents: number,
    setPriceInCents: (priceInCents: number) => void,
    saveCourse: ()=>void,
}

const MetaInfo = (props: MetaInfoProps) => {
    const {t} = useTranslation()
    const [amount, setAmount] = useState<string>('0')
    const course = useContext(CourseContext)
    const urlTraining = course ? `${window.location.origin}/course/${course.slug}` : ''
    const urlSalesPage = course ? `${window.location.origin}/enroll/${course.slug}` : ''

    useEffect(() => {
        // only change the value when we haven't updated it yet
        if (props.priceInCents && amount === '0') {
            setAmount((props.priceInCents / 100).toLocaleString(uiStore.locale, uiStore.moneyNoSymbol))
        }
    }, [props.priceInCents, amount])

    const handleAmountChange = (event: any) => {
        // on empty inputfield
        if (event.target.value === '') {
            setAmount('')
            return true
        }

        // replace the comma by a dot so JS understands it as a float
        const interpreted = event.target.value.replace(',', '.')

        // parse the value to an integer
        const amount: number = parseFloat(interpreted)
        const rounded = Math.round(amount * 100)
        const amount_in_cents = Math.trunc(rounded)

        // check whether it's a valid integer
        if (isNaN(amount_in_cents)) {
            setAmount('')
            return false
        }

        setAmount(event.target.value)
        props.setPriceInCents(amount_in_cents)
    }

    const copyLink = (inputElementId: string) => {
        const elInput: HTMLInputElement | null = document.getElementById(inputElementId) as HTMLInputElement
        if (elInput) {
            elInput.select()
            document.execCommand('copy')
            toast.success(t('URL copied'))
        }
    }

    return (
        <Card>
            <Card.Header>
                <h4 className="card-title">Meta</h4>
                <p className="card-subtitle">{t('Extra Options')}</p>
            </Card.Header>

            <form
                className="card-body"
                onSubmit={(e) => {e.preventDefault(); props.saveCourse()}}
                autoComplete='off'
            >
                <div className="form-group">
                    <label className="form-label" htmlFor="price-in-cents">{t('Link to training')}</label>
                    <div className='d-flex flex-row'>
                        <input
                            type="text"
                            id="link-to-course"
                            className="form-control"
                            value={urlTraining}
                            readOnly={true}
                        />
                        <Button
                            variant={'light'}
                            className='ml-1'
                            onClick={(event) => copyLink('link-to-course')}
                            data-for='url-training'
                            data-tip
                        >
                            <FontAwesomeIcon icon={faCopy} className="material-icons"/>
                        </Button>
                    </div>
                </div>

                <div className="form-group">
                    <label className="form-label" htmlFor="price-in-cents">{t('Link to salespage')}</label>
                    <div className='d-flex flex-row'>
                        <input
                            type="text"
                            id="link-to-salespage"
                            className="form-control"
                            value={urlSalesPage}
                            readOnly={true}
                        />
                        <Button
                            variant={'light'}
                            className='ml-1'
                            onClick={(event) => copyLink('link-to-salespage')}
                            data-for='url-training'
                            data-tip
                        >
                            <FontAwesomeIcon icon={faCopy} className="material-icons"/>
                        </Button>
                    </div>
                </div>

                <div className="form-group">
                    <label className="form-label" htmlFor="price-in-cents">{t('Price (inc VAT)')}</label>
                    <input
                        type="text"
                        id="price-in-cents"
                        className="form-control"
                        placeholder='0,00'
                        value={amount}
                        onChange={handleAmountChange}
                    />
                </div>

                <div className="form-group mb-0">
                    <label className="form-label" htmlFor="option1">
                        {t('Completion Badge')}
                    </label>
                    <div>
                        <div data-toggle="buttons">
                            <label className="btn btn-primary btn-circle active">
                                <FontAwesomeIcon icon={faUser} className="material-icons"/>
                            </label>
                            <label className="btn btn-danger btn-circle ml-1 mr-1">
                                <FontAwesomeIcon icon={faStar} className="material-icons"/>
                            </label>
                            <label className="btn btn-success btn-circle ml-1 mr-1">
                                <FontAwesomeIcon icon={faShoppingBag} className="material-icons"/>
                            </label>
                            <label className="btn btn-warning btn-circle ml-1 mr-1">
                                <FontAwesomeIcon icon={faDollarSign} className="material-icons"/>
                            </label>
                            <label className="btn btn-info btn-circle ml-1 mr-1">
                                <FontAwesomeIcon icon={faPlusSquare} className="material-icons"/>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
            <ReactTooltip id='url-training'>{t('copy url')}</ReactTooltip>
        </Card>
    )
}

export default MetaInfo;