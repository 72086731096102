import {useState} from "react"
import {IQuestion} from "../interfaces/IQuestion";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "react-query";
import {quizQuestions as questionsApi} from "../api/quizQuestions";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import moment from "moment/moment";
import {Button} from "react-bootstrap";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons/faTrashAlt";  
import QuestionModal from "../pages/Admin/Quizzes/QuestionModal";

type QuestionListItemProps = {
    question: IQuestion
}

const QuestionListItem = (props: QuestionListItemProps) => {
    const {question} = props
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const [showQuestionModal, setShowQuestionModal] = useState<boolean>(false)
    const [currentQuestion, setCurrentQuestion] = useState<IQuestion>()

    const editQuestion = (question: IQuestion) => {
        setCurrentQuestion(question)
        setShowQuestionModal(true)
    }

    const deleteQuestion = (id: number) => {

        questionsApi.del(question)
            .then((result) => {
                queryClient.invalidateQueries('quizzes')
                toast.success(t('Question removed'))
            })
            .catch((error: any) => {
                console.error(error.response)
                toast.error(t('Question could not be removed'))
            })
    }

    const updateQuestion = (question: IQuestion) => {
        questionsApi.update(question)
            .then((result) => {
                queryClient.invalidateQueries('quizzes')
                toast.success(t('Question updated'))
            })
            .catch((error: any) => {
                console.error(error.response)
                toast.error(t('Question could not be updated'))
            })
    }

    return (
        <>
            <div className="nestable-item nestable-item-handle">
                <div className="nestable-handle">
                    <FontAwesomeIcon icon={faBars} className="material-icons"/>
                </div>
                <div className="nestable-content">
                    <div className="media align-items-center">
                        <div className="media-body">
                            <h5 className="card-title h6 mb-0">
                                {question.question}
                            </h5>
                            <small className="text-muted">
                                bijgewerkt {moment(question?.modified).format('D-M-YYYY HH:mm')}
                            </small>
                        </div>
                        <div className="media-right">
                            <Button variant={"link"} onClick={() => editQuestion(question)}>
                                <FontAwesomeIcon icon={faPencilAlt} className="material-icons"/>
                            </Button>
                            <Button variant={"link"} onClick={() => question.id && deleteQuestion(question.id)}>
                                <FontAwesomeIcon icon={faTrashAlt} className="material-icons text-danger"/>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            {showQuestionModal && (
                <QuestionModal
                    hide={() => setShowQuestionModal(false)}
                    updateQuestion={updateQuestion}
                    question={currentQuestion}
                    quiz_id={question?.quiz_id}
                    sortOrder={question?.sort_order}
                />
            )}
        </>
    )
}

export default QuestionListItem
