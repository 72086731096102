import React, {useEffect} from 'react';
import MenuInstructor from "./MenuInstructor";
import uiStore from "../../stores/uiStore";
import {observer} from "mobx-react";
import persistentStore from "../../stores/persistentStore";
import MenuAdmin from "./MenuAdmin";
import store from "../../stores/store";

type SideBarProps = {}

const SideBar = (props: SideBarProps) => {
    const sidebarOptions = 'sidebar-dark bg-dark'

    // hide the sidebar by default on mobile
    useEffect(() => {
        if(store.deviceType === 'mobile'){
            uiStore.setShowSidebar(false)
        }
    }, [])

    if (!uiStore.showSidebar) {
        return <></>
    }

    return (
        <div
            className={`mdk-drawer js-mdk-drawer ${sidebarOptions}`}
            id="default-drawer"
            style={{
                minHeight: store.isMobile() ? '100%' : '100vh',
                position: store.isMobile() ? 'absolute' : 'relative'
            }}
        >
            <div className={`mdk-drawer__content `}>
                <div className={`sidebar sidebar-left ${sidebarOptions} `}>
                    <div className="sidebar-p-y">
                        {/*<MenuAccount/>*/}
                        <MenuInstructor/>

                        {persistentStore.isAdmin && (
                            <MenuAdmin />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default observer(SideBar);