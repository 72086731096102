import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faYoutube} from "@fortawesome/free-brands-svg-icons/faYoutube";
import ReactQuill from "react-quill";
import {useTranslation} from "react-i18next";
import {ILesson} from "../../../interfaces/ILesson";
import missingVideoPoster from "../../../assets/img/empty_video.jpg";
import ReactPlayer from "react-player/lazy";
import {toast} from "react-toastify";
import {IVideoInfoWistia} from "../../../interfaces/IVideoInfoWistia";
import {IVideoInfoVimeo} from "../../../interfaces/IVideoInfoVimeo";
import { ICourse } from '../../../interfaces/ICourse';
import {IVideoInfo} from "../../../interfaces/IVideoInfo";

type LessonInfoProps = {
    lesson: ILesson,
    setLesson: (updatedLesson: ILesson) => void
    saveLesson: () => void
    course?: ICourse
}

const LessonInfo = (props: LessonInfoProps) => {
    const {t} = useTranslation()
    const {lesson, setLesson, saveLesson} = props
    const {course} = props
    const [videoUrl, setVideoUrl] = useState(lesson.video_url)
    const [contentText, setContentText] = useState(lesson.content_text)
    const [poster, setPoster] = useState(lesson.poster)
    const [title, setTitle] = useState<string>(lesson.title)
    const [thumbnail, setThumbnail] = useState(missingVideoPoster)
    const [videoInfo, setVideoInfo] = useState<IVideoInfo | undefined>()
    const [preview, setPreview] = useState<boolean>(lesson.preview)

    useEffect(() => {
        const updatedLesson: ILesson = Object.assign(
            {},
            lesson,
            {
                content_text: contentText,
                poster: poster,
                title: title,
                video_url: videoUrl,
                preview: preview
            }
        )

        setLesson(updatedLesson)
        // ignore 'lesson' as dependency as it causes an infinity loop
        // eslint-disable-next-line
    }, [contentText, title, videoUrl, poster, setLesson, preview])

    // update title and description with information retrieved from the video-url
    useEffect(() => {
        if (videoInfo) {
            if (!title && videoInfo.title) {
                setTitle(videoInfo.title)
            }

            if (!contentText && videoInfo.description) {
                setContentText(videoInfo.description)
            }

            if ((!poster || poster === missingVideoPoster) && videoInfo.image_url) {
                setPoster(videoInfo.image_url)
            }
        }
    }, [videoInfo, title, contentText, poster])

    const getVimeoVideoInfo = (vimeoId: string) => {
        const vimeoUrl: string = `https://vimeo.com/api/v2/video/${vimeoId}.json`
        getJSON(vimeoUrl, (status, data: IVideoInfoVimeo[]) => {
            if(! data || ! data[0]){
                toast.error(t('Could not get video information from Vimeo'))
                return
            }

            const vimeoVideoInfo: IVideoInfoVimeo = data[0]
            const info: IVideoInfo = {
                id: vimeoId,
                title: vimeoVideoInfo.title,
                description: vimeoVideoInfo.description,
                image_url: vimeoVideoInfo.thumbnail_medium
            }

            setVideoInfo(info)
        })
    }

    const getWistiaVideoInfo = (wistiaId: string) => {
        const wistiaUrl: string = `http://fast.wistia.com/oembed?url=http%3A%2F%2Fhome.wistia.com%2Fmedias%2F${wistiaId}`
        getJSON(wistiaUrl, (status, data) => {
            const wistiaVideoInfo: IVideoInfoWistia = data
            const info: IVideoInfo = {
                id: wistiaId,
                title: wistiaVideoInfo.title,
                description: '',
                image_url: wistiaVideoInfo.thumbnail_url
            }

            setVideoInfo(info)
        })
    }

    // const getWistiaThumbnail = (url: string) => {
    //     const wistiaID = getIdFromWistiaUrl(url)
    //     const wistiaType = "async_popover";
    //     const popoverWidth = 640;
    //     const popoverHeight = 350;
    //     const thumbUrl = `https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/${wistiaID}?embedType=${wistiaType}&videoWidth=900&popoverWidth=${popoverWidth}&popoverHeight=${popoverHeight}`
    //     getJSON(thumbUrl, (status, response) => {
    //         if (response.thumbnail_url) {
    //             setThumbnail(response.thumbnail_url)
    //
    //             // also! set the height and width of the player as wistia changes that dynamically
    //             // playerRef.current.
    //         } else {
    //             console.error('Did not receive thumbnail url from wistia')
    //             console.error(response)
    //         }
    //     })
    // }

    const getIdFromWistiaUrl = (url: string) => {
        if (url.includes('fast.wistia.net/embed/iframe/')) {
            const index = url.indexOf('embed/iframe/') + 'embed/iframe/'.length
            const id = url.substr(index)
            return id
        }

        if (url.includes('embed/iframe/?')) {
            const index = url.indexOf('embed/iframe/?') + 'embed/iframe/?'.length
            const indexOfQuestion = url.indexOf('?')
            const length = indexOfQuestion - index
            const id = url.substr(index, length)
            return id
        }

        // https://fast.wistia.com/embed/iframe/zs8hlyi5xz
        if (url.includes('embed/iframe/')) {
            const searchPhrase = 'embed/iframe/'
            const index = url.indexOf(searchPhrase) + searchPhrase.length
            const id = url.substr(index)
            setVideoUrl(`https://fast.wistia.net/embed/iframe/${id}`)

            return id
        }

        toast.error(t('Unknown format of Wistia url'))
    }

    const getIdFromYoutubeUrl = (url: string) => {
        if (url.includes('watch?v=')) {
            const index = url.indexOf('?v=') + 3
            const id = url.substr(index)
            setVideoUrl(`https://youtu.be/${id}`)

            return id
        }

        // https://studio.youtube.com/video/v7mi-YnjU80/edit
        if (url.includes('studio.youtube.com')) {
            const searchPhrase: string = 'video/'
            const endPhrase: string = '/edit'
            const index: number = url.indexOf(searchPhrase) + searchPhrase.length
            const length: number = url.length - index - endPhrase.length
            const id = url.substr(index, length)

            setVideoUrl(`https://youtu.be/${id}`)

            return id
        }

        // https://youtu.be/DISuxOzx2Ac
        if (url.includes('youtu.be')) {
            const searchPhrase = 'tu.be/'
            const index = url.indexOf(searchPhrase) + searchPhrase.length
            const id = url.substr(index)

            return id
        }
    }

    const getIdFromVimeoUrl = (url: string) => {
        if (url.includes('vimeo.com')) {
            const index = url.indexOf('vimeo.com') + 9
            const id = url.substr(index)
            return id
        }
    }

    const getVimeoThumbNail = (url: string) => {
        const id = getIdFromVimeoUrl(url)

        if (id) {
            const apiUrl = `https://vimeo.com/api/v2/video/${id}.json`
            getJSON(apiUrl, (status: any, response: any) => {
                if (response[0].thumbnail_medium) {
                    setThumbnail(response[0].thumbnail_medium)
                }
            })
        }
    }

    const getJSON = (url: string, callback: (status: any, response: any) => void) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'json';
        xhr.onload = function () {
            const status = xhr.status;
            if (status === 200) {
                callback(null, xhr.response);
            } else {
                callback(status, xhr.response);
            }
        };
        xhr.send();
    }

    const getYoutubeVideoInfo = (videoId: string) => {
        // todo get this from env var
        const apikey: string | undefined = process.env.REACT_APP_YOUTUBE_API_KEY
        if (!apikey) {
            toast.error('No youtube API key provided. Be sure to set environment variable REACT_APP_YOUTUBE_API_KEY')
            console.error('No youtube API key provided. Be sure to set environment variable REACT_APP_YOUTUBE_API_KEY')
            return
        }
        const googleApiUrl = 'https://www.googleapis.com/youtube/v3/videos?id=' + videoId + '&key=' + apikey + '&part=snippet';

        getJSON(googleApiUrl, (status, data) => {
            if (data.items && data.items.length > 0 && data.items[0].snippet !== undefined) {
                const snippet = data.items[0].snippet

                // replace newlines with <br />
                const description = snippet.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
                const info: IVideoInfo = {
                    id: videoId,
                    title: snippet.title,
                    description: description,
                    image_url: snippet.thumbnails.standard.url
                }

                setVideoInfo(info)
            }
        })
    }

    useEffect(() => {
        // YOUTUBE
        if (videoUrl && (videoUrl.includes('youtube') || videoUrl.includes('youtu.be'))) {
            // get poster
            const youtubeId: string | undefined = getIdFromYoutubeUrl(videoUrl)
            if (youtubeId) {
                getYoutubeVideoInfo(youtubeId)
            } else {
                console.error('could not get id from youtube url: ' + videoUrl)
            }
            const youtubeThumbnail = `https://img.youtube.com/vi/${youtubeId}/0.jpg`

            setThumbnail(youtubeThumbnail)
        }

        // VIMEO
        if (videoUrl && (videoUrl.includes('vimeo'))) {
            // get poster
            getVimeoThumbNail(videoUrl)
            const vimeoId = getIdFromVimeoUrl(videoUrl)
            if (vimeoId) {
                getVimeoVideoInfo(vimeoId)
            } else {
                toast.error(t('Could not get information about the Vimeo video'))
            }
        }

        // WISTIA
        if (videoUrl && (videoUrl.includes('wistia'))) {
            const wistiaId = getIdFromWistiaUrl(videoUrl)
            if (wistiaId) {
                getWistiaVideoInfo(wistiaId)
            } else {
                toast.error(t('Could not get information about the Wistia video'))
            }
        }
        // eslint-disable-next-line
    }, [videoUrl])

    useEffect(() => {
        if (thumbnail) {
            setPoster(thumbnail)
        }
    }, [thumbnail])

    return (
        <Card>
            <Card.Body>
                <form onSubmit={saveLesson}>

                    <Row className="form-group row">
                        <label htmlFor="title" className="col-md-3 col-form-label form-label">{t("Title")}</label>
                        <Col md={6}>
                            <input
                                id="title"
                                type="text"
                                className="form-control"
                                value={title}
                                onChange={(event) => setTitle(event.target.value)}
                            />
                        </Col>
                    </Row>

                    <Row className="form-group">
                        <label className="col-md-3 col-form-label form-label">
                            {t('Link to your video')}
                        </label>
                        <Col md={9}>
                            <Row>
                                <Col md={6}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={videoUrl}
                                            onChange={(event) => setVideoUrl(event.target.value)}
                                            autoFocus
                                        />
                                        <small className="form-text text-muted d-flex align-items-center">
                                            <FontAwesomeIcon
                                                icon={faYoutube}
                                                className="material-icons font-size-16pt mr-1"
                                            />
                                            <span className="icon-text">{t('Paste Video')}</span>
                                        </small>
                                    </div>
                                </Col>

                                <Col md={6}>
                                    <div className="form-group">
                                        {!videoUrl && (
                                            <img
                                                src={missingVideoPoster}
                                                alt='missing video url'
                                                style={{width: '100%'}}
                                            />
                                        )}

                                        {videoUrl && (
                                            <div className="embed-responsive embed-responsive-16by9">
                                                <ReactPlayer
                                                    className='embed-responsive-item'
                                                    url={videoUrl}
                                                    controls={false}
                                                    width='100%'
                                                    height='100%'
                                                    playing={true}
                                                    muted={true}
                                                    light={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="form-group">
                        <label htmlFor="avatar" className="col-sm-3 col-form-label form-label">
                            {t("Preview image")}
                        </label>
                        <Col sm={9}>
                            <div className="media align-items-center">
                                <div className="media-left">
                                    {!poster && <img src={thumbnail} alt="" width="100" className="rounded"/>}
                                    {poster && <img src={poster} alt="" width="100" className="rounded"/>}
                                </div>
                                <div className="media-body">
                                    <div className="custom-file" style={{width: "auto"}}>
                                        <input
                                            type="file"
                                            id="avatar"
                                            className="custom-file-input"
                                        />
                                        <label htmlFor="avatar" className="custom-file-label">
                                            {t("Choose file")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {course && course.type === 'private' && (
                        <Row className='mb-3 mt-2'>
                            <Col lg={3}>
                                <label className="form-label" htmlFor="subscribe">{t('Allow Preview')}</label>
                            </Col>
                            <Col className="col-lg-8 d-flex align-items-center">
                                <div className="d-flex flex-row">
                                    <div className="custom-control custom-checkbox-toggle custom-control-inline">
                                        <input
                                            checked={preview}
                                            type="checkbox"
                                            id="subscribe"
                                            className="custom-control-input"
                                            onChange={(e) => setPreview(e.target.checked)}
                                        />
                                        <label className="custom-control-label" htmlFor="subscribe" />
                                    </div>
                                    <div className="">
                                        {preview && t('This lesson is accessible as a free preview')}
                                        {! preview && t('This lesson is NOT accessible as a free preview')}
                                    </div>
                                </div>
                            </Col>
                        </Row>  
                    )}
                    
                    <div className="form-group mb-0" style={{height: '400px'}}>
                        <label className="form-label">{t('Description')}</label>
                        <ReactQuill
                            style={{height: "200px"}}
                            theme="snow"
                            value={contentText}
                            onChange={setContentText}
                            placeholder={t('Write the lesson content here')}
                        />
                    </div>
                </form>
            </Card.Body>
        </Card>
    )
};

export default LessonInfo;