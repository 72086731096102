import React, {useContext} from 'react';
import {ILesson} from "../../../interfaces/ILesson";
import UserLessonContext from "../../../contexts/UserLessonContext";
import './ContentText.scss';

type ContentTextProps = {
    // text: string
}

const ContentText = (props: ContentTextProps) => {
    const lesson: ILesson | undefined = useContext(UserLessonContext)

    return (
        <div className="lesson-description" dangerouslySetInnerHTML={{__html: lesson?.content_text || ''}}/>
    )
}

export default ContentText;

