import React, {useEffect} from "react";
import {Button} from 'react-bootstrap'
import DataTable from "react-data-table-component";
import {useTranslation} from "react-i18next";
import {IUser} from "../../../interfaces/IUser";
import moment from "moment";
import FilterComponent from "../../../components/FilterComponent";
import {users as usersApi} from "../../../api/users";
import persistentStore from "../../../stores/persistentStore";
import {useHistory} from "react-router-dom";
import qs from "querystring";
import {ICourse} from "../../../interfaces/ICourse";
import {toast} from "react-toastify";
import {useQueryClient} from "react-query";
import {ISubscription} from "../../../interfaces/ISubscription";

const UsersTable = (props: any) => {
    const [filterText, setFilterText] = React.useState('');
    const {t} = useTranslation()
    const users = props.users
    const history = useHistory()
    const queryClient = useQueryClient()

    // update the url when the user searches. This way we can use the back-button and retain the searchresults
    useEffect(() => {
        if(filterText === ''){
            history.push(`/admin/users`)
        }

        if(filterText){
            history.push(`/admin/users?s=${filterText}`)
        }
        // no need to add `history` to the list of dependencies
        // eslint-disable-next-line
    }, [filterText])

    // when we arrive on this page and a search is requested, perform that search
    useEffect(() => {
        const queryString = window.location.search.startsWith('?')
            ? window.location.search.slice(1)
            : window.location.search
        const queryParams = qs.parse(queryString)

        if (queryParams.hasOwnProperty('s') && typeof queryParams.s === 'string') {
            setFilterText(queryParams.s)
        }
        // eslint-disable-next-line
    }, [])

    const impersonate = (user: IUser) => {
        if (user.id === undefined) {
            console.error('No id known for user. Cannot impersonate')
            toast.error('No id known for user. Cannot impersonate')
            return
        }

        usersApi.impersonate(user.id)
            .then((result: any) => {
                const user: IUser = result.data.user

                // clear cached querydata
                queryClient.invalidateQueries('courses')
                queryClient.invalidateQueries('settings')

                // trainers/admins: push to dashboard
                if(user.is_admin || result.data.user.is_trainer){
                    return history.push('/admin/dashboard')
                }

                // students: push to the first course they have access to
                if(user.Courses && user.Courses.length > 0){
                    const firstCourse: ICourse = user.Courses[0]
                    return history.push(`/course/${firstCourse.slug}`)
                }

                console.error('Impersonation happened, but user is neither admin, trainer or student')
                console.error(result.data)
            })
    }

    const isTrialUser = (user: IUser) => {
        if(user.subscriptions?.length === 0){
            return false
        }

        // sort descending on grants_access_until
        const activeTrialSubscription = (user.subscriptions||[])
            .sort((subscriptionA: ISubscription, subscriptionB: ISubscription) => (
                 subscriptionB.grants_access_until.localeCompare(subscriptionA.grants_access_until)
            ))

        return activeTrialSubscription[0].type === 'trial'
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return <FilterComponent
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
        />;
    }, [filterText]);

    const filteredUsers = (users.data || [])
        .filter((user: IUser) => {
            if (filterText === '') {
                return true
            }
            const data = JSON.stringify(user).toLowerCase()
            const query = filterText.toLowerCase()

            return data && data.includes(query)
        })

    const columns = [
        {
            name: t('Last seen'),
            selector: (user: IUser) => user.last_seen || '',
            sortable: true,
            cell: (user: IUser) => {
                return user.last_seen
                    ? `${moment(user.last_seen).fromNow()}`
                    : ''
            },
        },
        {
            name: t('Name'),
            selector: (user: IUser) => user.name,
            sortable: true,
        },
        {
            name: t('Email'),
            selector: (user: IUser) => user.email,
            sortable: true,
        },
        {
            name: t('Type'),
            selector: (user: IUser) => user.type,
            sortable: true,
            cell: (user: IUser) => {
                if(user.is_admin){
                    return t('admin')
                }

                if(user.is_trainer){
                    if(isTrialUser(user)){
                        return (
                            <div>
                                {t('trainer')}
                                &nbsp;
                                <span className="badge badge-warning">{t('trial')}</span>
                            </div>
                        )
                    }

                    return t('trainer')
                }

                return t('student')
            }
        },
    
        // {
        //     name: t('Requests'),
        //     sortable: true,
        //     selector: (user: IUser) => user.requests_this_month,
        // },
        // {
        //     name: t('Last time'),
        //     selector: (user: IUser) => user.last_seen,
        //     sortable: true,
        //     cell: (user: IUser) => {
        //         return user.last_seen
        //             ? `${moment(user.last_seen).fromNow()}`
        //             : ''
        //     },
        // },
     
        {
            name: t('Action'),
            cell: (row: any) => (
                <Button variant="outline-secondary" onClick={() => impersonate(row)} className='d-flex flex-row btn-outline'>
                    <i className='fa fa-user mt-1'/>
                    <span className='d-none d-lg-block'>
                        &nbsp; {t('Impersonate')}
                    </span>
                </Button>
            )
        }
    ]

    const onRowClicked = (user: IUser) => {
        // console.log(`/admin/users/${user.id}`)
        history.push(`/admin/users/${user.id}`)
    }

    return (
        <DataTable
            className=''
            columns={columns}
            data={filteredUsers}
            pagination
            paginationPerPage={persistentStore.paginationLength}
            paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
            onChangeRowsPerPage={(rowCount) => persistentStore.setPaginationLength(rowCount)}
            noHeader={true}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            onRowClicked={(user) => onRowClicked(user)}
            defaultSortAsc={false}
            pointerOnHover
            highlightOnHover
        />
    )
}

export default UsersTable
