import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import QuizContent from "./Content/Quizzes/QuizContent";
import './Template.scss'
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {useHistory, useParams} from "react-router-dom";
import {courses} from "../../api/courses";
import {ICourse} from "../../interfaces/ICourse";
import CourseContext from "../../contexts/CourseContext";
import {observer} from "mobx-react";
import {lessons as lessonsApi} from "../../api/lessons";
import {quizzes as quizzesApi} from "../../api/quizzes";
import UserLessonContext from "../../contexts/UserLessonContext";
import UserQuizContext from "../../contexts/UserQuizContext";
import LessonsContext from "../../contexts/LessonsContext";
import {IUserLesson} from "../../interfaces/IUserLesson";
import {IUserQuiz} from "../../interfaces/IUserQuiz";
import Loader from "../../components/Loader";
import persistentStore from "../../stores/persistentStore";
import QuizContext from "../../contexts/QuizContext";
import {usersQuizzes} from "../../api/usersQuizzes";

type QuizTemplateProps = {}

const QuizTemplate = (props: QuizTemplateProps) => {
    const {courseSlug, lessonSlug, quizSlug} = useParams<{ courseSlug: string, lessonSlug: string , quizSlug:string}>()
    const [course, setCourse] = useState<ICourse|undefined>(undefined)
    const [lessons, setLessons] = useState<IUserLesson[]>([])
    const [lesson, setLesson] = useState<IUserLesson | undefined>()
    const [quizzes, setQuizzes] = useState<IUserQuiz[]>([])
    const [quiz, setQuiz] = useState<IUserQuiz | undefined>()
    const [completedQuizzesID, setCompletedQuizzesID] = useState<number[]>([])
    const history = useHistory()
    
    const courseQuery = useQuery(
        ['course', courseSlug],
        () => courses.get(courseSlug),
        {
            enabled: courseSlug !== undefined,
        }
    )

    useEffect(() => {
        if(courseQuery.data){
            setCourse(courseQuery.data)
        }
    }, [courseQuery.data])

    // you always need to be logged in
    useEffect(() => {
        if(!persistentStore.loggedIn){
            return history.push(`/course/${courseSlug}/login`)
        }
    // eslint-disable-next-line
    }, [courseSlug])

    // get all lessons of the course including the progress of the current user under element 'lessons_users'
    const lessonsQuery = useQuery(
        ['lessons', courseSlug],
        () => {
            if (courseQuery.data.id) {
                return lessonsApi.getByCourse(courseQuery.data.id)
            }
        },
        {
            enabled: !courseQuery.isLoading
        }
    )

    const quizzesQuery = useQuery(
        ['quizzes', courseSlug],
        () => {
            if (courseQuery.data.id) {
                return quizzesApi.getByCourse(courseQuery.data.slug)
            }
        },
        {
            enabled: !courseQuery.isLoading
        }
    )

    const userQuizzesQuery = useQuery(
        ['userQuizzes'],
        () => {
            if (courseQuery.data.id && persistentStore.user?.id) {
                return usersQuizzes.getByUser(persistentStore.user.id)
            }
        },
        {
            enabled: !courseQuery.isLoading
        }
    )

    useEffect(() => {
        // Add the students' progress to the lessons
        if (lessonsQuery.data && courseQuery.data) {
            // const lessonProgresses: ILessonProgress[] = userLessonsQuery.data
            const courseLessons: IUserLesson[] = lessonsQuery.data

            const userLessons: IUserLesson[] = courseLessons.map((courseLesson) => {
                if (courseLesson.lessons_users && (courseLesson.lessons_users?.length || 0) > 0) {
                    const progress: { completed: boolean, last_position: number } = courseLesson.lessons_users[0]
                    courseLesson.completed = progress.completed
                    courseLesson.last_position = progress.last_position

                    return courseLesson
                }

                courseLesson.completed = false
                courseLesson.last_position = 0
                return courseLesson
            })

            setLessons(userLessons)
        }

        if(quizzesQuery.data && courseQuery.data){
            setQuizzes(quizzesQuery.data)
        }

    }, [lessonsQuery.data, courseQuery.data, quizzesQuery.data])

    // fetch the current quiz from all quizzes in the course
    useEffect(() => {
        // do nothing when loading is not done
        if (!quizzesQuery.data) {
            return
        }

        if(course && course.type === 'private' && ! persistentStore.loggedIn){
            alert('Too bad, you need to login first')
            history.push(`/course/${courseSlug}/login`)
        }

        if (quizzes && quizSlug && userQuizzesQuery.data ) {
            
            const tempCompletedQuizzesID = userQuizzesQuery.data.map((userQuiz: IUserQuiz) => {
                return userQuiz.quiz_id;
            });
            setCompletedQuizzesID(tempCompletedQuizzesID)

            const notCompletedQuizzes:IUserQuiz[] = quizzes.filter((quiz) => {
                return !tempCompletedQuizzesID.includes(quiz.id)
            })

            let currentQuiz: IUserQuiz | undefined = quizzes.find((coursequiz: IUserQuiz) => coursequiz.slug === quizSlug)

            if(tempCompletedQuizzesID.includes(currentQuiz?.id) && notCompletedQuizzes.length > 0){
                    history.push(`/course/${courseSlug}/take-quiz/${notCompletedQuizzes[0].slug}`)
            }
            
            if (currentQuiz) {
                currentQuiz.slug = quizSlug
                currentQuiz.completed = false
                setQuiz(currentQuiz)
            }
        }

        // eslint-disable-next-line
    }, [quizzes, quizSlug, course?.title, quiz?.name, quizzesQuery.data, userQuizzesQuery.data])
    
    // fetch the current lesson from all lessons in the course
    useEffect(() => {
        // do nothing when loading is not done
        if (!lessonsQuery.data) {
            return
        }

        if(course && course.type === 'private' && ! persistentStore.loggedIn){
            alert('Too bad, you need to login first')
            history.push(`/course/${courseSlug}/login`)
        }

        if(!quizSlug){
            if (lessons && lessonSlug) {
                const currentLesson: IUserLesson | undefined = lessons.find((courseLesson: IUserLesson) => courseLesson.slug === lessonSlug)
                if (currentLesson) {
                    currentLesson.courseSlug = courseSlug
                    setLesson(currentLesson)
    
                    // ensure the module this lesson is in, is opened
                    if(currentLesson.module_id){
                        persistentStore.openModule(currentLesson.module_id)
                    }
                }
            } else if (lessons && lessons.length > 0) {
                // get the first lesson
                lessons[0].courseSlug = courseSlug
                history.push(`/course/${courseSlug}/${lessons[0].slug}`)
            }
    
            // also, set the document (page) title
            document.title = `${course?.title}: ${lesson?.title}` || 'TrainingsOmgeving'
            // eslint-disable-next-line
        }
        
    }, [course, history, courseSlug, lessons, lessonSlug, course?.title, lesson?.title, lessonsQuery.data, quizSlug])

    if(! course){
        return <Loader />
    }

    return (
        <CourseContext.Provider value={course}>
            <LessonsContext.Provider value={lessons}>
                <UserLessonContext.Provider value={lesson}>
                    <QuizContext.Provider value={quizzes}>
                        <UserQuizContext.Provider value={quiz}>
                            <div className='template'>
                                <Header logo={course?.logo_url}/>

                                <main>
                                    <Sidebar completedQuizzesIds={completedQuizzesID}/>
                                    <QuizContent />
                                </main>
                            </div>
                        </UserQuizContext.Provider>
                    </QuizContext.Provider>
                </UserLessonContext.Provider>
            </LessonsContext.Provider>
        </CourseContext.Provider>
    )
};

export default observer(QuizTemplate)