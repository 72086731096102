import React, {useContext} from 'react';
import './ContentHeader.scss'
import {observer} from "mobx-react";
import UserLessonContext from "../../../contexts/UserLessonContext";
import {ILesson} from "../../../interfaces/ILesson";


type ContentHeaderProps = {
    // lesson: ILesson,
}

const ContentHeader = (props: ContentHeaderProps) => {
    const lesson: ILesson | undefined = useContext(UserLessonContext)
    
    return (
        <>
            
            <div className='content-header'>
                <h1>{lesson?.title}</h1>
                <h4>{lesson?.subtitle}</h4>
            </div>
        </>
        
    )
};

export default observer(ContentHeader);