import React from 'react';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";

type NavBarSearchProps = {}

const NavBarSearch = (props: NavBarSearchProps) => {
    const {t} = useTranslation()

    return (
        <form className="search-form d-none d-md-flex">
            <input type="text" className="form-control" placeholder={t('Search')}/>
            <button className="btn" type="button">
                <FontAwesomeIcon
                    icon={faSearch}
                    className="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                />
            </button>
        </form>
    )
};

export default NavBarSearch;