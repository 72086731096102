import './App.css';
import {Switch, Route} from "react-router";
import React from "react";
import {observer} from "mobx-react";
import Template from "./Templates/CF/Template";
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/app.scss'
import QuizTemplate from './Templates/CF/QuizTemplate';
import PageNotFound from "./components/PageNotFound";

function App() {

    return (
        <div className="App">
            <Switch>
                <Route exact path='/course/:courseSlug/:lessonSlug' component={Template}/>
                <Route exact path='/course/:courseSlug/take-quiz/:quizSlug' component={QuizTemplate}/>
                <Route exact path='/course/:courseSlug' component={Template}/>
                <Route component={() => <PageNotFound/>}/>
            </Switch>
        </div>
    );
}

export default observer(App)
