import React, {useEffect, useState} from 'react';
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import Nestable from "react-nestable";
import RenderLesson from "../../../utils/renderLesson";
import {ICourse} from "../../../interfaces/ICourse";
import {useTranslation} from "react-i18next";
import {ILesson} from "../../../interfaces/ILesson";
import {IModule} from "../../../interfaces/IModule";
import featureToggleStore from "../../../stores/featureToggleStore";
import {IQuiz} from "../../../interfaces/IQuiz";

type LessonListingProps = {
    courseSlug: string,
    addModule?: ()=>void,
    addQuiz?: ()=>void,
    course?: ICourse,
    storeNewLessonOrder: (items: any, item: any)=>void,
}

const LessonListing = (props: LessonListingProps) => {
    const {t} = useTranslation()
    const {course} = props
    const [lessonsModulesQuizzes, setLessonsModulesQuizzes] = useState<(ILesson|IModule|IQuiz)[]>([])

    // order the lessons and add the courseslug
    useEffect(() => {
        if(course){
            const orderedLessons = course.lessons.sort((a,b) => {
                if(! a.sort_order || ! b.sort_order) {return 0}
                return a.sort_order - b.sort_order
            })

            // add courseslug to all lessons
            const orderedLessonsWithCourseSlug = orderedLessons.map((lesson) => {
                lesson.courseSlug = course.slug
                return lesson
            })

            // now add the modules and order by sort_order
            const modules = course.modules || []
            const lessonsAndModules = [...orderedLessonsWithCourseSlug, ...modules]
                .sort((a, b) => {
                    // @ts-ignore
                    return (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0)
                })

            // now add the quizzes and order by sort_order
            const quizzes = course.quizzes || []
            const lessonsModulesAndQuizzes = [...lessonsAndModules, ...quizzes]
                .sort((a, b) => {
                    // @ts-ignore
                    return (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0)
                })

            setLessonsModulesQuizzes(lessonsModulesAndQuizzes)
        }
    }, [course])

    return (
        <Card>
            <Card.Header>
                <h4 className="card-title">{t('Lessons')}</h4>
            </Card.Header>
            <Card.Body>
                <p>
                    <Link to={`/admin/courses/${props.courseSlug}/lesson/add`} className="btn btn-primary">
                        {t('Add Lesson')}
                        <FontAwesomeIcon icon={faPlus} className="material-icons ml-2"/>
                    </Link>
                    <button
                        className='ml-2 btn btn-outline-secondary'
                        onClick={props.addModule}
                    >
                        {t('Add module')}
                        <FontAwesomeIcon icon={faPlus} className="material-icons ml-2"/>
                    </button>
                    {featureToggleStore.quizzes && (
                        <button
                            className='ml-2 btn btn-outline-secondary'
                            onClick={props.addQuiz}
                        >
                            {t('Add quiz')}
                            <FontAwesomeIcon icon={faPlus} className="material-icons ml-2"/>
                        </button>
                    )}
                </p>
                <div className="nestable" id="nestable-handles-primary">
                    <Nestable
                        items={lessonsModulesQuizzes}
                        renderItem={RenderLesson}
                        onChange={props.storeNewLessonOrder}
                    />
                </div>
            </Card.Body>
        </Card>
    )
};

export default LessonListing;