import React from 'react';
import App from "../App";
import {Switch, Route, Redirect} from "react-router-dom";
import LoginStudent from "../pages/Login/LoginStudent";
import AdminRoutes from "./AdminRoutes";
import ResetPasswordRequest from "../pages/Login/ResetPasswordRequest";
import ResetPassword from "../pages/Login/ResetPassword";
import LoginAdmin from "../pages/Login/LoginAdmin";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Enroll from "../pages/Enroll/Enroll";
import PrivateRoute from "../utils/PrivateRoute";
import OrderProcessing from "../pages/OrderProcessing/OrderProcessing";
import InvoicePage from "../pages/Invoice/InvoicePage";
import TrialPage from "../pages/Trial/TrialPage";
import Preview from "../Templates/CF/Preview";

const Routes = () => {

    return (
        <Switch>
            <Route exact path="/trial" component={TrialPage}/>
            <Route exact path='/reset-password-request' component={ResetPasswordRequest}/>
            <Route exact path='/reset-password/:selector/:validator' component={ResetPassword}/>
            <Route exact path='/course-preview/:courseSlug' component={Preview}/>
            <Route exact path='/course-preview/:courseSlug/:lessonSlug' component={Preview}/>
            <Route exact path='/course/:courseSlug/login' component={LoginStudent}/>
            <Route exact path='/enroll/:courseSlug' component={Enroll}/>/
            <Route exact path='/invoice/:public_id' component={InvoicePage}/>/
            <Route exact path='/orderprocessing/:orderPublicId' component={OrderProcessing}/>/
            <Route exact path='/forgot-password' component={ForgotPassword}/>
            <Route exact path='/admin/login' component={LoginAdmin}/>
            <PrivateRoute path='/admin' component={AdminRoutes}/>
            <Route path='/course' component={App}/>
            <Redirect to={'/admin/dashboard'} />
        </Switch>
    )
}

export default Routes;
