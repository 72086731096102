import {IModule} from "../interfaces/IModule";
import persistentStore from "../stores/persistentStore";
import axios from "axios";
import errorChecker from "./errorchecker";

const moduleRequests = () => {
    const updateMany = async (modules: IModule[]) => {
        const path = `/modules/update-many`;
        const config = persistentStore.authHeader;

        return axios.put(persistentStore.apiUrl + path, modules, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.modules) {
                console.log('The response did not contain modules')
                throw Error('The response did not contain modules')
            }

            return data.modules;
        });
    }

    const update = async (module: IModule) => {
        const path = `/modules/${module.id}`;
        const config = persistentStore.authHeader;

        return axios.put(persistentStore.apiUrl + path, module, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.module) {
                console.log('The response did not contain module')
                throw Error('The response did not contain module')
            }

            return data.module;
        });
    }

    const remove = async (id: string) => {
        const path = `/modules/${id}`;
        const config = persistentStore.authHeader;

        return axios.delete(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.module) {
                    console.log('The response did not contain module');
                    throw Error('The response did not contain module');
                }

                return data.module;
            });
    }

    const add = async (module: IModule) => {
        const path = `/modules`;
        const config = persistentStore.authHeader;

        return axios.post(persistentStore.apiUrl + path, module, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.module) {
                    console.log('The response did not contain module');
                    throw Error('The response did not contain module');
                }

                return data.module;
            });
    }

    return {
        add,
        remove,
        update,
        updateMany,
    }
}

export const modules = moduleRequests()
