import React from 'react';
import NavBarUser from "./NavBarUser";
import NavBarSearch from "./NavBarSearch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {Navbar} from "react-bootstrap";
import uiStore from "../../stores/uiStore";
import persistentStore from "../../stores/persistentStore";
import {ImpersonateHeader} from "../ImpersonateHeader";

type NavBarProps = {
    UserLoggedIn:boolean
}

const NavBar = (props: NavBarProps) => {
    return (
        <nav id="default-navbar"
             className="navbar navbar-expand navbar-dark bg-primary m-0 mdk-header--fixed">
            <div className="container-fluid">
                {props.UserLoggedIn && (
                    <button
                        className="navbar-toggler d-block"
                        data-toggle="sidebar"
                        type="button"
                        onClick={() => uiStore.toggleSidebar()}
                    >
                    <FontAwesomeIcon icon={faBars} />
                </button>)}
        
                <Navbar.Brand href={props.UserLoggedIn ? '/admin/dashboard' : '/courses'}>
                    <img src={process.env.PUBLIC_URL + "/img/logo/white.svg"} className="mr-2" alt="LearnPlus"/>
                    <span className="d-none d-xs-md-block">TrainingsOmgeving</span>
                </Navbar.Brand>

                {/*<ul className="nav navbar-nav navbar-nav-stats d-none d-md-flex flex-nowrap">*/}
                {/*    <li className="nav-item">*/}
                {/*        <div className="nav-stats">$591 <small>{t('GROSS')}</small></div>*/}
                {/*    </li>*/}
                {/*    <li className="nav-item">*/}
                {/*        <div className="nav-stats">$31 <small>{t('TAXES')}</small></div>*/}
                {/*    </li>*/}
                {/*    <li className="nav-item mr-3">*/}
                {/*        <div className="nav-stats">$560 <small>{t('NET')}</small></div>*/}
                {/*    </li>*/}
                {/*</ul>*/}

                <NavBarSearch />

                <div className="flex"></div>

                {persistentStore.isImpersonating && <ImpersonateHeader/>}

                <ul className="nav navbar-nav flex-nowrap">
                    {/*{props.UserLoggedIn && <NavBarNotifications />}*/}
                    {props.UserLoggedIn && <NavBarUser />}
                </ul>
            </div>
        </nav>
    )
};

export default NavBar;