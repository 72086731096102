import React, {useState} from 'react';
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/BreadCrumb";
import {Col, Row} from "react-bootstrap";
import '../../../scss/vendor/nestable.scss'
import {courses} from "../../../api/courses";
import {useLoading} from "../../../utils/useLoading";
import history from "../../../utils/history";
import {toast} from "react-toastify";
import {useQueryClient} from "react-query";
import BasicInformation from "./BasicInformation";
import MetaInfo from "./MetaInfo";
import CourseHeader from "./CourseHeader";
import Alert from "../../../components/Alert";
import CourseImage from "./CourseImage";
import CourseLogo from "./CourseLogo";

type CourseAddProps = {}

const CourseAdd = (props: CourseAddProps) => {
    const {t} = useTranslation()
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [loading, startLoading, stopLoading] = useLoading()
    const queryClient = useQueryClient()
    const [error, setError] = useState<string | undefined>()
    const [priceInCents, setPriceInCents] = useState<number>(0)
    const [type, setType] = useState<"public" | "private">('public')
    const [imageUrl, setImageUrl] = useState('')
    const [logoUrl, setLogoUrl] = useState('')

    const crumbs: IBreadCrumb[] = [
        {
            name: t('Home'),
            active: false,
            link: '/admin/dashboard'
        },
        {
            name: t('Courses'),
            active: false,
            link: '/admin/courses'
        },
        {
            name: t('Add'),
            active: true,
            link: '/admin/courses/add'
        },
    ]

    const saveCourse = () => {
        startLoading()

        if (title === '') {
            setError(t('Please provide a title for the course'))
            toast.error(t('You did not provide a title'))
            return
        }

        const newCourse = Object.assign(
            {},
            {
                id: '',
                title: title,
                description: description,
                type: type,
                duration: 0,
                image_url: imageUrl,
                logo_url: logoUrl,
                price_in_cents: priceInCents,
                lessons: [],
                modules: [],
                quizzes: [],
                sales: 0
            }
        )

        courses.add(newCourse)
            .then((course) => {
                stopLoading()
                toast.success(t('Course created'))
                queryClient.invalidateQueries('courses')

                history.push(`/admin/courses/${course.slug}/edit`)
            })
            .catch((error) => {
                stopLoading()
                toast.error(t('Error while creating course'))
            })
    }

    return (
        <div className="container-fluid page__container">
            <BreadCrumb crumbs={crumbs}/>

            {error && (
                <Alert message={error} type='danger'/>
            )}

            <CourseHeader
                loading={loading}
                saveCourse={saveCourse}
                title={t('Add Course')}
            />

            <Row>
                <Col md={8}>
                    <BasicInformation
                        title={title}
                        setTitle={setTitle}
                        type={type}
                        setType={setType}
                        description={description}
                        setDescription={setDescription}
                        saveCourse={saveCourse}
                    />
                </Col>

                <Col md={4}>
                    <CourseImage
                        imageUrl={imageUrl}
                        setImageUrl={setImageUrl}
                    />

                    <CourseLogo
                        logoUrl={logoUrl}
                        setLogoUrl={setLogoUrl}
                    />

                    <MetaInfo
                        priceInCents={priceInCents}
                        setPriceInCents={setPriceInCents}
                        saveCourse={saveCourse}
                    />
                </Col>
            </Row>
        </div>
    )
};

export default CourseAdd;