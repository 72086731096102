import React, {useContext, useEffect, useRef, useState} from 'react';
import ReactPlayer from "react-player/lazy";
import ToggleSlider from "../../../components/ToggleSlider";
import persistentStore from "../../../stores/persistentStore";
import {observer} from "mobx-react";
import MarkComplete from "../../../components/MarkComplete";
import {lessons as lessonsApi} from "../../../api/lessons";
import store from "../../../stores/store";
import {ILesson} from "../../../interfaces/ILesson";
import history from "../../../utils/history";
import {useTranslation} from "react-i18next";
import screenfull from "screenfull";
import UserLessonContext from "../../../contexts/UserLessonContext";
import {IUserLesson} from "../../../interfaces/IUserLesson";
import {useQueryClient} from "react-query";
import LessonsContext from "../../../contexts/LessonsContext";
import PreviewContext from "../../../contexts/PreviewContext";

type ContentVideoProps = {}

const ContentVideo = (props: ContentVideoProps) => {
    const [play, setPlay] = useState(false)
    const [videoEnded, setVideoEnded] = useState<boolean>(false)
    const [setupIsDone, setSetupIsDone] = useState(false)
    const lesson: IUserLesson|undefined = useContext(UserLessonContext)
    const playerRef = useRef<ReactPlayer>(null)
    const clientQuery = useQueryClient()
    const lessons = useContext(LessonsContext)
    const isPreview = useContext(PreviewContext)
    const {t} = useTranslation()

    // only start playing when all setup is done
    useEffect(() => {
        if (setupIsDone && persistentStore.autoplay) {
            setPlay(true)
        }
    }, [setupIsDone])

    useEffect(() => {
        if (screenfull.isEnabled) {
            // const elPlayer = document.getElementById('videoplayer')
            // elPlayer && screenfull.request(elPlayer);
        } else {
            // Ignore or do something else
            // console.log('screenfull not enabled')
        }
    }, [])

    if (!lesson) {
        return <div>{t('Loading lesson')}...</div>
    }

    const handleProgress = (progress: { played: number, playedSeconds: number, loaded: number, loadedSeconds: number }) => {
        if(isPreview) { return }

        if(! lesson.id){
            console.log('No lesson Id selected')
            return
        }

        if (progress.playedSeconds < 6) {
            // console.log('Not storing position under 6 seconds')
            return
        }

        lessonsApi.updateProgress(lesson.id, progress.playedSeconds)
            .then((result) => {
                clientQuery.invalidateQueries(['lessons', lesson.courseSlug])
            })
            .catch((error) => {
                console.error(error.response)
            })
    }

    const handleVideoEnded = () => {
        if(isPreview){ return }

        setVideoEnded(true)

        console.log('video ended')
        if (!lesson.id) {
            console.error('No lesson selected in store to mark as completed')
            return
        }

        lessonsApi.markComplete(lesson.id)
            .then((result) => {
                clientQuery.invalidateQueries(['lessons', lesson.courseSlug])
            })

        // reset the progress
        lessonsApi.updateProgress(lesson.id, 0)

        // if autoplay is on, go to the next video
        if (persistentStore.autoplay) {
            const nextLesson: ILesson | undefined = getNextLesson(lesson)

            if (nextLesson && nextLesson.id && nextLesson.slug) {
                store.setSelectedLessonId(nextLesson.id)
                history.push(`/course/${lesson.courseSlug}/${nextLesson.slug}`)
                setVideoEnded(false)
            }
        }
    }

    const getNextLesson = (currentLesson: ILesson) => {
        const allLessons = lessons
        const currentIndex = allLessons.findIndex((lesson: ILesson) => lesson.id === currentLesson.id)
        const nextIndex = currentIndex + 1

        if (allLessons.length > nextIndex) {
            return allLessons[nextIndex]
        }

        return undefined
    }

    const determineStartPosition = () => {
        if(isPreview){
            setSetupIsDone(true)
        }

        if (!playerRef || !playerRef.current) {
            // console.log('PLAYER IS ER NOG NIET KLAAR VOOR')
        }

        // stop the video from playing to prevent a 'blip' in which you see a second of the beginning of
        // the video followed by the seekTo() to kick in effect

        if (playerRef && playerRef.current && lesson) {
            // console.log(lesson)
            // console.log('SEEKING!!!!!!!!!!!!!!!!')
            if ((lesson.last_position || 0) > 0) {
                const last_position: number = lesson?.last_position || 44
                setTimeout(() => {
                    // console.log('executing the timeout function')
                    playerRef.current?.seekTo(last_position, 'seconds')
                    setSetupIsDone(true)
                }, 1000)
            } else {
                // console.log('no last position. Setup is done now')
                setSetupIsDone(true)
            }
        } else {
            setSetupIsDone(true)
        }
    }

    const toggleAutoplay = (shouldAutoplay: boolean) => {
        persistentStore.setAutoplay(shouldAutoplay)
    }

    // const setFullScreen = () => {
    //     persistentStore.setPlayFullScreen(true)
    // }

    return (
        <div className='content-video'>
            <div className='videoplayer' id='videoplayer'>
                <ReactPlayer
                    ref={playerRef}
                    url={lesson.video_url}
                    controls={true}
                    width='auto'
                    height='auto'
                    playing={play}
                    onEnded={handleVideoEnded}
                    onProgress={handleProgress}
                    onReady={determineStartPosition}
                />
            </div>

            <div className='video-controls'>
                <ToggleSlider
                    className='autoplay'
                    text='autoplay'
                    size='md'
                    type={"round"}
                    checked={persistentStore.autoplay}
                    onChange={(doAutoplay: boolean) => toggleAutoplay(doAutoplay)}
                />

                {! isPreview && (
                    <MarkComplete
                        className='mark-complete'
                        completed={lesson.completed || videoEnded}
                        onComplete={() => lesson.id && lessonsApi.markComplete(lesson.id)}
                        onInComplete={() => lesson.id && lessonsApi.markInComplete(lesson.id)}
                    />
                )}

                {/*<button onClick={setFullScreen}>fullscreen</button>*/}
            </div>
        </div>
    )
};

export default observer(ContentVideo)