import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import {courses as coursesApi} from "../../../api/courses";
import {IUser} from "../../../interfaces/IUser";
import {useParams} from "react-router-dom";
import CoursesContext from "../../../contexts/CoursesContext";
import {ICourse} from "../../../interfaces/ICourse";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import BreadCrumb from "../../../components/BreadCrumb";
import {Col, Row} from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../../../components/FilterComponent";
import persistentStore from "../../../stores/persistentStore";
import {useHistory} from "react-router-dom";
import moment from "moment";

type CourseStudentsProps = {}

const CourseStudents = (props: CourseStudentsProps) => {
    const {t} = useTranslation()
    const {courseSlug} = useParams<{ courseSlug: string }>()
    const courses: ICourse[] = useContext(CoursesContext)
    const course: ICourse | undefined = courses.find((course) => course.slug === courseSlug)
    const [filterText, setFilterText] = React.useState('');
    const [students, setStudents] = useState<IUser[]>([])
    const history = useHistory()
    const studentQuery = useQuery(
        [courseSlug, 'students'],
        () => coursesApi.getStudents(courseSlug),
        {enabled: courseSlug !== undefined}
    )

    useEffect(() => {
        if (studentQuery.data) {
            setStudents(studentQuery.data)
        }
    }, [studentQuery.data])

    const crumbs: IBreadCrumb[] = [
        {
            name: t('Home'),
            active: false,
            link: '/admin/dashboard'
        },
        {
            name: t('Courses'),
            active: false,
            link: '/admin/courses'
        },
        {
            name: course?.title || '',
            active: false,
            link: `/admin/courses/${course?.slug}/edit`
        },
        {
            name: t('Students'),
            active: true,
            link: `/admin/courses/${courseSlug}/students`
        },
    ]

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return <FilterComponent
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
        />;
    }, [filterText]);

    const filteredStudents = (students || [])
        .filter((student: IUser) => {
            if (filterText === '') {
                return true
            }
            const data = JSON.stringify(student).toLowerCase()
            const query = filterText.toLowerCase()

            return data && data.includes(query)
        })

    const columns = [
        {
            name: t('Name'),
            selector: (student: IUser) => student.name,
            sortable: true,
        },
        {
            name: t('Email'),
            selector: (student: IUser) => student.email,
            sortable: true,
        },
        {
            name: t('Last seen'),
            selector: (student: IUser) => student.last_seen || '',
            sortable: true,
            cell: (student: IUser) => {
                return student.last_seen
                    ? moment(student.last_seen).fromNow()
                    : ''
            },
        }
    ]

    const onRowClicked = (user: IUser) => {
        history.push(`/admin/students/${user.id}`)
    }

    return (
        <div className="container-fluid page__container">
            <BreadCrumb crumbs={crumbs}/>
            <div className="media align-items-center mb-headings">
                <div className="media-body">
                    <h1 className="h2">{course?.title}: {t('Students')}</h1>
                </div>
            </div>
            <Row>
                <Col md={12}>
                    <DataTable
                        columns={columns}
                        data={filteredStudents}
                        pagination
                        paginationPerPage={persistentStore.paginationLength}
                        paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
                        onChangeRowsPerPage={(rowCount) => persistentStore.setPaginationLength(rowCount)}
                        noHeader={true}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        onRowClicked={(student) => onRowClicked(student)}
                        defaultSortAsc={false}
                        pointerOnHover
                        highlightOnHover
                    />
                </Col>
            </Row>
        </div>
    )
};

export default CourseStudents;