import React, {useEffect, useState} from 'react';
import {Button, Modal, Row, Col} from "react-bootstrap";
import {IQuestion} from "../../../interfaces/IQuestion";
import {IQuizAnswer} from '../../../interfaces/IQuizAnswer';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

type QuestionModalProps = {
    hide: () => void,
    updateQuestion: (question: IQuestion) => void,
    question?: IQuestion,
    quiz_id: number,
    sortOrder: number
}

const QuestionModal = (props: QuestionModalProps) => {
    const {hide, question, quiz_id, updateQuestion, sortOrder} = props
    const {t} = useTranslation()
    const [title, setTitle] = useState<string>(question?.question || '')
    const [type, setType] = useState<string>(question?.type || 'input')

    /*** create a blank answer object for object.assign */
    let blankAns: any[] = [];
    if (type === 'input' || type === 'text') {
        blankAns = [{
            value: "",
            points: 0,
            is_correct: false,
        }]
    } else {
        blankAns = [{
            value: "",
            points: 0,
            is_correct: false,
        },
            {
                value: "",
                points: 0,
                is_correct: false,
            },
            {
                value: "",
                points: 0,
                is_correct: false,
            },
            {
                value: "",
                points: 0,
                is_correct: false,
            }];
    }

    const tempAnswers = (question?.quiz_answers || blankAns)
    const [answers, setAnswers] = useState<IQuizAnswer[]>(question?.quiz_answers || blankAns)

    useEffect(() => {

        if (question && question.type === type) {
            setAnswers(tempAnswers)
            return
        }

        let blankAns: any[] = [];
        if (type === 'input' || type === 'text') {
            blankAns = [{
                value: "",
                points: 0,
                is_correct: false,
            }]
        } else {
            blankAns = [{
                value: "",
                points: 0,
                is_correct: false,
            },
                {
                    value: "",
                    points: 0,
                    is_correct: false,
                },
                {
                    value: "",
                    points: 0,
                    is_correct: false,
                },
                {
                    value: "",
                    points: 0,
                    is_correct: false,
                }];
        }

        setAnswers(blankAns)
        //console.log(blankAns)
    }, [type, question, tempAnswers]);

    const changeAnswers = (value: string, index: number, valueUpdateFor: string) => {

        let temp_answers = [...answers];
        let temp_ans = {...temp_answers[index]};

        switch (valueUpdateFor) {
            case "value":
                temp_ans.value = value;
                break;
            case "points":
                temp_ans.points = parseInt(value);
                break;

            case "is_correct":
                temp_ans.is_correct = (value === 'true');
                break;

            default:
                break;
        }

        temp_answers[index] = temp_ans;
        setAnswers(temp_answers)
    }

    const handleSaveQuestion = () => {
        if (title === '') {
            toast.error(t('You did not provide a title'))
            return
        }

        // if(!question){
        //     const newQuestion: INewQuestion = Object.assign(
        //         {},
        //         {
        //             question:title,
        //             quiz_id:quiz_id,
        //             type:type,
        //             sort_order:sortOrder,
        //             quiz_answers:answers
        //         }
        //     );
        //     updateQuestion(newQuestion);
        // }else{

        const updatedQuestion: IQuestion = Object.assign(
            question,
            {
                question: title,
                quiz_id: quiz_id,
                type: type,
                sort_order: sortOrder,
                quiz_answers: answers
            }
        );
        updateQuestion(updatedQuestion);
        // }
    }

    const renderChoices = (correctType: string) => {

        let items = [];
        if (answers.length === 4 && (type === 'multiple-choice' || type === 'single-choice')) {
            //console.log(answers)
            for (let i = 0; i < 4; i++) {
                items.push(
                    <Row className="mb-2" key={`fsdf_${i}`}>
                        <Col md={4}>
                            <input
                                type="text"
                                className="form-control"
                                value={answers[i].value}
                                onChange={(event) => changeAnswers(event.target.value, i, 'value')}
                            />
                        </Col>
                        <Col md={4}>
                            <input type="number" className="form-control" max={100} value={answers[i].points}
                                   onChange={(event) => changeAnswers(event.target.value, i, 'points')}/>
                        </Col>
                        <Col md={4}>
                            {correctType === 'multiple-choice' && (
                                <input type="checkbox" name="multi-choice[]" value="is_correct"
                                       checked={answers[i].is_correct}
                                       onChange={(event) => changeAnswers(`${event.target.checked}`, i, 'is_correct')}/>
                            )}
                            {correctType === 'single-choice' && (
                                <input type="radio" name="multi-choice" value="is_correct"
                                       checked={answers[i].is_correct}
                                       onChange={(event) => changeAnswers(`${event.target.checked}`, i, 'is_correct')}/>
                            )}
                        </Col>
                    </Row>
                )
            }
        }
        return items;
    }

    return (
        <Modal show={true} onHide={hide} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{(question) ? `${t('Edit Question')}: ${question.question}` : t('Add Question')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row className="form-group">
                    <label htmlFor="qtitle" className="col-form-label form-label col-md-3">{t('Question')}:</label>
                    <Col md={9}>
                        <input id="qtitle" type="text" className="form-control" value={title}
                               onChange={(event) => setTitle(event.target.value)}/>
                    </Col>
                </Row>

                <Row className="form-group">
                    <label htmlFor="type" className="col-form-label form-label col-md-3">{t('Question type')}:</label>
                    <Col md={4}>
                        <select
                            id="type"
                            className="custom-control custom-select form-control"
                            value={type}
                            onChange={(event) => setType(event.target.value)}
                        >
                            <option value="input">{t('Short free text')}</option>
                            <option value="text">{t('Long free text')}</option>
                            <option value="multiple-choice">{t('Multiple choice, multiple answers')}</option>
                            <option value="single-choice">{t('Multiple choice, one correct answer')}</option>
                        </select>
                    </Col>
                </Row>

                {type === 'input' && (
                    <>
                        <Row className="form-group">
                            <label className="col-form-label form-label col-md-3">{t('Answer')}:</label>
                            <Col md={4}>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={answers[0].value}
                                    onChange={(event) => changeAnswers(event.target.value, 0, 'value')}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label className="col-form-label form-label col-md-3">{t('Score')}:</label>
                            <Col md={4}>
                                <input type="number" className="form-control" max={100} value={answers[0].points}
                                       onChange={(event) => changeAnswers(event.target.value, 0, 'points')}/>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label className="col-form-label form-label col-md-3">{t('Is Correct')}:</label>
                            <Col md={4}>
                                <input type="checkbox" value="is_correct" checked={answers[0].is_correct}
                                       onChange={(event) => changeAnswers(`${event.target.checked}`, 0, 'is_correct')}/>
                            </Col>
                        </Row>
                    </>
                )}

                {type === 'text' && (
                    <>
                        <Row className="form-group">
                            <label className="col-form-label form-label col-md-3">{t('Answer')}:</label>
                            <Col md={4}>
                                <textarea
                                    className="form-control"
                                    value={answers[0].value}
                                    onChange={(event) => changeAnswers(event.target.value, 0, 'value')}
                                >
                                    {answers[0].value}
                                </textarea>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label className="col-form-label form-label col-md-3">{t('Score')}:</label>
                            <Col md={4}>
                                <input type="number" className="form-control" max={100} value={answers[0].points}
                                       onChange={(event) => changeAnswers(event.target.value, 0, 'points')}/>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label className="col-form-label form-label col-md-3">{t('Is Correct')}:</label>
                            <Col md={4}>
                                <input type="checkbox" value="is_correct" checked={answers[0].is_correct}
                                       onChange={(event) => changeAnswers(`${event.target.checked}`, 0, 'is_correct')}/>
                            </Col>
                        </Row>
                    </>
                )}

                {type === 'multiple-choice' && (
                    <>
                        <Row className="form-group">
                            <label className="col-form-label form-label col-md-3">{t('Answers')}:</label>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <label className="col-form-label form-label">{t('Displayed Answers')}</label>
                            </Col>
                            <Col md={4}>
                                <label className="col-form-label form-label">{t('Points')}</label>
                            </Col>
                            <Col md={4}>
                                <label className="col-form-label form-label">{t('Is Correct')}</label>
                            </Col>
                        </Row>
                        {renderChoices('multiple-choice')}
                    </>
                )}

                {type === 'single-choice' && (
                    <>
                        <Row className="form-group">
                            <label className="col-form-label form-label col-md-3">{t('Answers')}</label>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <label className="col-form-label form-label">{t('Displayed Answers')}</label>
                            </Col>
                            <Col md={4}>
                                <label className="col-form-label form-label">{t('Points')}</label>
                            </Col>
                            <Col md={4}>
                                <label className="col-form-label form-label">{t('Is Correct')}</label>
                            </Col>
                        </Row>
                        {renderChoices('single-choice')}
                    </>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="link" onClick={hide}>
                    {t('Cancel')}
                </Button>

                <Button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleSaveQuestion()}
                >
                    {t('Submit')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default QuestionModal;
