import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {useTranslation} from "react-i18next";
import {faSlidersH} from "@fortawesome/free-solid-svg-icons/faSlidersH";
import {Button} from "react-bootstrap";
import CoursesContext from "../../../contexts/CoursesContext";

type CourseFilterProps = {
    searchKeyword:string,
    setSearchKeyword:(updatedSearchKeyword:string) => void
}

const CourseFilter = (props: CourseFilterProps) => {
    const {searchKeyword, setSearchKeyword} = props;
    const [showFilter, setShowFilter] = useState<boolean>(false)
    const courses = useContext(CoursesContext)
    const {t} = useTranslation()

    const toggleShowFilter = () => {
        setShowFilter(! showFilter)
    }

    const closeFilters = (event: MouseEvent) => {
        const container = document.getElementById('course-filter');

        if(! container){
            console.error('No container found for the eventlistener for closing an element')
            return
        }

        const clickedElement = event.target as Node
        if (!container.contains(clickedElement)) {
            // hide the submenu
            setShowFilter(false)
        }
    }

    useEffect(() => {
        if(showFilter){
            // add event listener which listens for a click outside of the opened element
            window.addEventListener('mouseup', closeFilters);
        }
        else {
            // remove event listener
            document.removeEventListener('mouseup', closeFilters)
        }

        return document.removeEventListener('mouseup', closeFilters)
    },[showFilter])

    return (
        <div className="card card-body border-left-3 border-left-primary navbar-shadow mb-4">
            <form action="#">
                <div className="d-flex flex-wrap2 align-items-center mb-headings">
                    <div className="dropdown">
                        <Button
                            data-toggle="dropdown"
                            className="btn btn-white"
                            onClick={toggleShowFilter}
                        >
                            <FontAwesomeIcon icon={faSlidersH} className='material-icons mr-sm-2' />
                            <span className="d-none d-sm-block">{t('Filters')}</span>
                        </Button>

                        <div id='course-filter' className={`dropdown-menu ${showFilter && 'show'}`}>
                            <div className="dropdown-item d-flex flex-column">
                                <div className="form-group">
                                    <label htmlFor="custom-select" className="form-label">
                                        {t('Category')}
                                    </label>
                                    <br/>
                                    <select
                                        id="custom-select"
                                        className="form-control custom-select"
                                        style={{width: '200px'}}
                                    >
                                        <option value="0">{t('All categories')}</option>
                                        <option value="1">Vue.js</option>
                                        <option value="2">Node.js</option>
                                        <option value="3">GitHub</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="published01" className="form-label">{t('Published')}</label>
                                    <br/>
                                    <select
                                        id="published01"
                                        className="form-control custom-select"
                                        style={{width: '200px'}}
                                    >
                                        <option value="0">{t('Published courses')}</option>
                                        <option value="1">{t('Draft courses')}</option>
                                        <option value="3">{t('All courses')}</option>
                                    </select>
                                </div>
                                <button role='link' >{t('Clear filters')}</button>
                            </div>
                        </div>
                    </div>

                    <div className="flex search-form ml-3 search-form--light">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t("Search courses")}
                            id="searchSample02"
                            value={searchKeyword}
                            onChange={(event) => setSearchKeyword(event.target.value)}
                        />
                        <button className="btn">
                            <FontAwesomeIcon icon={faSearch} className='material-icons' />
                        </button>
                    </div>
                </div>

                <div className="d-flex flex-column flex-sm-row align-items-sm-center"
                     style={{whiteSpace: "nowrap"}}>
                    <small className="flex text-muted text-uppercase mr-3 mb-2 mb-sm-0">
                        {t(
                            'Displaying {{current}} out of {{total}} courses',
                            {current: courses?.length, total: courses?.length}
                        )}
                    </small>
                    <div className="w-auto ml-sm-auto table d-flex align-items-center mb-0">
                        <small className="text-muted text-uppercase mr-3 d-none d-sm-block">
                            {t('Sort by')}
                        </small>

                        <Button variant={'link'} className="sort desc small text-uppercase">{t('Course')}</Button>
                        <Button variant={'link'} className="sort small text-uppercase ml-2">{t('Earnings')}</Button>
                        <Button variant={'link'} className="sort small text-uppercase ml-2">{t('Sales')}</Button>
                    </div>
                </div>
            </form>
        </div>
    )
};

export default CourseFilter;