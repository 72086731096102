import React, {useEffect, useState} from 'react';
import {useQueryClient} from "react-query";
import {Button, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import Nestable from "react-nestable";
import RenderQuizQuestion from "../../../utils/renderQuizQuestion";
import {IQuiz} from "../../../interfaces/IQuiz";
import {useTranslation} from "react-i18next";
import {IQuestion} from "../../../interfaces/IQuestion";
import QuestionModal from "./QuestionModal";
import {quizQuestions as questionsApi} from "../../../api/quizQuestions";
import {toast} from "react-toastify";

type QuestionsListingProps = {
    quiz: IQuiz,
    quiz_questions: IQuestion[],
    storeNewQuestionOrder: (items: any, item: any) => void,
}

const QuestionsListing = (props: QuestionsListingProps) => {
    const {t} = useTranslation()
    const {quiz} = props
    const [questions, setQuestions] = useState<(IQuestion)[]>([])
    const [showQuestionModal, setShowQuestionModal] = useState<boolean>(false)
    // const [currentQuestion, setCurrentQuestion] = useState<IQuestion | undefined>()
    const queryClient = useQueryClient()
    const QuizContext = React.createContext<IQuiz>(quiz)

    // order the questions and add the quizID
    useEffect(() => {
        if (!quiz) {
            return
        }

        const orderedQuestions: any = props.quiz_questions.sort((a, b) => {
            if (!a.sort_order || !b.sort_order) {
                return 0
            }
            return a.sort_order - b.sort_order
        })

        // add quizid to all questions
        const orderedQuestionsWithQuizId = orderedQuestions.map((question: IQuestion) => {
            question.quiz_id = quiz?.id
            return question
        })

        // now add the modules and order by sort_order

        const questions = orderedQuestionsWithQuizId
            .sort((a: any, b: any) => {
                // @ts-ignore
                return (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0)
            })

        setQuestions(questions)
        //alert(quiz.id)
    }, [quiz, props.quiz_questions])

    const saveQuestion = (question: IQuestion) => {

        questionsApi.add(question)
            .then((result) => {
                queryClient.invalidateQueries('quizzes')
                toast.success(t('Question added'))
                setShowQuestionModal(false);
            })
            .catch((error: any) => {
                console.error(error.response)
                toast.error(t('Question could not be added'))
            })
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <h4 className="card-title">{t('Questions')}</h4>
                </Card.Header>
                <Card.Body>
                    <p>
                        <Button
                            className='ml-2'
                            onClick={() => setShowQuestionModal(true)}
                        >
                            {t('Add Question')}
                            <FontAwesomeIcon icon={faPlus} className="material-icons ml-2"/>
                        </Button>
                    </p>
                    <QuizContext.Provider value={quiz}>
                        <div className="nestable" id="nestable-handles-primary">
                            <Nestable
                                items={questions}
                                renderItem={RenderQuizQuestion}
                                onChange={props.storeNewQuestionOrder}
                            />
                        </div>
                    </QuizContext.Provider>
                </Card.Body>
            </Card>

            {showQuestionModal && quiz?.id && (
                <QuestionModal
                    hide={() => setShowQuestionModal(false)}
                    updateQuestion={saveQuestion}
                    quiz_id={quiz?.id}
                    sortOrder={questions.length + 1}
                />
            )}
        </>
    )
};

export default QuestionsListing;
