import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";

const orderRequests = () => {
    const get = async (publicId: string) => {
        const path = `/orders/${publicId}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.order) {
                    throw Error('The response did not contain the created order');
                }

                return data.order
            })
    }

    return {
        get,
    }
}

const orders = orderRequests()
export default orders
