import {ILesson} from "../interfaces/ILesson";
import {IModule} from "../interfaces/IModule";
import {IQuiz} from "../interfaces/IQuiz";

export const isLesson = (itemToCheck: ILesson | IModule | IQuiz): itemToCheck is ILesson => {
    // real bad comparison
    // is Lesson when the object contains property 'content_text'
    // console.log(itemToCheck)
    return 'content_text' in itemToCheck
}

export const isQuiz = (itemToCheck: ILesson | IModule | IQuiz): itemToCheck is ILesson => {
    // real bad comparison
    // is Lesson when the object contains property 'content_text'
    // console.log(itemToCheck)
    return 'quiz_questions' in itemToCheck
}
