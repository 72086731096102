import React, {useContext} from 'react';
import '../ContentHeader.scss'
import {observer} from "mobx-react";
import UserQuizContext from "../../../../contexts/UserQuizContext";
import {IQuiz} from "../../../../interfaces/IQuiz";


type ContentHeaderProps = {}

const ContentHeader = (props: ContentHeaderProps) => {
    const quiz: IQuiz | undefined = useContext(UserQuizContext)
    //console.log(quiz)

    return (
    
        <div className='content-header'>
            <h1>{quiz?.name}</h1>
            
        </div>
    )
};

export default observer(ContentHeader);