import React, {FormEvent, useEffect, useState} from 'react';
import './LoginAdmin.scss'
import {useTranslation} from "react-i18next";
import {Button, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faKey} from "@fortawesome/free-solid-svg-icons/faKey";
import {Link, useHistory} from "react-router-dom"
import {users} from "../../api/users";
import Alert from "../../components/Alert";
import persistentStore from "../../stores/persistentStore";
import {ILoginPostData} from "../../interfaces/ILoginPostData";
import qs from "querystring";

type LoginProps = {}

const LoginAdmin = (props: LoginProps) => {
    const {t} = useTranslation()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<string | undefined>()
    const history = useHistory()

    // logout the user
    useEffect(() => {
        persistentStore.emptyStore()
    }, [])

    // when there is an email in the url, use it to pre-populate the inputfield
    useEffect(() => {
        const queryString = window.location.search.startsWith('?')
            ? window.location.search.slice(1)
            : window.location.search
        const queryParams = qs.parse(queryString)

        if (queryParams.hasOwnProperty('email') && typeof queryParams.email === 'string') {
            setEmail(queryParams.email)

            // set focus to password field
            document.getElementById('password')?.focus()
        }
    }, [])

    const setLoggedInCookie = () => {
        const domain = process.env.NODE_ENV === 'development'
            ? 'trainingsomgeving.local'
            : 'trainingsomgeving.nl'
        const d = new Date()
        const validDays = 30
        d.setTime(d.getTime() + (validDays*24*60*60*1000));
        const expires = d.toUTCString();
        const info={path: '/admin/dashboard'}
        document.cookie = `logged_in=${JSON.stringify(info)};expires=${expires};domain=${domain}`
    }

    const handleLogin = (event: FormEvent) => {
        event.preventDefault()
        setError(undefined)

        const loginPostData: ILoginPostData = {
            username: email,
            password: password,
            remember_me: true,
            target: "backend"
        }

        users.login(loginPostData)
            .then((result: any) => {
                
                if(result.data.user.is_admin === false && result.data.user.is_trainer === false){
                    
                    persistentStore.emptyStore()
                    setError(t('Only trainers can access the backend'))
                    //history.push('/admin/dashboard')
                    return 
                }
                
                setLoggedInCookie()
                history.push('/admin/dashboard')
            })
            .catch((error: any) => {
                if (error.response?.data?.errors?.title === "FAILURE_CREDENTIALS_INVALID") {
                    setError(t('Incorrect username or password'))
                    return
                }

                if (error.response?.data?.errors?.title === "FAILURE_CREDENTIALS_MISSING") {
                    // setError(t('Credentials are missing'))
                    setError(t('Incorrect username or password'))
                    return
                }

                if (error.response?.data?.errors?.title === "Access Denied") {
                    const message = error.response.data.errors.detail
                    setError(message)
                    return
                }

                console.error(error.response)
            })
    }

    return (
        <div className="login-page d-flex align-items-center">
            <div className="col-sm-8 col-md-6 col-lg-4 mx-auto">
                <Card className="navbar-shadow">
                    <Card.Header className="text-center">
                        <h4 className="card-title">{t('Login for trainers')}</h4>
                        <p className="card-subtitle">{t('Access your account')}</p>
                    </Card.Header>

                    {error && (
                        <Alert type="danger" message={error}/>
                    )}

                    <Card.Body className="card-body">
                        <form onSubmit={handleLogin} noValidate>
                            <div className="form-group">
                                <label className="form-label" htmlFor="email">{t('Your email address')}:</label>
                                <div className="input-group input-group-merge">
                                    <input
                                        id="email"
                                        type="email"
                                        className="form-control form-control-prepended pl-2"
                                        placeholder={t("Your email address")}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="password">{t('Your password')}:</label>
                                <div className="input-group input-group-merge">
                                    <input
                                        id="password"
                                        type="password"
                                        required
                                        className="form-control form-control-prepended pl-2"
                                        placeholder={t("Your password")}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <FontAwesomeIcon icon={faKey}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group ">
                                <Button type="submit" className="btn btn-primary btn-block">
                                    {t('Login')}
                                </Button>
                            </div>
                            <div className="text-center">
                                <Link
                                    to={`/reset-password-request?from=admin`}
                                    className="forgot-password text-black-70"
                                >
                                    {t('Forgot Password?')}
                                </Link>
                            </div>
                        </form>
                    </Card.Body>

                    <div className="card-footer text-center text-black-50">
                        {t('Not yet a trainer')}? <Link to="/trial">{t('Start your free trial week!')}</Link>
                    </div>
                </Card>
            </div>
        </div>
    )
};

export default LoginAdmin;
