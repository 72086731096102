import { makeAutoObservable} from 'mobx'
import {autoSave} from "./autoStore";

class FeatureToggleStore {
    lessonFiles: boolean = process.env.NODE_ENV === 'development'
    quizzes: boolean = process.env.NODE_ENV === 'development'

    constructor() {
        // store changes to the state in localStorage
        makeAutoObservable(this)
        autoSave(this, 'featureToggleStore')
    }
}

const featureToggleStore = new FeatureToggleStore()
export default featureToggleStore
