import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import Content from "./Content/Content";
import './Template.scss'
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {useHistory, useParams} from "react-router-dom";
import {courses} from "../../api/courses";
import {ICourse} from "../../interfaces/ICourse";
import CourseContext from "../../contexts/CourseContext";
import {observer} from "mobx-react";
import {lessons as lessonsApi} from "../../api/lessons";
import {quizzes as quizzesApi} from "../../api/quizzes";
import UserLessonContext from "../../contexts/UserLessonContext";
import UserQuizContext from "../../contexts/UserQuizContext";
import LessonsContext from "../../contexts/LessonsContext";
import {IUserLesson} from "../../interfaces/IUserLesson";
import {IUserQuiz} from "../../interfaces/IUserQuiz";
import Loader from "../../components/Loader";
import persistentStore from "../../stores/persistentStore";
import QuizContext from "../../contexts/QuizContext";

type TemplateProps = {}

const Template = (props: TemplateProps) => {
    const {courseSlug, lessonSlug, quizSlug} = useParams<{ courseSlug: string, lessonSlug: string , quizSlug:string}>()
    const [course, setCourse] = useState<ICourse|undefined>(undefined)
    const [lessons, setLessons] = useState<IUserLesson[]>([])
    const [lesson, setLesson] = useState<IUserLesson | undefined>()
    const [quizzes, setQuizzes] = useState<IUserQuiz[]>([])
    const [quiz, setQuiz] = useState<IUserQuiz | undefined>()
    const history = useHistory()

    const courseQuery = useQuery(
        ['course', courseSlug],
        () => courses.get(courseSlug),
        {
            enabled: courseSlug !== undefined,
        }
    )

    const courseHasPreviewLessonsQuery = useQuery(
        ['courseHasPreviewLessons', courseSlug],
        () => courses.hasPreviewLessons(courseSlug),
        {
            enabled: courseSlug !== undefined,
        }
    )

    // get all lessons of the course including the progress of the current user under element 'lessons_users'
    const lessonsQuery = useQuery(
        ['lessons', courseSlug],
        () => {
            if (courseQuery.data.id) {
                return lessonsApi.getByCourse(courseQuery.data.id)
            }
        },
        {
            enabled: persistentStore.loggedIn && !courseQuery.isLoading && ! courseHasPreviewLessonsQuery.isLoading && courseHasPreviewLessonsQuery.data !== true
        }
    )

    const quizzesQuery = useQuery(
        ['quizzes', courseSlug],
        () => {
            if (courseQuery.data.id) {
                return quizzesApi.getByCourse(courseQuery.data.slug)
            }
        },
        {
            enabled: persistentStore.loggedIn && !courseQuery.isLoading && ! courseHasPreviewLessonsQuery.isLoading && courseHasPreviewLessonsQuery.data !== true
        }
    )

    useEffect(() => {
        if(courseQuery.data){
            setCourse(courseQuery.data)
        }
    }, [courseQuery.data])

    // you always need to be logged in
    useEffect(() => {
        if(courseHasPreviewLessonsQuery.data === true && ! persistentStore.loggedIn) {
            return history.push(`/course-preview/${courseSlug}`)
        }

        // user is not logged in and the query for preview-lessons has run
        if(!persistentStore.loggedIn && ! courseHasPreviewLessonsQuery.isLoading){
            return history.push(`/course/${courseSlug}/login`)
        }
    }, [courseSlug, courseHasPreviewLessonsQuery, history])

    useEffect(() => {
        // Add the students' progress to the lessons
        if (lessonsQuery.data && courseQuery.data) {
            const courseLessons: IUserLesson[] = lessonsQuery.data

            const userLessons: IUserLesson[] = courseLessons.map((courseLesson) => {
                if (courseLesson.lessons_users && (courseLesson.lessons_users?.length || 0) > 0) {
                    const progress: { completed: boolean, last_position: number } = courseLesson.lessons_users[0]
                    courseLesson.completed = progress.completed
                    courseLesson.last_position = progress.last_position

                    return courseLesson
                }

                courseLesson.completed = false
                courseLesson.last_position = 0
                return courseLesson
            })

            setLessons(userLessons)
        }

        if(quizzesQuery.data && courseQuery.data){
            setQuizzes(quizzesQuery.data)
        }

    }, [lessonsQuery.data, courseQuery.data, quizzesQuery.data])

    // fetch the current quiz from all quizzes in the course
    useEffect(() => {
        
        // do nothing when loading is not done
        if (!quizzesQuery.data) {
            return
        }

        if(course && course.type === 'private' && ! persistentStore.loggedIn){
            history.push(`/course/${courseSlug}/login`)
        }

        if (quizzes && quizSlug) {
            const currentQuiz: IUserQuiz | undefined = quizzes.find((coursequiz: IUserQuiz) => coursequiz.slug === quizSlug)
            
            if (currentQuiz) {
                currentQuiz.slug = quizSlug
                currentQuiz.completed = false
                currentQuiz.last_position = 0
                
                setQuiz(currentQuiz)
            }
        }

        // eslint-disable-next-line
    }, [quizzes, quizSlug, course?.title, quiz?.name, quizzesQuery.data])
    
    // fetch the current lesson from all lessons in the course
    useEffect(() => {
        // do nothing when loading is not done
        if (!lessonsQuery.data) {
            return
        }

        if (lessons && lessonSlug) {
            const currentLesson: IUserLesson | undefined = lessons.find((courseLesson: IUserLesson) => courseLesson.slug === lessonSlug)

            if (currentLesson) {
                currentLesson.courseSlug = courseSlug
                setLesson(currentLesson)

                // ensure the module this lesson is in, is opened
                if(currentLesson.module_id){
                    persistentStore.openModule(currentLesson.module_id)
                }
            }
        } else if (lessons && lessons.length > 0) {
            // get the first lesson
            lessons[0].courseSlug = courseSlug
            history.push(`/course/${courseSlug}/${lessons[0].slug}`)
        }
    }, [lessons, lessonSlug, course, lesson?.title, lessonsQuery.data, courseSlug, history])

    if(! course){
        return <Loader />
    }

    return (
        <CourseContext.Provider value={course}>
            <LessonsContext.Provider value={lessons}>
                <UserLessonContext.Provider value={lesson}>
                    <QuizContext.Provider value={quizzes}>
                        <UserQuizContext.Provider value={quiz}>
                            <div className='template'>
                                <Header logo={course?.logo_url}/>

                                <main>
                                    <Sidebar completedQuizzesIds={[]}/>
                                    <Content/>
                                </main>
                            </div>
                        </UserQuizContext.Provider>
                    </QuizContext.Provider>
                </UserLessonContext.Provider>
            </LessonsContext.Provider>
        </CourseContext.Provider>
    )
};

export default observer(Template)