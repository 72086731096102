import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {IQuiz} from "../../../interfaces/IQuiz";

type QuizHeaderProps = {
    loading: boolean,
    saveQuiz: ()=>void,
    quiz?: IQuiz,
    title: string,
}

const QuizHeader = (props: QuizHeaderProps) => {
    const {loading, quiz, saveQuiz, title} = props
    const {t} = useTranslation()

    console.log(quiz)

    return (
        <div className="media align-items-center mb-headings">
            <div className="media-body">
                <h1 className="h2">{title}</h1>
            </div>

            <div className="media-right">
                {quiz?.course?.slug &&
                    <Link to={`/course/${quiz.course.slug}/take-quiz/${quiz.slug}`} className="mr-2" target='_blank'>
                        {t('Preview')}
                    </Link>
                }
                <button className="btn btn-success" onClick={saveQuiz}>
                    {loading && t('SAVING') + '...'}
                    {! loading && t('SAVE')}
                </button>
            </div>
        </div>
    )
};

export default QuizHeader;