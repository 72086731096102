import React, {useContext} from 'react';
import './Modules.scss'
import {observer} from "mobx-react";
import store from "../../../stores/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useParams} from "react-router-dom";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import history from "../../../utils/history";
import {faCircle} from "@fortawesome/free-regular-svg-icons/faCircle";
import {faCircle as fasCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import {IQuiz} from '../../../interfaces/IQuiz';
import QuizContext from '../../../contexts/QuizContext';

type QuizzesProps = {
    className?: string,
    completedQuizzesID: number[]
}

const Quizzes = (props: QuizzesProps) => {
    const {courseSlug, quizSlug} = useParams<{ courseSlug: string, quizSlug: string }>()
    const quizzes: IQuiz[] = useContext<IQuiz[]>(QuizContext)

    const selectQuiz = (quiz: IQuiz) => {
        // persistentStore.setSelectedLessonId(lesson.id)
        if (quiz.id && quiz.slug) {
            store.setSelectedQuizId(quiz.id)
            history.push(quiz.slug)
        }
    }

    return (
        <ul className={`modules ${props.className}`}>
            {quizzes.length > 0 && (
                <li className='lessons'>
                    {quizzes.map((quiz: IQuiz, index: number) => (
                        <div
                            key={index}
                            className={`lesson ${quizSlug === quiz.slug ? 'active' : ''}`}
                        >
                            {props.completedQuizzesID && props.completedQuizzesID.includes(quiz.id) ?
                                <Link
                                    to={`/course/${courseSlug}/take-quiz/${quiz.slug}`}
                                    onClick={() => selectQuiz(quiz)}
                                >
                                    <FontAwesomeIcon className='started mt-1' icon={fasCircle}/>
                                    <div>{quiz.name}</div>
                                    <FontAwesomeIcon icon={faAngleRight}/>
                                </Link>

                                : <Link
                                    to={`/course/${courseSlug}/take-quiz/${quiz.slug}`}
                                    onClick={() => selectQuiz(quiz)}
                                >
                                    <FontAwesomeIcon className='started mt-1' icon={faCircle}/>
                                    <div>{quiz.name}</div>
                                    <FontAwesomeIcon icon={faAngleRight}/>
                                </Link>
                            }
                        </div>
                    ))}
                </li>
            )}
        </ul>
    )
}

export default observer(Quizzes);