import React from "react";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import './ImpersonateHeader.scss'
import persistentStore from "../stores/persistentStore";
import {useQueryClient} from "react-query";

export const ImpersonateHeader = () => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()

    const stopImpersonating = () => {
        persistentStore.stopImpersonating()

        // clear cached querydata
        queryClient.invalidateQueries('courses')
        queryClient.invalidateQueries('settings')

        window.location.href = persistentStore.urlToReturnTo
    }

    return (
        <Button
            variant='danger'
            className='mr-2'
            onClick={() => stopImpersonating()}
        >
            <i className='fa fa-user' />&nbsp;
            {t('Stop impersonating')}
        </Button>
    );
};
