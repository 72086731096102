import React, {useState} from 'react';

type AlertProps = {
    message: string,
    type?: "primary" | "secondary" | "success" | "info" | "warning" | "danger" | "light" | "dark",
}

const Alert = (props: AlertProps) => {
    const {type} = props
    const [show, setShow] = useState(true)
    const hide = () => {
        setShow(false)
    }

    if(! show) {
        return <></>
    }

    return (
        <div className={`alert alert-light alert-dismissible border-1 border-left-3 border-left-${type || 'info'}`} role="alert">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={hide}>
                <span aria-hidden="true">&times;</span>
            </button>
            <div className="text-black-70">
                {props.message}
            </div>
        </div>
    )
};

export default Alert;