import React, {useContext} from 'react';
import {Card, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import QuizImage from './QuizImage';
import CoursesContext from "../../../contexts/CoursesContext";
import {ICourse} from '../../../interfaces/ICourse';

type BasicInformationProps = {
    title: string,
    setTitle: (title: string) => void,
    courseId: string,
    setCourseId: (courseId: string) => void,
    imageUrl: string | undefined,
    setImageUrl: (imageUrl: string) => void,
    saveQuiz: () => void,
}

const BasicInformation = (props: BasicInformationProps) => {
    const {t} = useTranslation()
    const courses: ICourse[] | undefined = useContext<ICourse[]>(CoursesContext)
    

    return (
        <Card className="card">
            <Card.Header>
                <h4 className="card-title">{t('Basic Information')}</h4>
            </Card.Header>

            <Card.Body>
                <form onSubmit={(e) => {e.preventDefault(); props.saveQuiz()}}>
                    <Row className="form-group">
                        <Col sm={3} >
                            <label htmlFor="quiz_title" className="col-form-label form-label">{t('Title')}</label>
                        </Col>
                        <Col sm={9} md={4}>
                            <input 
                                id="quiz_title" 
                                type="text"
                                className="form-control"
                                placeholder={t("Write a title")} 
                                value={props.title}
                                onChange={(event) => props.setTitle(event.target.value)}
                                autoComplete='off'
                            />
                        </Col>
                    </Row>

                    <Row className="form-group">
                        <Col sm={3} >
                            <label htmlFor="course_id" className="col-form-label form-label">{t('Course')}</label>
                        </Col>
                        <Col sm={9} md={4}>
                            <select 
                                id="course_id" 
                                className="custom-select form-control"
                                value={props.courseId}
                                onChange={(event) => props.setCourseId(event.target.value)} >
                                    <option value="" >{t('Select Course')}</option>
                                    {courses.map((course: ICourse, index:number) => (
                                        
                                       <option value={course.id} key={course.id}>{`${course.title}`}</option> 
                                    ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col sm={3}>
                            <label htmlFor="quiz_image" className="col-form-label form-label">{t('Image')}:</label>
                        </Col>
                        <Col sm={9} md={4} >
                            <QuizImage
                                imageUrl={props.imageUrl}
                                setImageUrl={props.setImageUrl}
                            />
                        </Col>
                    </Row>
                    {/* <Row className="form-group mb-0">
                        <Col sm={3} className="offset-sm-3"
                            <button type="submit" className="btn btn-success" onClick={props.saveQuiz} >
                                {props.loading && t('SAVING') + '...'}
                                {! props.loading && t('SAVE')}
                            </button>
                        </Col>
                    </Row> */}
                </form>
            </Card.Body>
        </Card>
    )
};

export default BasicInformation;