import React, {useEffect, useState} from 'react';
import store from "../../stores/store";
import uiStore from "../../stores/uiStore";
import {useTranslation} from "react-i18next";
import {faBookOpen} from "@fortawesome/free-solid-svg-icons/faBookOpen";
import {faUnlockAlt} from "@fortawesome/free-solid-svg-icons/faUnlockAlt";
import {IMenuItem} from "../../interfaces/IMenuItem";
import MenuItem from "./MenuItem";
import {observer} from "mobx-react";
import MenuSettings from "./MenuSettings";
import {faTachometerAlt} from "@fortawesome/free-solid-svg-icons/faTachometerAlt";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import featureToggleStore from "../../stores/featureToggleStore";

type MenuInstructorProps = {}

const MenuInstructor = (props: MenuInstructorProps) => {
    const [slug, setSlug] = useState('')
    const {t} = useTranslation()

    useEffect(() => {
        setSlug(window.location.pathname.substring('/admin/'.length))
    }, [])

    const menuItemClicked = (item: IMenuItem) => {
        if (store.isMobile()) {
            uiStore.setShowSidebar(false)
        }
        setSlug(item.slug)
    }

    const menuItems: IMenuItem[] = [
        {
            slug: 'dashboard',
            link: '/admin/dashboard',
            icon: faTachometerAlt,
            text: t('Dashboard'),
        },
        {
            slug: 'course-manager',
            link: '/admin/course-manager',
            icon: faBookOpen,
            text: t('Course Manager'),
        },
        // {
        //     slug: 'earnings',
        //     link: '/admin/earnings',
        //     icon: faChartLine,
        //     text: t('SalesTable'),
        // },
        // {
        //     slug: 'help-center',
        //     link: '/admin/help-center',
        //     icon: faHeadset,
        //     text: t('Help Center'),
        // },
        {
            slug: 'logout',
            link: '/admin/login',
            icon: faUnlockAlt,
            text: t('Logout'),
        },
    ]

    const quizMenuItem = {
        slug: 'quiz-manager',
        link: '/admin/quiz-manager',
        icon: faQuestionCircle,
        text: t('Quiz Manager'),
    }

    if (featureToggleStore.quizzes) {
        menuItems.splice(menuItems.length - 1, 0, quizMenuItem)
    }

    return (
        <>
            <div className="sidebar-heading">{t('Instructor')}</div>
            <ul className="sidebar-menu">
                {menuItems.map((item: IMenuItem, index: number) => (
                    <MenuItem item={item} active={slug === item.slug} click={menuItemClicked} key={index}/>
                ))}

                <MenuSettings className='mt-3'/>
            </ul>
        </>
    )
};

export default observer(MenuInstructor)