import {ILesson} from "../interfaces/ILesson";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {useQueryClient} from "react-query";
import {lessons as lessonsApi} from "../api/lessons";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import emptyVideoImg from "../assets/img/empty_video.jpg";
import moment from "moment/moment";
import {Button} from "react-bootstrap";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import React from "react";
import Row from "react-bootstrap/Row";
import './LessonListItem.scss'

type LessonListItemProps = {
    lesson: ILesson
}
const LessonListItem = (props: LessonListItemProps) => {
    const {lesson} = props
    const {t} = useTranslation()
    const history = useHistory()
    const queryClient = useQueryClient()

    const deleteLesson = (id: string) => {

        lessonsApi.remove(id)
            .then((result) => {
                queryClient.invalidateQueries('courses')
                toast.success(t('Lesson removed'))
            })
            .catch((error) => {
                console.error(error.response)
                toast.error(t('Lesson could not be removed'))
            })
    }

    return <div className="nestable-item nestable-item-handle lesson-list-item">
        <div className="nestable-handle">
            <FontAwesomeIcon icon={faBars} className="material-icons"/>
        </div>
        <div className="nestable-content">
            <div className="media align-items-center">
                <div className="media-left">
                    {!lesson.poster && (
                        <img src={emptyVideoImg} alt='no video thumbnail set' style={{width: '100%', height: '100%'}}/>)
                    }

                    {lesson.poster && (
                        <img
                            src={lesson.poster}
                            alt=""
                            width="100"
                            className="rounded"
                        />
                    )}
                </div>
                <Row className="media-body pl-2">
                    <h5 className="card-title h6 mb-0 col-12">
                        <Link to={`/admin/lessons/edit/${lesson.slug}`}>
                            {lesson.title}
                        </Link>
                    </h5>
                    <small className="text-muted col-12">
                        {t('updated')} {moment(lesson?.modified).format('D-M-YYYY HH:mm')}
                    </small>
                    {lesson.preview && (
                        <small className="text-muted free-preview col-12 mt-1">
                            {t('free preview')}
                        </small>
                    )}
                </Row>
                <div className="media-right">
                    <Button variant={"link"} onClick={() => history.push(`/admin/lessons/edit/${lesson.slug}`)}>
                        <FontAwesomeIcon icon={faPencilAlt} className="material-icons"/>
                    </Button>
                    <Button variant={"link"} onClick={() => lesson.id && deleteLesson(lesson.id)}>
                        <FontAwesomeIcon icon={faTrashAlt} className="material-icons text-danger"/>
                    </Button>
                </div>
            </div>
        </div>
    </div>
}

export default LessonListItem