import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react";
import persistentStore from "../../../../stores/persistentStore";
import UserQuizContext from '../../../../contexts/UserQuizContext';
import {IUserQuiz} from '../../../../interfaces/IUserQuiz';
import {useTranslation} from "react-i18next";
import {IQuestion} from '../../../../interfaces/IQuestion';
import {Button, Card, CardGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {IQuizAnswer} from '../../../../interfaces/IQuizAnswer';
import Loader from "../../../../components/Loader";
import QuizResult from "./QuizResult";
import {INewUserQuestion} from "../../../../interfaces/IUserQuestion";

type TakeQuizProps = {}

// /**
//  * A multiple choice question can have 1 IUserAnswer with multiple IUserAnswerValue
//  */
// interface IUserAnswer {
//     question_id: number,
//     answers: string[]
// }

interface IQuizProgress {
    answers_correct: number,
    answers_incorrect: number,
    total_points: number,
    answers: INewUserQuestion[]
}

const TakeQuiz = (props: TakeQuizProps) => {
    const {t} = useTranslation()
    const quiz: IUserQuiz | undefined = useContext(UserQuizContext)
    const [showResult, setShowResult] = useState<boolean>(false)
    const [currentQuestionNo, setCurrentQuestionNo] = useState<number>(0)
    const [quizProgress, setQuizProgress] = useState<IQuizProgress>({
        answers_correct: 0,
        answers_incorrect: 0,
        total_points: 0,
        answers: []
    })

    const [currentQuestion, setCurrentQuestion] = useState<IQuestion>()
    const [quizQuestions, setQuizQuestions] = useState<IQuestion[]>([])
    const [userAnswers, setUserAnswers] = useState<INewUserQuestion[]>([])

    /**
     * Go through all questions and provided answers and:
     * - determine the correct answers
     * - calculate the earned amount of points
     * - update counters: correct, incorrect, questions left
     */
    const updateQuizProgress = () => {
        // copy the quizProgress and reset the counters as we start from scratch
        const updatedQuizProgress = Object.assign(
            {},
            quizProgress,
            {
                answers_correct: 0,
                answers_incorrect: 0,
                total_points: 0
            }
        )

        // for each provided answer, see whether it's correct or not
        quizProgress.answers.forEach((answer: INewUserQuestion) => {
            const questionToCheck: IQuestion | undefined = quizQuestions.find(question => question.id === answer.quiz_question_id)

            if(! questionToCheck){
                throw new Error('Could not find question with id ' + answer.quiz_question_id + ' in this quiz')
            }

            // get all values from the question that are correct
            const correctAnswers: IQuizAnswer[] = questionToCheck.quiz_answers.filter(questionAnswer => questionAnswer.is_correct)

            // the question is _ONLY_ answered correctly when _ALL_ provided answers are correct.
            // in other words, ALL values in `answers` must exist in `correctAnswers`
            // In other words, when there are incorrect answers, this question is not answered correctly
            const correctAnswerValues = correctAnswers.map(correctAnswer => correctAnswer.value)
            const correctlyAnswered: IQuizAnswer[] = correctAnswers.filter(correctAnswer => answer.answers.includes(correctAnswer.value))

            const answeredCorrectly: boolean = correctlyAnswered.length === correctAnswerValues.length

            if(answeredCorrectly){
                answer.is_correct = true
                // bump counter
                updatedQuizProgress.answers_correct += 1
                // bump points for quiz
                updatedQuizProgress.total_points += correctlyAnswered.reduce((sum, answer) => sum + answer.points, 0)
            }
            else{
                answer.is_correct = false
                updatedQuizProgress.answers_incorrect += 1
            }
        })

        setQuizProgress(updatedQuizProgress)
    }

    // update the quizProgress whenever the answers change
    useEffect(() => {
        updateQuizProgress()
        // eslint-disable-next-line
    }, [quizProgress.answers])

    useEffect(() => {
        if (quiz && quiz.quiz_questions?.length && persistentStore.user) {
            const questions = quiz.quiz_questions
                .sort((a: IQuestion, b: IQuestion) => {
                    return (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0)
                })

            // initialize the userAnswers by setting the answers to an empty array for each question(id)
            const updatedUserAnswers = userAnswers
            questions.forEach(question => {
                const questionIdsWithAnswers = userAnswers.map(userAnswer => userAnswer.quiz_question_id)
                if(! questionIdsWithAnswers.includes(question.id)){
                    const emptyPlaceholderAnswer: INewUserQuestion = {
                        user_id: persistentStore.user?.id || '',
                        quiz_question_id : question.id,
                        is_correct : false,
                        quiz_id : quiz.id,
                        points : 0,
                        value: question.question,
                        answers: []
                    }
                    updatedUserAnswers.push(emptyPlaceholderAnswer)
                }
            })
            setUserAnswers(updatedUserAnswers)

            setQuizQuestions(questions)
            setCurrentQuestion(questions[0])
            setCurrentQuestionNo(0)
        }
        // eslint-disable-next-line
    }, [quiz])

    if (!currentQuestion || ! quiz) {
        return <Loader/>
    }

    const nextQuestion = () => {
        const nextQuestion: IQuestion[] = quizQuestions.filter((question: IQuestion, index: number) => {
            return index === currentQuestionNo + 1
        });

        if (currentQuestionNo < (quizQuestions.length - 1)) {
            setCurrentQuestionNo(currentQuestionNo + 1)
            setCurrentQuestion(nextQuestion[0]);
        }
    }

    const previousQuestion = () => {
        const previousQuestion: IQuestion[] = quizQuestions.filter((question: IQuestion, index: number) => {
            return index === currentQuestionNo - 1
        });

        if (currentQuestionNo > 0) {
            setCurrentQuestionNo(currentQuestionNo - 1)
            setCurrentQuestion(previousQuestion[0]);
        }
    }

    const skipQuestion = () => {
        nextQuestion()
    }

    /**
     * Add the provided answer to the current question to the userProvidedAnswers
     * @param answer
     */
    const updateUserAnswerSingleChoice = (answer: string) => {
        // only continue when the current question is known
        if (!currentQuestion?.quiz_id || !currentQuestion.id) {
            return
        }

        const updatedUserAnswer: INewUserQuestion = {
            quiz_question_id: currentQuestion.id,
            user_id: persistentStore.user?.id || '',
            is_correct : false,
            quiz_id : quiz.id,
            points : 0,
            value: currentQuestion.question,
            answers: [answer]
        }

        const updatedUserAnswers = userAnswers
            // remove existing answer
            .filter(userAnswer => userAnswer.quiz_question_id !== currentQuestion.id)

        // add the current answers
        updatedUserAnswers.push(updatedUserAnswer)

        setUserAnswers(updatedUserAnswers)
    }

    const addOrUpdateAnswerToQuestion = (answer: INewUserQuestion) => {
        const updatedAnswers: INewUserQuestion[] = quizProgress.answers.filter(quizAnswer => quizAnswer.quiz_question_id !== answer.quiz_question_id)
        updatedAnswers.push(answer)

        const updatedQuizProgress: IQuizProgress = Object.assign(
            {},
            quizProgress,
            {answers: updatedAnswers}
        )

        // update quizProgress. This also triggers a calculation of the score
        setQuizProgress(updatedQuizProgress)
    }

    const submitAllQuestions = () => {
        const userAnswerIds = userAnswers.map(answer => answer.quiz_question_id)
        const filteredAnswers: INewUserQuestion[] = quizProgress.answers.filter(answer => ! userAnswerIds.includes(answer.quiz_question_id))
        const updatedAnswers = [...filteredAnswers, ...userAnswers]

        const updatedQuizProgress: IQuizProgress = Object.assign(
            {},
            quizProgress,
            {answers: updatedAnswers}
        )

        setQuizProgress(updatedQuizProgress)
    }

    /**
     * Take the provided answer(s), add it to the quizProgress, calculate score and bump the counters
     */
    const submitQuestion = () => {
        if (!currentQuestion || !currentQuestion.id) {
            return;
        }

        // get the provided answers
        const providedAnswers = userAnswers.filter(answer => answer.quiz_question_id === currentQuestion.id)

        // only update quizProgress when the user chose an answer
        if(providedAnswers.length !== 1){
            console.error(providedAnswers)
            throw new Error('Unexpected length of providedAnswers')
        }

        if(providedAnswers.length === 1){
            // add the answer to the provided answers in the quizProgress
            addOrUpdateAnswerToQuestion(providedAnswers[0])
        }

        // next question
        nextQuestion()
    }

    const submitQuiz = () => {
        // first, submit all questions (thus: including the ones that were skipped)
        submitAllQuestions()

        // usersQuizzes.add(userQuestionStatus)
        //     .then((result) => {
        //         toast.success(t('Quiz updated'))
        //         queryClient.invalidateQueries('quizzes')
                setShowResult(true)
        //
        //     })
        //     .catch((error) => {
        //         //stopLoading()
        //         console.error(error.response)
        //         toast.error(error.response?.data?.errors?.detail)
        //     })
    }

    return (
        <div className="container-fluid page__container">

            {!showResult && (
                <>
                    <CardGroup>
                        <Card>
                            <Card.Body className="text-center">
                                <h4 className="mb-0"><strong>{quizQuestions.length}</strong></h4>
                                <small className="text-muted-light">{t('Total')}</small>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className="text-center">
                                <h4 className="text-success mb-0"><strong>{quizProgress.answers_correct}</strong></h4>
                                <small className="text-muted-light">{t('Correct')}</small>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className="text-center">
                                <h4 className="text-danger mb-0"><strong>{quizProgress.answers_incorrect}</strong></h4>
                                <small className="text-muted-light">{t('Wrong')}</small>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className="text-center">
                                <h4 className="text-primary mb-0"><strong>{quizQuestions.length - (Object.keys(quizProgress.answers).length)}</strong></h4>
                                <small className="text-muted-light">{t('Left')}</small>
                            </Card.Body>
                        </Card>
                    </CardGroup>

                    <Card>
                        <Card.Header>
                            <div className="media align-items-center">
                                <div className="media-left">
                                    <h4 className="mb-0"><strong>{`#${currentQuestionNo + 1}`}</strong></h4>
                                </div>
                                <div className="media-body">
                                    <Card.Title as="h4">{currentQuestion?.question}</Card.Title>
                                </div>
                            </div>
                        </Card.Header>

                        <Card.Body>
                            {/*{currentQuestion?.type === 'input' && (*/}
                            {/*    <div className="form-group">*/}
                            {/*        <label htmlFor="answer" className="form-label">{t('Answer')}</label>*/}
                            {/*        <input*/}
                            {/*            type="text"*/}
                            {/*            className="form-control"*/}
                            {/*            value={userProvidedAnswers[0].value}*/}
                            {/*            onChange={(e) => updateUserAnswer(e.target.value, 0, false)}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            {/*{currentQuestion?.type === 'text' && (*/}
                            {/*    <div className="form-group">*/}
                            {/*        <label htmlFor="answer" className="form-label">{t('Answer')}</label>*/}
                            {/*        <textarea*/}
                            {/*            className="form-control"*/}
                            {/*            value={userProvidedAnswers[0].value}*/}
                            {/*            onChange={(e) => updateUserAnswer(e.target.value, 0, false)}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            {/*{currentQuestion && currentQuestion.type === 'multiple-choice' && (*/}
                            {/*    (currentQuestion.quiz_answers || []).map((answer: IAnswer, index: number) => {*/}
                            {/*        return (*/}
                            {/*            <div className="form-group" key={`formgroup_${index}`}>*/}
                            {/*                <div className="custom-control custom-checkbox">*/}
                            {/*                    <input*/}
                            {/*                        id={`customCheck${index}`}*/}
                            {/*                        type="checkbox"*/}
                            {/*                        className="custom-control-input"*/}
                            {/*                        onChange={(e) => updateUserAnswer(answer?.id, index, e.target.checked)}*/}
                            {/*                    />*/}
                            {/*                    <label*/}
                            {/*                        htmlFor={`customCheck${index}`}*/}
                            {/*                        className="custom-control-label"*/}
                            {/*                    >*/}
                            {/*                        {answer.value}*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        )*/}
                            {/*    })*/}
                            {/*)}*/}

                            {currentQuestion?.type === 'single-choice' && (
                                (currentQuestion.quiz_answers || [])
                                    // filter out empty answers
                                    .filter((answer: IQuizAnswer) => answer.value !== '')
                                    .map((answer: IQuizAnswer, index: number) => (
                                        <div className="form-group" key={`formgroup_${index}`}>
                                            <div className="custom-control custom-radio">
                                                <input
                                                    id={`customCheck${index}`}
                                                    name="single-choice"
                                                    type="radio"
                                                    className="custom-control-input"
                                                    checked={(userAnswers.filter(answer => answer.quiz_question_id === currentQuestion.id)[0]['answers']||[]).includes(answer.value)}
                                                    onChange={(e) => updateUserAnswerSingleChoice(answer.value)}
                                                />
                                                <label
                                                    htmlFor={`customCheck${index}`}
                                                    className="custom-control-label"
                                                >
                                                    {answer.value}
                                                </label>
                                            </div>
                                        </div>
                                    ))
                            )}
                        </Card.Body>

                        <Card.Footer>
                            {currentQuestionNo > 0 && (
                                <Button className="btn-white" onClick={(e) => previousQuestion()}>
                                    {t('Previous')}
                                </Button>
                            )}

                            {currentQuestionNo < quizQuestions.length - 1 && (
                                <>
                                    <Button className="btn-white" onClick={(e) => skipQuestion()}>
                                        {t('Skip')}
                                    </Button>

                                    <Button
                                        className="btn-success float-right"
                                        onClick={(e) => submitQuestion()}
                                    >
                                        {t('Next')}
                                        <FontAwesomeIcon
                                            icon={faPaperPlane}
                                            className="material-icons btn__icon--right"
                                            transform={{rotate: 45}}
                                        />
                                    </Button>
                                </>
                            )}

                            {(currentQuestionNo + 1) === quizQuestions.length && (
                                <Button
                                    className="btn-success float-right"
                                    onClick={(e) => submitQuiz()}
                                >
                                    {t('Submit')}
                                </Button>
                            )}

                        </Card.Footer>
                    </Card>
                </>
            )}

            {showResult && (
                <QuizResult quizQuestions={quizQuestions} userQuestions={quizProgress.answers}/>
            )}
        </div>
    )
};

export default observer(TakeQuiz)