import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IMenuItem} from "../../interfaces/IMenuItem";

type MenuItemProps = {
    item: IMenuItem,
    active: boolean,
    click: (item: IMenuItem) => void,
}

const MenuItem = (props: MenuItemProps) => {

    return (
        <li className={`sidebar-menu-item ${props.active && 'active'}`}>
            <Link
                className="sidebar-menu-button sidebar-js-collapse"
                to={props.item.link}
                onClick={() => props.click(props.item)}
            >
                {props.item.icon && (
                    <FontAwesomeIcon
                        icon={props.item.icon}
                        className="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                    />
                )}
                {props.item.text}
            </Link>
        </li>
    )
};

export default MenuItem;