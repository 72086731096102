import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from "react-dropzone";
import {Alert, Card, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import store from "../../../stores/store";
import {toast} from "react-toastify";
import {lessons} from "../../../api/lessons";
import '../../../scss/vendor/fancytree.scss'
import persistentStore from "../../../stores/persistentStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderOpen} from "@fortawesome/free-regular-svg-icons/faFolderOpen";
import {faFile} from "@fortawesome/free-regular-svg-icons/faFile";

type CourseImageProps = {
    lessonId: string,
    files: any,
    setFiles: (files: any[]) => void,
}

const LessonFiles = (props: CourseImageProps) => {
    const {setFiles, files, lessonId} = props
    const [selectedFile, setSelectedFile] = useState('')
    const [selectedFileName, setSelectedFileName] = useState('')
    const [uploadError, setUploadError] = useState<string | undefined>()
    const {t} = useTranslation()

    const onDrop = useCallback(acceptedFiles => {
        // clear any upload errors
        setUploadError(undefined)

        const fileToUpload = acceptedFiles[0]
        if (!fileToUpload) {
            return
        }

        const sizeInMB: number = fileToUpload.size / 1000000;
        const fileTooLarge = sizeInMB > store.courseImageSizeLimit

        if (fileTooLarge) {
            const errorMessage: string = t('This file is over the maximum file limit of')
                + ' ' + store.courseImageSizeLimit + 'MB '
                + `): ${sizeInMB.toFixed(2)}M. ` +
                t('Please select a smaller image and try again')
            // get the file size in standard format
            setUploadError(errorMessage)
            return
        }

        setSelectedFileName(fileToUpload.name)
        setSelectedFile(fileToUpload)
        // eslint-disable-next-line
    }, [])

    // const removeFile = () => {
    //     // empty uploaded resources
    //     setSelectedFileName('')
    //     setSelectedFile('')
    //     setSelectedFileNamePreview('')
    //
    //     // clear out set image
    //     setImageUrl('')
    //     setImageSource(undefined)
    //     setDeletePoster(true)
    // }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png, image/gif, image/bmp, application/zip, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingm, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf'
    });

    // upload image when was dropped
    useEffect(() => {
        if (!selectedFile || !selectedFileName) {
            return
        }

        const formData = new FormData();
        formData.append(
            "lessonFile",
            selectedFile,
            selectedFileName
        )

        lessons.uploadFile({data: formData, lessonId: lessonId})
            .then((file: any) => {
                toast.success(t('Lesson file uploaded'))
                const newFiles = [...files, file]
                setFiles(newFiles)
            })
            .catch((error: any) => {
                console.error(error.response)
                toast.error(t('Could not upload lesson file'))
            })
        // eslint-disable-next-line
    }, [selectedFile, selectedFileName])

    // useEffect(() => {
    //     if (deletePoster) {
    //         setImageSource(undefined)
    //         setImageUrl('')
    //     } else if (selectedFile) {
    //         setImageSource(selectedFileNamePreview)
    //     } else if (imageUrl) {
    //         setImageSource(persistentStore.apiUrl + imageUrl)
    //     }
    //     // eslint-disable-next-line
    // }, [selectedFile, imageUrl, selectedFileNamePreview])

    return (
        <div className='d-block'>
            <Card>
                <Card.Header>
                    <Card.Title>{t('Files')}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={6} >
                            {uploadError && <Alert variant='danger'>{uploadError}</Alert>}

                            <div className='' {...getRootProps()} style={{minHeight: "150px"}}>
                                <div style={{
                                    display: 'flex',
                                    height: '150px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#89d1f6'
                                }}>
                                    <div style={{
                                        width: '80%',
                                        color: '#fafafa',
                                        fontSize: '30px',
                                        fontWeight: 'bolder',
                                        textAlign: 'center'
                                    }}>
                                        {!isDragActive && t('Drag a file here')}
                                        {isDragActive && isDragReject && t('This file extension is not allowed')}
                                        {isDragActive && !isDragReject && t('Drop it')}
                                    </div>
                                </div>
                            </div>
                            <input {...getInputProps()} />
                        </Col>
                        <Col md={6}>
                            {files && (
                                <ul>
                                    <li className="folder expanded">
                                        {files.length === 0 && t('No files for this lesson')}
                                        {files.length > 0 && (
                                            <>
                                                <FontAwesomeIcon icon={faFolderOpen} className={`material-icons md-18 mr-2`} />
                                                {t('Files for this lesson')}
                                            </>
                                        )}
                                        <ul>
                                            {files.map((file: any, index: number) => (
                                                <li key={index}>
                                                    <FontAwesomeIcon
                                                        icon={faFile}
                                                        className={`material-icons md-18 mr-2`}
                                                    />
                                                    <a
                                                        href={persistentStore.apiUrl + '/' + file.file_url}
                                                        rel="noreferrer"
                                                        target='_blank'
                                                    >
                                                        {file.file_name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                </ul>
                            )}
                            
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
};

export default LessonFiles;