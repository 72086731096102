import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import '../../../assets/vendor/dropzone.min.css'
import '../../../assets/css/dropzone.css'
import '../../../assets/css/fancytree.css'
import {ICourse} from "../../../interfaces/ICourse";
import CoursesContext from "../../../contexts/CoursesContext";
import {useParams} from "react-router-dom";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import BreadCrumb from "../../../components/BreadCrumb";
import {useLoading} from "../../../utils/useLoading";
import {ILesson} from "../../../interfaces/ILesson";
import {lessons} from "../../../api/lessons";
import {toast} from "react-toastify";
import history from "../../../utils/history";
import {useQueryClient} from "react-query";
import LessonHeader from "./LessonHeader";
import LessonInfo from "./LessonInfo";
import {IModule} from "../../../interfaces/IModule";

type LessonAddProps = {}

const LessonAdd = (props: LessonAddProps) => {
    const {t} = useTranslation()
    const [course, setCourse] = useState<ICourse | undefined>()
    const {courseSlug} = useParams<{ courseSlug: string }>();
    const courses: ICourse[] = useContext<ICourse[]>(CoursesContext)
    const [lastModule, setLastModule] = useState<IModule|null>(null)
    const queryClient = useQueryClient()
    const [loading, startLoading, stopLoading] = useLoading()
    const emptyLesson: ILesson = {
        sort_order: 100,
        content_text: "",
        image_url: "",
        poster: "",
        slug: "",
        subtitle: "",
        title: "",
        video_url: "",
        module_id: '',
        preview:false
    }
    const [updatedLesson, setUpdatedLesson] = useState<ILesson>(emptyLesson)

    const saveLesson = () => {
        startLoading()

        const newLesson: ILesson = Object.assign(
            {course_id: course?.id},
            emptyLesson,
            {module_id: lastModule?.id || null},
            updatedLesson
        )

        lessons.add(newLesson)
            .then((lesson: ILesson) => {
                stopLoading()
                queryClient.invalidateQueries('courses')
                toast.success(t('Lesson added'))
                history.push(`/admin/courses/${course?.slug}/edit`)
            })
            .catch((error) => {
                stopLoading()
                console.error(error.response)
                toast.error(error.response?.data?.errors?.detail)
            })
    }

    useEffect(() => {
        if (courses && courseSlug) {
            const courseToEdit = courses.find((course: ICourse) => course.slug === courseSlug)

            // get the last module so we can add the course to this module
            const orderedModules = courseToEdit?.modules.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0))
            if(orderedModules){
                setLastModule(orderedModules[orderedModules.length-1])
            }

            setCourse(courseToEdit)
        }
    }, [courses, courseSlug])

    const crumbs: IBreadCrumb[] = [
        {
            name: t('Home'),
            active: false,
            link: '/admin/dashboard'
        },
        {
            name: t('Courses'),
            active: false,
            link: '/admin/courses'
        },
        {
            name: course?.title || '',
            active: false,
            link: `/admin/courses/${course?.slug}/edit`
        },
        {
            name: t('Add Lesson'),
            active: false,
            link: undefined
        },
    ]

    return (
        <div className="container-fluid page__container">
            <BreadCrumb crumbs={crumbs}/>

            <LessonHeader
                title={t('Add Lesson')}
                saveLesson={saveLesson}
                loading={loading}
            />

            <LessonInfo
                lesson={updatedLesson}
                setLesson={setUpdatedLesson}
                saveLesson={saveLesson}
                course={course}
            />

            {/*<FileManager />*/}
        </div>
    )
}

export default LessonAdd;
