import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import {ICourse} from "../../../interfaces/ICourse";
import BreadCrumb from "../../../components/BreadCrumb";
import '../../../assets/vendor/dropzone.min.css'
import '../../../assets/css/dropzone.css'
import '../../../assets/css/fancytree.css'
import {ILesson} from '../../../interfaces/ILesson';
import {useQuery, useQueryClient} from "react-query";
import {lessons} from "../../../api/lessons";
import 'react-quill/dist/quill.snow.css'
import {useLoading} from "../../../utils/useLoading";
import {toast} from "react-toastify";
import LessonHeader from "./LessonHeader";
import LessonInfo from "./LessonInfo";
import LessonFiles from "./LessonFiles";
import featureToggleStore from "../../../stores/featureToggleStore";

type LessonAddProps = {}

const LessonEdit = (props: LessonAddProps) => {
    const {t} = useTranslation()
    const {lessonSlug} = useParams<{ lessonSlug: string }>();
    const lessonQuery = useQuery<ILesson | undefined>(
        ['lesson', lessonSlug],
        () => lessons.getBySlug(lessonSlug),
        {enabled: lessonSlug !== undefined}
    )
    const [lesson, setLesson] = useState<ILesson>()
    const [course, setCourse] = useState<ICourse | undefined>()
    const [loading, startLoading, stopLoading] = useLoading()
    const [files, setFiles] = useState<any[] | undefined>([])
    const queryClient = useQueryClient()

    const crumbs: IBreadCrumb[] = [
        {
            name: t('Home'),
            active: false,
            link: '/admin/dashboard'
        },
        {
            name: t('Courses'),
            active: false,
            link: '/admin/courses'
        },
        {
            name: course?.title || '',
            active: false,
            link: `/admin/courses/${course?.slug}/edit`
        },
        {
            name: t('Edit Lesson') + `: ${lesson?.title}`,
            active: false,
            link: undefined
        },
    ]

    useEffect(() => {
        if (lessonSlug && lessonQuery.data) {
            setLesson(lessonQuery.data)
            setFiles(lessonQuery.data.lesson_files)

            if (lessonQuery.data?.course) {
                setCourse(lessonQuery.data?.course)
            }
        }
    }, [lessonQuery.data, lessonSlug])

    const saveLesson = () => {
        startLoading()

        const updatedLesson: ILesson = Object.assign(
            {
                slug: lessonSlug,
                course_id: course?.id
            },
            lesson,
        )

        lessons.update(updatedLesson)
            .then((result) => {
                stopLoading()
                toast.success(t('Lesson updated'))
                queryClient.invalidateQueries( ['lesson', lessonSlug])
                queryClient.invalidateQueries('courses')
                // history.push(`/admin/courses/${course?.slug}/edit`)
            })
            .catch((error) => {
                stopLoading()
                console.error(error.response)
                toast.error(error.response?.data?.errors?.detail)
            })
    }

    return (
        <div className="container-fluid page__container">
            <BreadCrumb crumbs={crumbs}/>

            <LessonHeader
                saveLesson={saveLesson}
                loading={loading}
                course={course}
                lessonSlug={lessonSlug}
                lesson={lesson}
            />

            {lesson && course && (
                <LessonInfo lesson={lesson} setLesson={setLesson} saveLesson={saveLesson} course={course}/>
            )}

            {featureToggleStore.lessonFiles && lesson && lesson.id && (
                <LessonFiles files={files} setFiles={setFiles} lessonId={lesson.id}/>
            )}
        </div>
    )
};

export default LessonEdit;