import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";

const saleRequests = () => {

    const getToday = async () => {
        const path = `/get-today-sales/`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data

                if(data.success){
                    // return data so the requesting party can check for 'success' as well
                    return data.data.courses
                }

                console.error(response)
                throw new Error('Could not get earnings')
            })
    }

    return {
        getToday,
    }
}

export const sales = saleRequests()
