import React, {useEffect, useState} from "react";
import "./OrderProcessing.scss"
import {useTranslation} from "react-i18next";
import {Image, Col, Row, Container, Card} from 'react-bootstrap'
import {IOrder} from "../../interfaces/IOrder";
import Button from "react-bootstrap/Button";
import persistentStore from "../../stores/persistentStore";
import {useQuery} from "react-query";
import {useHistory, useParams} from "react-router-dom";
import orders from "../../api/orders";

const OrderProcessing = () => {
    const history = useHistory()
    const {orderPublicId} = useParams<{orderPublicId: string}>()
    const [order, setOrder] = useState<IOrder>({} as IOrder)
    const [count, setCount] = useState(0)
    const [queryShouldrefetch, setQueryShouldRefetch] = useState(true)
    const orderQuery = useQuery(
        ['order', orderPublicId],
        () => orders.get(orderPublicId),
        {
            enabled: queryShouldrefetch,
        }
    )

    const {t} = useTranslation();

    useEffect(() => {
        const hasPublicId = (orderPublicId !== undefined)
        const statusIsNotPaid = (order.status !== 'paid')
        const countIsBelowThreshold = (count < 30)
        const shouldRefetch = hasPublicId && statusIsNotPaid && countIsBelowThreshold
        //console.log(shouldRefetch)
        setQueryShouldRefetch(shouldRefetch)
    }, [orderPublicId, order.status, count])

    useEffect(() => {
        if (orderQuery.data && queryShouldrefetch) {
            setCount((previous: number) => previous + 1)
            setOrder(orderQuery.data)
            // console.log(orderQuery.data)
            // console.log(`count: ${count}`)

            if (orderQuery.data.status === 'open') {
                // when the order is still open, return the user to the order page
                // this happens when the payment was cancelled by the user
                // window.location.replace(`/order/${orderQuery.data.seller_id}`)
                // console.log('Je wordt teruggestuurd')
            } else {
                // when order is closed, remove the stored order from the store
                // persistentStore.setChosenCardType(undefined)
                persistentStore.setOrder(undefined)
            }
        }

        // disable, otherwise `order` needs to be in there and that causes an infinite loop
        // eslint-disable-next-line
    }, [orderQuery, orderPublicId])

    const HeaderSection = () => {
        let logo = <h2>{order?.course?.title}</h2>
        if (order?.course?.logo_url) {
            logo =
                <Image
                    src={`${persistentStore.apiUrl}${order?.course?.logo_url}`}
                    alt='logo'
                    style={{maxWidth: '250px'}}
                    className='col-6'
                />
        }
        return (logo);
    }

    if (orderQuery.data?.status === 'paid') {
        // clear the variables that were set in the order process
        // persistentStore.setBuyer({name: '', email: ''})
        persistentStore.setOrder(undefined)
        // persistentStore.setChosenCardType(undefined)
    }

    return (
        <div>
            <Container className="mt-5 pt-5">
                <Card className="navbar-shadow card-width-700">
                    <Row className="block-row pb-5">
                        <Col md={12} className="text-center mt-5">
                            <HeaderSection/>
                        </Col>
                        <Col md={{span:10, offset:1}} className="text-center align-center">
                            <hr 
                                style={{
                                    color: "#F5F6F7",
                                    backgroundColor: "#F5F6F7",
                                    height: 1
                            }} /> 
                        </Col>
                        <Col md={12} className="text-center">
                            <h3 style={{color:"inherit"}}>{t('Thankyou for your order')}</h3>
                        </Col>
                        <Col md={{span:10, offset:1}} className="text-center pt-5" style={{backgroundColor: "#F5F6F7"}}>
                            <h4 style={{color:"inherit"}}>{t('Order Confirmation')}</h4>
                            {order.status === 'paid' && (
                                <Col md={12} className="text-center pt-2">
                                    <p>{t("Thank you!")}</p>
                                    <p>{t("Your order is being processed")}.</p>
                                    <p>{t("The invoice for your order has been sent by email also credentials are sent by email")}. {t('Please check your spambox if you have not received the email within 5 minutes')}</p>
                                    <p>{t(`If you have any questions about this order, send an email to {{sellerName}} at {{sellerMail}}`, {
                                        sellerName: order?.seller?.name,
                                        sellerMail: order?.seller?.email
                                    })}</p>
                                </Col>
                            )}

                            {order.status === 'open' && (
                                <Col md={12} className="text-center pt-2">
                                    <p>{t("The order is processing")}</p>
                                    <p>{t("Current status")}: {order.status}</p>
                                </Col>
                            )}

                            {orderQuery.isLoading && (
                                <>
                                    <span className="sr-only">Controleren op status...</span>
                                    <div className="spinner-grow" role="status">
                                        <span className="sr-only">Controleren op status...</span>
                                    </div>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card>
                {(order?.buyer?.public_id) && (
                    <Row>
                        <Button
                            onClick={() => history.push(`/status/${order?.buyer?.public_id}`)}
                        >
                            {t('To my personal page')}
                        </Button>
                    </Row>
                )}
            </Container>
        </div>
    );
};

export default OrderProcessing;
