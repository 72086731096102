import React, {useContext} from 'react';
import SidebarPreview from "./SidebarPreview";
import Modules from "./Modules";
import Quizzes from "./Quizzes";
import './Sidebar.scss'
import {observer} from "mobx-react";
import CourseContext from "../../../contexts/CourseContext";

type SidebarProps = {
    completedQuizzesIds: number[],
    withoutQuizzes?: boolean,
}

const Sidebar = (props: SidebarProps) => {
    const {completedQuizzesIds, withoutQuizzes} = props
    const course = useContext(CourseContext)
    const includeQuizzes: boolean = withoutQuizzes !== true

    return (
        <div className='sidebar'>
            <SidebarPreview img={course?.image_url || ''} />

            {includeQuizzes &&(
                <Quizzes className="mt-3" completedQuizzesID={completedQuizzesIds} />
            )}

            <Modules className='mt-3' />
        </div>
    )
};

export default observer(Sidebar);