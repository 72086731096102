import {ICourse} from "../interfaces/ICourse";
import persistentStore from "../stores/persistentStore";
import axios, {AxiosResponse} from "axios";
import errorChecker from "./errorchecker";
import {IUser} from "../interfaces/IUser";

const courseRequests = () => {

    const getFromTrainer = async (trainerId: string) => {
        const path = `/users/${persistentStore.user?.id}/courses`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.courses) {
                console.log('The response did not contain courses');
                throw Error('The response did not contain courses');
            }

            return data.courses;
        });
    }

    const update = async (course: ICourse) => {
        const path = `/courses/${course.slug}`;
        const config = persistentStore.authHeader;

        return axios.put(persistentStore.apiUrl + path, course, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.course) {
                console.log('The response did not contain course');
                throw Error('The response did not contain course');
            }

            return data.course;
        });
    }

    const add = async (course: ICourse) => {
        const path = `/courses`;
        const config = persistentStore.authHeader;

        return axios.post(persistentStore.apiUrl + path, course, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.course) {
                console.log('The response did not contain course');
                throw Error('The response did not contain course');
            }

            return data.course;
        });
    }

    const isPublic = async (courseSlug: string) => {
        const path = `/courses/is-public/${courseSlug}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data
            if (!data.hasOwnProperty('is_public')) {
                console.log('The response did not contain response');
                throw Error('The response did not contain response');
            }

            return data.is_public;
        });
    }

    const getStudents = async (courseSlug: string): Promise<IUser[]> => {
        const path = `/courses/${courseSlug}/students`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.students) {
                    console.log('The response did not contain students');
                    throw Error('The response did not contain students');
                }

                return data.students
            });
    };

    const hasPreviewLessons = async (courseSlug: string) => {
        const path = `/courses/has-preview-lessons/${courseSlug}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.hasOwnProperty('has_preview_lessons')) {
                    console.log(data);
                    throw Error('The response did not contain proper response');
                }

                return data.has_preview_lessons;
            });
    }

    const getPreview = async (courseSlug: string) => {
        const path = `/courses/preview/${courseSlug}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.course) {
                    console.log('The response did not contain course');
                    throw Error('The response did not contain course');
                }

                return data.course;
            });
    }

    const get = async (courseSlug: string) => {
        const path = `/courses/${courseSlug}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.course) {
                    console.log('The response did not contain course');
                    throw Error('The response did not contain course');
                }

                return data.course;
            });
    }

    const del = async (courseSlug: string) => {
        const path = `/courses/${courseSlug}`;
        const config = persistentStore.authHeader;

        return axios.delete(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.course) {
                    console.log('The response did not contain course');
                    throw Error('The response did not contain course');
                }

                return data.course;
            });
    }

    const uploadImage = async (data: FormData) => {
        const path = `/courses/upload-image`
        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then((response: AxiosResponse) => {
                const data = response.data.data;
                if (!data.image) {
                    console.log('The response did not contain image');
                    throw Error('The response did not contain image');
                }

                return data.image;
            })
    }

    type enrollProps = {
        course_slug: string,
        email: string
    }

    const enroll = async (props: enrollProps) => {
        const path = `/courses/enroll/${props.course_slug}`

        return axios.post(persistentStore.apiUrl + path, props, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then((response: AxiosResponse) => {
                const data = response.data.data;
                if (!data.message) {
                    console.log('The response did not contain message');
                    throw Error('The response did not contain message');
                }

                return data.message;
            })
    }

    return {
        add,
        update,
        del,
        enroll,
        hasPreviewLessons,
        isPublic,
        uploadImage,
        get,
        getPreview,
        getStudents,
        getFromTrainer,
    }
}

export const courses = courseRequests()
