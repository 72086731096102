import React from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ICourse} from "../../interfaces/ICourse";
import './ChooseCourseModal.scss'

type ChooseCourseModalProps = {
    show: boolean,
    handleSubmit: (course: ICourse) => void,
    courses: ICourse[]
}

const ChooseCourseModal = (props: ChooseCourseModalProps) => {
    const {show, handleSubmit, courses} = props
    const {t} = useTranslation()

    const chooseCourse = (course: ICourse) => {

        handleSubmit(course)
    }

    return (
        <Modal show={show} className='choose-course-modal'>
            <Modal.Header>
                <h2>{t('Choose the course to login to')}</h2>
            </Modal.Header>

            <Modal.Body>
                <p className='mb-4'>
                    {t('You are enrolled in multiple courses')}.&nbsp;
                    {t('Choose the course you want to login to')}.
                </p>

                {courses.map((course: ICourse, index: number) => (
                    <div
                        key={index}
                        className="course-block media align-items-center mb-3"
                        style={{cursor: 'pointer'}}
                        onClick={() => chooseCourse(course)}
                    >
                        <div className="avatar avatar-4by3 avatar-sm mr-3">
                            <img src={course.image_url} alt="course" className="avatar-img rounded"/>
                        </div>

                        <div className="media-body">
                            <strong>{course.title}</strong>

                            <br/>

                            <small className="course-description mr-1">
                                {course.description}
                            </small>
                        </div>
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    )
};

export default ChooseCourseModal;