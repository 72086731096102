import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import Routes from "./routes/Routes";
import {Router} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import * as Sentry from "@sentry/react"
import {Integrations} from "@sentry/tracing";
import persistentStore from "./stores/persistentStore";
import './utils/i18n'
import i18next from 'i18next'
import history from "./utils/history";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import ClearCache from "./pages/ClearCache/ClearCache";
import {Route} from "react-router";
import {deviceType} from "react-device-detect";
import store from "./stores/store";
import Loader from "./components/Loader";
import LoadingContext from "./contexts/LoadingContext";

store.setDeviceType(deviceType)

// Create a client
const queryClient = new QueryClient()

if (process.env.REACT_APP_API_URL) {
    persistentStore.setApiUrl(process.env.REACT_APP_API_URL)
}

if (process.env.REACT_APP_IPD_TOKEN) {
    persistentStore.setIPDToken(process.env.REACT_APP_IPD_TOKEN)
}

const hotjar = document.createElement('script')
hotjar.innerHTML = '(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2509563,hjsv:6}; a=o.getElementsByTagName(\'head\')[0]; r=o.createElement(\'script\');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,\'https://static.hotjar.com/c/hotjar-\',\'.js?sv=\');'

if (process.env.NODE_ENV !== 'development') {
    // track and log errors with Sentry
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_KEY,
        environment: process.env.NODE_ENV,
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })

    // add hotjar integration
    document.head.appendChild(hotjar)
}

// set default language
i18next.changeLanguage('nl')

// catch network errors
axios.defaults.timeout = 15000
axios.interceptors.response.use(
    response => {
        return response
    },
    error => {

        console.error('interceptor error', error)
        let message = ''

        if (error.code === 'ECONNABORTED') {
            toast.error(error.message)
            return Promise.reject(error)
        }

        if (error.response !== undefined) {
            switch (error.response.status) {
                case 502:
                    message = 'System is starting up, please try again in a minute'
                    toast.error(message)
                    break
                case 401:
                    console.log('error.response:')
                    console.error(error.response)

                    // when logging in, let the Login component deal with the specific errors
                    if (error.response?.config.url.endsWith('/login')) {
                        throw error
                    }

                    // when trying to access course, send to the course login page
                    if (error.response?.data?.url.startsWith('/course/')) {
                        const url = error.response.data.url
                        const courseSlug = url.substr('/course/'.length)
                        history.push(`/course/${courseSlug}/login`)
                        break
                    }

                    message = 'Login verlopen, log opnieuw in alstublieft'
                    // prevent too many messages
                    toast.clearWaitingQueue()
                    toast.error(message, {toastId: 401})

                    // remove locally saved items by logging out
                    persistentStore.emptyStore()
                    history.push('/admin/login')
                    break
                case 400:
                    toast.error(error.response.data.message)
                    break
                default:
                    break
            }
        }

        return Promise.reject(error)
    })

ReactDOM.render(
    <Suspense fallback={<Loader />}>
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <LoadingContext.Provider value={store.isLoading}>
                    <Router history={history}>
                        {store.isLoading && <Loader/>}
                        <Route exact path='/clear-cache' component={ClearCache}/>
                        <Routes/>
                        <ToastContainer/>
                    </Router>
                </LoadingContext.Provider>
            </QueryClientProvider>
        </React.StrictMode>
    </Suspense>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
