import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import CourseContext from "../../contexts/CourseContext";
import uiStore from "../../stores/uiStore";
import emptyVideo from "../../assets/img/empty_video.jpg"
import './EnrollOrder.scss'
import SettingContext from "../../contexts/SettingContext";
import {ISetting} from "../../interfaces/ISetting";
import persistentStore from "../../stores/persistentStore";

type EnrollOrderProps = {
    className?: string,
}

const EnrollOrder = (props: EnrollOrderProps) => {
    const {t} = useTranslation()
    const {className} = props
    const course = useContext(CourseContext)
    const [coursePrice, setCoursePrice] = useState('')
    const [vatPrice, setVATPrice] = useState('')
    const [totalPrice, setTotalPrice] = useState('')
    const settings: ISetting[] = useContext(SettingContext)
    const [priceIncludesVAT, setPriceIncludesVAT] = useState(true)

    useEffect(() => {
        const priceIncludesVATSetting = settings.find((setting) => setting.name === 'pricesIncludeVAT')
        if (priceIncludesVATSetting) {
            setPriceIncludesVAT(priceIncludesVATSetting.value === 'true')
        }

    }, [settings])

    useEffect(() => {
        if (course) {
            const vatPercentageSetting = settings.find((setting) => setting.name === 'vatPercentage')
            const floatPercentage = vatPercentageSetting
                ? parseFloat(vatPercentageSetting.value)
                : 0.21

            const coursePrice = priceIncludesVAT
                ? (course.price_in_cents * (1-floatPercentage)) / 100     // price ex VAT
                : course.price_in_cents / 100       // price inc VAT

            const formattedPrice = coursePrice.toLocaleString(uiStore.locale, uiStore.moneyOptions)
            setCoursePrice(formattedPrice)

            const vat = (course.price_in_cents * floatPercentage) / 100
            const vatFormatted = vat.toLocaleString(uiStore.locale, uiStore.moneyOptions)
            setVATPrice(vatFormatted)

            const total = coursePrice + vat
            const totalFormatted = total.toLocaleString(uiStore.locale, uiStore.moneyOptions)
            setTotalPrice(totalFormatted)
        }
    }, [course, settings, priceIncludesVAT])

    return (
        <div className={'enroll-order ' + className}>
            <h4>{t('Your order')}</h4>

            <div className='chosen-course'>
                <div className='course-image mr-2'>
                    {course?.image_url && (
                        <img src={`${persistentStore.apiUrl}/${course.image_url}`} alt={course.title} style={{maxWidth: '90px'}}/>
                    )}
                    {!course?.image_url && (
                        <img src={emptyVideo} alt={course.title} style={{maxWidth: '90px'}}/>
                    )}
                </div>
                <div className='course-title' style={{textTransform: 'capitalize', color: 'grey', fontWeight: 500}}>
                    {course.title}
                </div>
                <div className='course-price' style={{marginLeft: 'auto'}}>
                    <div> {coursePrice} </div>
                    <div>
                        <small>{t('(ex VAT)')}</small>
                    </div>
                </div>
            </div>

            <div className='order-costs'>
                <div className='course-image'>
                    {t('Subtotal')}
                </div>
                <div className='course-price' style={{marginLeft: 'auto'}}>
                    {coursePrice}
                </div>
            </div>

            <div className='order-vat'>
                <div className='course-image'>
                    {t('VAT')}
                </div>
                <div className='course-price' style={{marginLeft: 'auto'}}>
                    {vatPrice}
                </div>
            </div>

            <div className={'d-flex flex-row mt-2'} style={{fontWeight: 'bold'}}>
                <div className='course-image'>
                    {t('Total')}
                </div>
                <div className='course-price' style={{marginLeft: 'auto'}}>
                    {totalPrice}
                </div>
            </div>
        </div>
    )
};

export default EnrollOrder;