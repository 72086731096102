import React from 'react';
import {Link} from "react-router-dom";
import {IBreadCrumb} from "../interfaces/ICrumb";

type BreadCrumbProps = {
    crumbs: IBreadCrumb[]
}

const BreadCrumb = (props: BreadCrumbProps) => {

    return (
        <ol className="breadcrumb">
            {props.crumbs.map((crumb: IBreadCrumb, index: number) => (
                <li className={`breadcrumb-item ${crumb.active && 'active'}`} key={index}>
                    {crumb.link && <Link to={crumb.link}> {crumb.name} </Link>}
                    {!crumb.link && crumb.name}
                </li>
            ))}
        </ol>
    )
};

export default BreadCrumb;