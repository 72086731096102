import axios from 'axios'
import errorChecker from './errorchecker'
import history from "../utils/history";
import qs from 'querystring'
import persistentStore from "../stores/persistentStore";
import {IUser} from "../interfaces/IUser";
import {ILoginPostData} from "../interfaces/ILoginPostData";

const userRequests = () => {
    // register an event for this user
    const event = async (eventName: string) => {
        const path = `/users/event/${eventName}`

        return axios.post(persistentStore.apiUrl + path, [], persistentStore.authHeader)
            .then((response: any) => {
                const serverData = response.data

                if (response.data.success === true) {
                }

                return serverData
            })
    }

    const logout = () => {
        persistentStore.emptyStore()
        history.push('/login')
    }

    const login = async (postData: ILoginPostData) => {
        const {username, password, remember_me, courseSlug, target, token} = postData
        const path = `/login`
        const data = {
            email: username,
            password: password,
            remember_me: remember_me,
            target: target,
            courseSlug: '',
            token: ''
        }
        const header = {
            headers: {
                'Accept': 'application/json',
            }
        }

        if (courseSlug) {
            data.courseSlug = courseSlug
        }

        if (token) {
            data.token = token
        }

        return axios.post(persistentStore.apiUrl + path, qs.stringify(data), header)
            .then((result: any) => {
                if (result?.status === 401 || result?.response?.status === 401) {
                    // toast.error('Incorrect username or password')
                    throw new Error(result)
                }

                const data = result.data.data
                const user = data.user as IUser
                const token = data.token

                persistentStore.setUser(user)
                persistentStore.setToken(token)

                return result.data
            })
    }

    const register = async (user: IUser) => {
        const path = `/register`
        const data = user

        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then((response: any) => errorChecker(response))
            .then((response: any) => response.data)
    };

    const validatePasswordResetTokens = async (props: any) => {
        const path = `/password-reset/validate`
        const data = props

        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then((response: any) => response.data)
    };

    // const requestResetPassword = async (email: string) => {
    //     const path = `/password-reset`
    //     const data = {email: email}
    //
    //     return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
    //         .then((response: any) => response.data)
    //         .catch((error: any) => error)
    // };

    const validatePasswordRequest = async (selector: string, validator: string) => {
        const path = `/password-reset/validate`
        const data = {selector: selector, validator: validator}

        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then((response: any) => response.data)
            .catch((error: any) => error)
    };

    const resetPasswordRequest = async (email: string) => {
        const path = `/reset-password-request`
        const data = {email: email}

        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then((response: any) => response.data)
            .catch((error: any) => error)
    };

    type resetPasswordProps = { password: string, validator: string, selector: string }
    const resetPassword = async (props: resetPasswordProps) => {
        const path = `/reset-password`
        const data = {password: props.password, validator: props.validator, selector: props.selector}

        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then((response: any) => response.data)
            .catch((error: any) => error)
    };

    const update = async (updatedUser: IUser) => {
        const path = `/users/${updatedUser.id}`

        return axios.put(persistentStore.apiUrl + path, updatedUser, persistentStore.authHeader)
            .then((response: any) => {
                if (response.data.success === true) {
                    return response.data
                }

                console.error(response)
            })
            .catch((error: any) => error)
    };

    const changePassword = async (props: any) => {
        const path = `/changepassword`
        const data = props

        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then((response: any) => response.data)
            .catch((error: any) => error)
    };

    const get = async () => {
        const path = `/users`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then((response: any) => errorChecker(response))
            .then((response: any) => {
                return response.data.data.users
            })
    };

    const getOne = async (userId: string): Promise<IUser> => {
        const path = `/users/${userId}`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then((response: any) => errorChecker(response))
            .then((response: any) => response.data.data.user)
    };

    const del = async (userId: number) => {
        const path = `/users/${userId}`

        return axios.delete(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then((response: any) => errorChecker(response))
            .then((response: any) => response.data)
    };

    const impersonate = async (idOfUserToBecome: string) => {
        const path = `/impersonate/${idOfUserToBecome}`

        return axios.post(persistentStore.apiUrl + path, [], persistentStore.authHeader)
            .then((response) => {
                const serverData = response.data

                if (response.data.success === true) {
                    // store the current information
                    persistentStore.impersonate(
                        serverData.data.user,
                        serverData.data.token,
                        window.location.href
                    )
                }

                return serverData
            })
    }

    return {
        changePassword,
        del,
        event,
        get,
        getOne,
        impersonate,
        login,
        logout,
        register,
        resetPassword,
        resetPasswordRequest,
        update,
        validatePasswordRequest,
        validatePasswordResetTokens,
    }
}

export const users = userRequests()
