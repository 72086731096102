import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ICourse} from "../../../interfaces/ICourse";

type CourseHeaderProps = {
    loading: boolean,
    saveCourse: ()=>void,
    course?: ICourse,
    title: string,
}

const CourseHeader = (props: CourseHeaderProps) => {
    const {t} = useTranslation()
    const {course} = props

    return (
        <div className="media align-items-center mb-headings">
            <div className="media-body">
                <h1 className="h2">{props.title}</h1>
            </div>

            <div className="media-right">
                {course && <Link to={`/course/${course.slug}`} className="mr-2" target='_blank'>{t('Preview')}</Link>}
                <button className="btn btn-success" onClick={props.saveCourse}>
                    {props.loading && t('SAVING') + '...'}
                    {! props.loading && t('SAVE')}
                </button>
            </div>
        </div>
    )
};

export default CourseHeader;