import axios from "axios";
import persistentStore from "../stores/persistentStore";
import errorChecker from "./errorchecker";

const mailRequests = () => {

    const fromUser = async (userId: string) => {
        const path = `/mails/from-user/${userId}`

        return axios.get(persistentStore.apiUrl + path,  persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.mails) {
                    throw Error('The response did not contain mails');
                }

                return data.mails
            })
    }

    return {
        fromUser,
    }
}

export const mails = mailRequests()
