import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";
import UserContext from "../../contexts/UserContext";
import {IUser} from "../../interfaces/IUser";
import {md5} from "../../utils/md5";
import persistentStore from "../../stores/persistentStore";

type NavBarUserProps = {}

const NavBarUser = (props: NavBarUserProps) => {
    const {t} = useTranslation()
    const [show, setShow] = useState(false)
    const user: IUser = useContext(UserContext)
    const [gravatarUrl, setGravatarUrl] = useState<string>('https://www.gravatar.com/avatar/unknown')

    useEffect(() => {
        if(user && user.email){
            const md5Email = md5(user.email)
            setGravatarUrl(`https://www.gravatar.com/avatar/${md5Email}`)
        }
    }, [user])

    return (
        // <Dropdown>
        //     <Dropdown.Toggle type='reset' variant="light" id="dropdown-basic">
        //         <img
        //             src={process.env.PUBLIC_URL + "/img/people/guy-6.jpg"}
        //             alt="Avatar"
        //             className="rounded-circle"
        //             width="40"
        //         />
        //     </Dropdown.Toggle>
        //
        //     <Dropdown.Menu>
        //         <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        //         <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        //         <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
        //     </Dropdown.Menu>
        // </Dropdown>

        <li className="nav-item dropdown ml-1 ml-md-3">
            <span className="nav-link dropdown-toggle" data-toggle="dropdown" onClick={() => setShow(!show)}>
                <img
                    // src={process.env.PUBLIC_URL + "/img/people/paul.png"}
                    src={gravatarUrl}
                    alt="Avatar"
                    className="rounded-circle"
                    width="40"
                />
            </span>
            <div className={`dropdown-menu dropdown-menu-right ${show && 'show'}`}>
                {/*<Link to='/members/account-edit' className="dropdown-item">*/}
                {/*    <FontAwesomeIcon icon={faPen} className="material-icons"/>*/}
                {/*    {t('Edit Account')}*/}
                {/*</Link>*/}
                {/*<Link className="dropdown-item" to="/member/profile">*/}
                {/*    <FontAwesomeIcon icon={faUser} className="material-icons"/>*/}
                {/*    {t('Public Profile')}*/}
                {/*</Link>*/}
                <div className="dropdown-item">
                    {persistentStore.user?.email}
                </div>
                <hr />
                <Link className="dropdown-item" to="/admin/login">
                    <FontAwesomeIcon icon={faLock} className="material-icons"/>
                    {t('Logout')}
                </Link>
            </div>
        </li>
    )
};

export default NavBarUser;