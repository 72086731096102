import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import Alert from "../../../components/Alert";
import QuizTile from "./QuizTile";
import {IQuiz} from "../../../interfaces/IQuiz";
import {observer} from "mobx-react";
import persistentStore from "../../../stores/persistentStore";
import {useQuery} from "react-query";
import {quizzes as quizzesApi} from '../../../api/quizzes'

type QuizzesPageProps = {}

const QuizManager = (props: QuizzesPageProps) => {
    const {t} = useTranslation()
    const [quizzes, setQuizzes] = useState<IQuiz[]>([])
    const quizzesQuery = useQuery(
        'quizzes',
        () => {
            if (persistentStore.user?.id) {
                return quizzesApi.getAll()
            }
        },
        {
            enabled: persistentStore.user !== undefined,
        }
    )

    // const [paginatedQuizzes, setPaginatedQuizzes] = useState<IQuiz[]>([])

    const path: IBreadCrumb[] = [
        {name: 'Home', link: '/admin/dashboard', active: false},
        {name: 'Quizzes', link: '/admin/quiz-manager', active: true}
    ]

    useEffect(() => {
        if(quizzesQuery.data){
            setQuizzes(quizzesQuery.data)
        }
    }, [quizzesQuery.data])

    // // when the quizzes change, update the paginated quizzes
    // useEffect(() => {
    //     if(quizzes){
    //         // setPaginatedQuizzes(quizzes)
    //     }
    // },[quizzes])

    return (
        <div className="container-fluid page__container">
            <BreadCrumb crumbs={path}/>

            <div
                className="d-flex flex-column flex-sm-row flex-wrap mb-headings align-items-start align-items-sm-center">
                <div className="flex mb-2 mb-sm-0">
                    <h1 className="h2">{t('Manage Quizzes')}</h1>
                </div>
                <Link to="/admin/quizzes/add" className="btn btn-success">{t('Add Quiz')}</Link>
            </div>

            {quizzes?.length === 0 && (
                <Alert message={t('Ohh no') + '! ' + t('No quizzes to display') + '. ' + t('Add some quizzes') + '.'}/>
            )}

            <div className="row">
                {quizzes.map((quiz: IQuiz, index: number) => (
                    <div className="col-md-6" key={index}>
                        <QuizTile quiz={quiz}/>
                    </div>
                ))}
            </div>

            {/*<Pagination*/}
            {/*    records={quizzes}*/}
            {/*    setPaginatedRecords={quizzes}*/}
            {/*    pageSize={persistentStore.paginationLength}*/}
            {/*/>*/}
        </div>
    )
};

export default observer(QuizManager)
