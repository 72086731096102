import React from 'react';
import ContentHeader from "./ContentHeader";
import TakeQuiz from "./TakeQuiz";
import '../Content.scss'
import {observer} from "mobx-react";

type QuizContentProps = {
}

const QuizContent = (props: QuizContentProps) => {
    return (
        <div className='content'>
            <ContentHeader />
            <TakeQuiz />
        </div>
    )
};

export default observer(QuizContent)