import persistentStore from "../stores/persistentStore";
import axios from "axios";
import errorChecker from "./errorchecker";
import {IUserQuiz} from "../interfaces/IUserQuiz";

const userquizzesRequests = () => {

    const add = async (userquiz: IUserQuiz) => {
        const path = `/users-quiz-questions/add`;
        const config = persistentStore.authHeader;

        return axios.post(persistentStore.apiUrl + path, userquiz, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.user_quiz_questions) {
                console.log('The response did not contain quiz');
                throw Error('The response did not contain quiz');
            }

            return data.user_quiz_questions;
        });
    }

    const getByUser = async(userid: string) => {
        const path  = `/users-quiz-questions/get-by-user/${userid}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.user_quiz_questions) {
                console.log('The response did not contain quiz');
                throw Error('The response did not contain quiz');
            }

            return data.user_quiz_questions;
        });
    }

    return {
        add,
        getByUser
    }
}

export const usersQuizzes = userquizzesRequests()
