import React from "react";
import {ILesson} from "../interfaces/ILesson";
import {IModule} from "../interfaces/IModule";
import {isLesson, isQuiz} from "./isLesson";
import LessonListItem from "../components/LessonListItem";
import ModuleListItem from "../components/ModuleListItem";
import {IQuiz} from "../interfaces/IQuiz";
import QuizListItem from "../components/QuizListItem";

type RenderLessonProps = {
    item: ILesson | IModule | IQuiz,
}

const RenderLesson = (props: RenderLessonProps) => {
    const {item: lessonModuleOrQuiz} = props

    // Quiz:
    if (isQuiz(lessonModuleOrQuiz)) {
        const quiz: IQuiz = lessonModuleOrQuiz as unknown as IQuiz
        return <QuizListItem quiz={quiz}/>
    }

    // MODULE:
    if (!isLesson(lessonModuleOrQuiz)) {
        const module: IModule = lessonModuleOrQuiz as IModule
        return <ModuleListItem module={module}/>
    }

    // LESSON:
    const lesson: ILesson = lessonModuleOrQuiz as ILesson
    return <LessonListItem lesson={lesson}/>
}

export default RenderLesson