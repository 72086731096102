import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Container, Button} from "react-bootstrap";
import BreadCrumb from "../../../components/BreadCrumb";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import mollieLogo from "../../../assets/img/mollie-logo.svg"
import SettingContext from "../../../contexts/SettingContext";
import {ISetting} from "../../../interfaces/ISetting";
import {settings as settingsApi} from "../../../api/settings";
import {toast} from "react-toastify";
import {useQueryClient} from "react-query";
import ToggleSlider from "../../../components/ToggleSlider";

type PaymentSettingsProps = {}

const PaymentSettings = (props: PaymentSettingsProps) => {
    const {t} = useTranslation()
    const [mollieTestKey, setMollieTestKey] = useState('')
    const [mollieLiveKey, setMollieLiveKey] = useState('')
    const [vatPercentage, setVatPercentage] = useState("21")
    const queryClient = useQueryClient()
    const settings = useContext(SettingContext)
    const [liveSales, setLiveSales] = useState(false)
    const [pricesIncludeVAT, setPricesIncludeVAT] = useState(false)

    const path: IBreadCrumb[] = [
        {name: 'Home', link: '#', active: false},
        {name: 'Settings', link: '/admin/account/edit', active: false},
        {name: 'Payment settings', link: '/admin/account/payment-settings', active: true},
    ]

    useEffect(() => {
        if(settings){
            const vatSetting: ISetting|undefined = settings.find((setting: ISetting) => setting.name === 'vatPercentage')
            if(vatSetting){
                const stringVat = String(parseFloat(vatSetting.value) * 100)
                setVatPercentage(stringVat)
            }

            const mollieLiveKeySetting: ISetting|undefined = settings.find((setting: ISetting) => setting.name === 'mollieLiveKey')
            if(mollieLiveKeySetting){
                setMollieLiveKey(mollieLiveKeySetting.value)
            }

            const mollieTestKeySetting: ISetting|undefined = settings.find((setting: ISetting) => setting.name === 'mollieTestKey')
            if(mollieTestKeySetting){
                setMollieTestKey(mollieTestKeySetting.value)
            }

            const saleStatusSetting: ISetting|undefined = settings.find((setting: ISetting) => setting.name === 'saleStatus')
            if(saleStatusSetting){
                setLiveSales(saleStatusSetting.value !== 'test')
            }

            const pricesIncludeVATSetting: ISetting|undefined = settings.find((setting: ISetting) => setting.name === 'pricesIncludeVAT')
            if(pricesIncludeVATSetting){
                setPricesIncludeVAT(pricesIncludeVATSetting.value === 'true')
            }
        }
    }, [settings])

    const saveSettings = () => {
        let updatedVatSetting, updatedMollieLiveKey, updatedMollieTestKey: ISetting
        let updatedSettings: ISetting[] = []

        const vatSetting: ISetting|undefined = settings.find((setting: ISetting) => setting.name === 'vatPercentage')
        if(vatSetting){
            const floatPercentage = parseFloat(vatPercentage) / 100
            updatedVatSetting = Object.assign({}, vatSetting, {value: floatPercentage})
            updatedSettings.push(updatedVatSetting)
        }

        const mollieLiveKeySetting: ISetting|undefined = settings.find((setting: ISetting) => setting.name === 'mollieLiveKey')
        if(mollieLiveKeySetting){
            updatedMollieLiveKey = Object.assign({}, mollieLiveKeySetting, {value: mollieLiveKey})
            updatedSettings.push(updatedMollieLiveKey)
        }

        const mollieTestKeySetting: ISetting|undefined = settings.find((setting: ISetting) => setting.name === 'mollieTestKey')
        if(mollieTestKeySetting){
            updatedMollieTestKey = Object.assign({}, mollieTestKeySetting, {value: mollieTestKey})
            updatedSettings.push(updatedMollieTestKey)
        }

        const saleStatusSetting: ISetting|undefined = settings.find((setting: ISetting) => setting.name === 'saleStatus')
        if(saleStatusSetting){
            const updatedSaleStatus = Object.assign({}, saleStatusSetting, {value: liveSales ? 'live' : 'test'})
            updatedSettings.push(updatedSaleStatus)
        }

        // let updatedPricesIncludeVAT: ISetting
        const pricesIncludeVATSetting: ISetting|undefined = settings.find((setting: ISetting) => setting.name === 'pricesIncludeVAT')
        if(pricesIncludeVATSetting){
            const updatedPricesIncludeVAT = Object.assign({}, pricesIncludeVATSetting, {value: pricesIncludeVAT ? 'true' : 'false'})
            updatedSettings.push(updatedPricesIncludeVAT)
        }

        settingsApi.updateMany(updatedSettings)
            .then((result) => {
                toast.success(t('Settings updated'))
                queryClient.invalidateQueries('settings')
            })
            .catch((error) => {
                toast.error(error.response.data.errors.detail)
            })
    }

    return (
        <Container className="page__container p-0">

            <div className="col-lg container-fluid page__container">
                <BreadCrumb crumbs={path}/>

                <h1 className="h2">{t('Payment settings')}</h1>
            </div>

            <div className="card">
                <form onSubmit={(e) => {e.preventDefault(); saveSettings()}}>
                    <div className="list-group list-group-fit">
                        <div className="list-group-item py-3">
                      <span className="d-flex align-items-center">
                        <img src={mollieLogo} alt="cc" width="80" className="mr-3"/>
                        <span className="flex" style={{whiteSpace: "nowrap"}}>
                            {t('Provide your Mollie API keys to connect your payments with Mollie')}
                        </span>
                      </span>
                        </div>
                    </div>

                    <div className="list-group-item">
                        <div role="group" aria-labelledby="label-cc" className="m-0 form-group">
                            <div className="form-row">
                                <label id="label-cc" htmlFor="cc" className="col-md-3 col-form-label form-label">
                                    {t('Test key')}
                                </label>
                                <div className="col-md-9">
                                    <input
                                        id="inputMollieTestKey"
                                        type="text"
                                        placeholder={t('test_1234abcd')}
                                        className="form-control"
                                        value={mollieTestKey}
                                        onChange={(e) => setMollieTestKey(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="list-group-item">
                        <div role="group" aria-labelledby="label-cc" className="m-0 form-group">
                            <div className="form-row">
                                <label id="label-cc" htmlFor="cc" className="col-md-3 col-form-label form-label">
                                    {t('Live key')}
                                </label>
                                <div className="col-md-9">
                                    <input
                                        id="inputMollieLiveKey"
                                        type="text"
                                        placeholder={t('live_1234abcd')}
                                        className="form-control"
                                        value={mollieLiveKey}
                                        onChange={(e) => setMollieLiveKey(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="list-group-item">
                        <div role="group" aria-labelledby="label-expire_month" className="m-0 form-group">
                            <div className="form-row">
                                <label id="label-expire_month" htmlFor="expire_month"
                                       className="col-md-3 col-form-label form-label">
                                    {t('VAT percentage')}
                                </label>
                                <div className="col-md-9">
                                    <div className="form-row">
                                        <div className="col-auto">
                                            <select
                                                id="vatPercentage"
                                                className="form-control custom-select"
                                                style={{width: "140px"}}
                                                value={vatPercentage}
                                                onChange={(e) => setVatPercentage(e.target.value)}
                                            >
                                                <option value="0">0%</option>
                                                <option value="6">6%</option>
                                                <option value="12">12%</option>
                                                <option value="21">21%</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="list-group-item">
                        <div role="group" aria-labelledby="label-expire_month" className="m-0 form-group">
                            <div className="form-row">
                                <label
                                    id="label-expire_month"
                                    htmlFor="expire_month"
                                    className="col-md-3 col-form-label form-label">
                                    {t('Payments live')}
                                </label>
                                <div className="col-md-9">
                                    <div className="form-row">
                                        <div className="d-flex">
                                            <ToggleSlider type={'round'} checked={liveSales} onChange={setLiveSales} />
                                            <div className='pt-2'>
                                                {! liveSales && t('No real payments are done because we\'re in testmode')}
                                                {liveSales && t('Real payments are made, we\'re in live mode')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="list-group-item">
                        <div role="group" aria-labelledby="label-expire_month" className="m-0 form-group">
                            <div className="form-row">
                                <label
                                    id="label-expire_month"
                                    htmlFor="expire_month"
                                    className="col-md-3 col-form-label form-label">
                                    {t('Show prices including VAT')}
                                </label>
                                <div className="col-md-9">
                                    <div className="form-row">
                                        <div className="d-flex">
                                            <ToggleSlider type={'round'} checked={pricesIncludeVAT} onChange={setPricesIncludeVAT} />
                                            <div className='pt-2'>
                                                {pricesIncludeVAT && t('Prices shown is what the customer will pay')}
                                                {! pricesIncludeVAT && t('The customer will pay more than the prices show as the TAX will be added before checkout')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="list-group-item">
                        <Button
                            type="submit"
                            className="btn btn-success"
                        >
                            {t('Save Changes')}
                        </Button>
                    </div>
                </form>
            </div>
        </Container>
    )
};

export default PaymentSettings