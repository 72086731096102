import React from 'react';
import {IEvent} from "../../../interfaces/IEvent";
import DataTable from "react-data-table-component";
import persistentStore from "../../../stores/persistentStore";
import FilterComponent from "../../../components/FilterComponent";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";

type EventTableProps = {
    events: IEvent[],
    isLoading: boolean,
}

const EventTable = (props: EventTableProps) => {
    const [filterText, setFilterText] = React.useState('');
    const {t} = useTranslation()
    const {events} = props

    const filteredEvents = events.filter((event: IEvent) => {
        if(filterText === ''){
            return true
        }
        const data = JSON.stringify(event.data).toLowerCase()
        const query = filterText.toLowerCase()

        return (data && data.includes(query)) ||
            (event.type && event.type.toLowerCase().includes(query)) ||
            (event.email && event.email.toLowerCase().includes(query))
    });

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return <FilterComponent
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
        />;
    }, [filterText]);

    const datafieldsToShowPerType: {[key: string]: string[]} = {
        'LOGGED_IN_SUCCESS': ['name', 'email'],
        'LOGGED_IN_FAILED': ['name', 'email'],
        'USER_PASSWORD_RESET': ['name', 'email'],
        'USER_PASSWORD_RESET_MAIL_SENT': ['email'],
        'MAIL_SENT_SUCCESS': ['email', 'subject', 'substitutions']
    }

    const columns = [
        {
            name: 'data',
            selector: (event: IEvent) => event.type,
            sortable: true,
            width: 'auto'
        },
        {
            name: 'name',
            selector: (event: IEvent) => event.name,
            sortable: true,
            // width: '200px'
            cell: (event: IEvent) => {
                if(event.data.hasOwnProperty('name')){
                    return event.data.name
                }
            }
        },
        {
            name: 'email',
            selector: (event: IEvent) => event.email,
            sortable: true,
            // width: '200px'
            cell: (event: IEvent) => {
                if(event.data.hasOwnProperty('email')){
                    return event.data.email
                }
            }
        },
        {
            name: 'data',
            selector: (event: IEvent) => event.data,
            sortable: false,
            width: 'auto',
            cell: (event: IEvent) => {
                if(datafieldsToShowPerType.hasOwnProperty(event.type)){
                    const fields: string[] = datafieldsToShowPerType[event.type].map((fieldName: string) => event.data[fieldName])
                    return fields.join(' - ')
                }

                return JSON.stringify(event.data, undefined, 4).slice(0, 100)
            }
        },
        {
            name: 'created',
            selector: (event: IEvent) => event.created,
            sortable: true,
            // width: '200px',
            cell: (event: IEvent) => moment(event.created).format('Y-MM-DD H:mm:ss')
        },
    ]

    return (
        <DataTable
            className='CustomerListView customer-list-view'
            columns={columns}
            data={filteredEvents}
            pagination
            paginationPerPage={persistentStore.paginationLength}
            paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
            onChangeRowsPerPage={(rowCount: number) => persistentStore.setPaginationLength(rowCount)}
            noHeader={true}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            progressPending={props.isLoading}
            noDataComponent={<div>{t('No events')}</div>}
            defaultSortFieldId={'created'}
            defaultSortAsc={false}
        />
    )
};

export default EventTable;