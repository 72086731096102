import React, {useContext} from 'react';
import {observer} from "mobx-react";
import {IUserQuiz} from '../../../../interfaces/IUserQuiz';
import UserQuizContext from '../../../../contexts/UserQuizContext';
import {useTranslation} from "react-i18next";
import {Card} from 'react-bootstrap';
import {IQuestion} from '../../../../interfaces/IQuestion';
import {IUserQuestion} from '../../../../interfaces/IUserQuestion';

type QuizResultProps = {
    quizQuestions: IQuestion[],
    userQuestions: IUserQuestion[]
}

const QuizResult = (props: QuizResultProps) => {
    const {quizQuestions, userQuestions} = props
    const {t} = useTranslation()
    const quiz: IUserQuiz | undefined = useContext(UserQuizContext)

    console.log(quizQuestions)
    console.log(userQuestions)

    const results = userQuestions.map(userQuestion => {
        const answerToThisQuestion = userQuestion.answers.join(', ')
        return {
            question: userQuestion.value,
            answers: answerToThisQuestion,
            correct: userQuestion.is_correct || false
        }
    })

    return (
        <div className="container-fluid page__container">
            <div className="media mb-headings align-items-center">
                <div className="media-body">
                    <h1 className="h2">{quiz?.name}</h1>
                </div>
            </div>
            <Card>
                <Card.Header>
                    <h4 className="card-title">{t('Result')}</h4>
                </Card.Header>
            </Card>
            <Card>
                <Card.Header>
                    <h4 className="card-title">{t('Questions')}</h4>
                </Card.Header>
                <Card.Body>
                    <ul className="list-group list-group-fit mb-0">
                        {results.map((result: {question: string, answers: string, correct: boolean}, index: number) =>
                            <li className="list-group-item" key={`index_${index}`}>
                                <div className="media">
                                    <div className="media-left">
                                        <div className="text-muted-light">{`${index + 1}.`}</div>
                                    </div>
                                    <div className="media-body">{result.question}</div>
                                    <div className="media-right"></div>
                                </div>

                                <div className="media">
                                    <div className="media-left">
                                        <div className="text-muted-light"></div>
                                    </div>
                                    <div className="media-body">
                                        {t('Your answer')}:&nbsp;
                                        {result.answers}
                                    </div>
                                    <div className="media-right">
                                        <span
                                            className={`badge ${result.correct ? 'badge-success' : 'badge-danger'}`}
                                        >
                                            {result.correct && t('Correct')}
                                            {!result.correct && t('Wrong')}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </Card.Body>
            </Card>
        </div>
    )
};

export default observer(QuizResult)