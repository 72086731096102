import React from 'react';
import {useTranslation} from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import {users as usersApi} from "../../../api/users";
import {useQuery} from "react-query";
import UsersTable from "./UsersTable";

type UsersPageProps = {}

const UsersPage = (props: UsersPageProps) => {
    const {t} = useTranslation()

    const usersQuery = useQuery('getUsers', usersApi.get)

    return (
        <div id='users-list' className='ml-3 mr-3'>
            <Row className='col-12'>
                <h2>{t('Users')}</h2>
            </Row>
            <Row className='col-12'>
                {usersQuery.isLoading && <Spinner animation='border' className="spinner"/>}
            </Row>
            <Row>
                {usersQuery.isFetched && <UsersTable users={usersQuery}/>}
            </Row>
        </div>
    )
};

export default UsersPage;