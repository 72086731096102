import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {users as usersApi} from "../../../api/users";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import {IUser} from "../../../interfaces/IUser";
import Loader from "../../../components/Loader";
import {Link} from "react-router-dom";
import MailTable from "./MailTable";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import BreadCrumb from "../../../components/BreadCrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";

const StudentDetailsPage = () => {
    const {t} = useTranslation()
    const [user, setUser] = useState<IUser>()
    const {userId} = useParams<{ userId: string }>()

    const userQuery = useQuery('getUser', () => usersApi.getOne(userId))

    useEffect(() => {
        if(userQuery.data){
            setUser(userQuery.data)
        }
    }, [userQuery.data])

    if(!user){
        return <Loader />
    }

    const crumbs: IBreadCrumb[] = [
        {
            name: t('Home'),
            active: false,
            link: '/admin/dashboard'
        },
        {
            name: t('Students'),
            active: false,
            link: ''
        },
    ]

    return (
        <div className="page__container p-0">
            <Row className="m-0">
                <Col className="col-lg container-fluid page__container">
                    <BreadCrumb crumbs={crumbs}/>

                    <h1 className="h2">{user.name || user.email}</h1>
                    <Card>
                        <div className="list-group list-group-fit">
                            <div className="list-group-item">
                                <div className="form-group m-0" role="group" aria-labelledby="label-firstname">
                                    <div className="form-row">
                                        <label
                                            id="label-firstname"
                                            htmlFor="firstname"
                                            className="col-md-3 col-form-label form-label"
                                        >
                                            {t('Name')}
                                        </label>
                                        <div className="col-md-9">
                                            {user.name}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="list-group-item">
                                <div className="form-group m-0" role="group" aria-labelledby="label-lastname">
                                    <div className="form-row">
                                        <label
                                            id="label-lastname"
                                            htmlFor="lastname"
                                            className="col-md-3 col-form-label form-label"
                                        >
                                            {t('Email')}
                                        </label>
                                        <div className="col-md-9">
                                            {user.email}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="list-group-item">
                                <div className="form-group m-0" role="group" aria-labelledby="label-lastname">
                                    <div className="form-row">
                                        <label
                                            id="label-courses"
                                            htmlFor="courses"
                                            className="col-md-3 col-form-label form-label"
                                        >
                                            {t('Courses')}
                                        </label>
                                        <div className="col-md-9">
                                            <ul>
                                                {user.Courses?.map((course, index) => (
                                                    <li key={index}>
                                                        <Link to={`/course/${course.slug}`}>{course.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>

                    <h4>{t('Mails')}</h4>
                    <Card>
                        <MailTable userId={user.id} />
                    </Card>
                </Col>

                <div
                    id="page-nav"
                    className="col-lg-auto page-nav"
                >
                    <div>
                        <div className="page-section pt-lg-32pt">
                            <div className="page-nav__content">
                                <button className="btn btn-success">{t('Save Changes')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    );
}

export default StudentDetailsPage;
