import React, {useEffect, useState} from 'react';
import BreadCrumb from "../../../components/BreadCrumb";
import RedirectMessage from "../../../components/RedirectMessage";
import SalesGraph from "./SalesGraph";
import SalesTable from "./SalesTable";
import Transactions from "./Transactions";
import persistentStore from "../../../stores/persistentStore";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import {Row} from "react-bootstrap";
import {sales} from "../../../api/sales";
import {payments} from "../../../api/payments";
import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {useTranslation} from "react-i18next";
import Col from "react-bootstrap/Col";
import {ITransaction} from "../../../interfaces/ITransaction";
import {IStatement} from "../../../interfaces/IStatement";

type DashboardProps = {}

const Dashboard = (props: DashboardProps) => {
    const {t} = useTranslation()
    const salesTodayQuery = useQuery('salesToday', () => sales.getToday())
    // TODO remove any type
    const [transactionsList, setTransactionsList] = useState<any[]>([])
    const search = useLocation().search
    const from = (new URLSearchParams(search)).get('from')
    const redirectedFromHome: boolean = (from === 'trainingsomgeving.nl')
    const transactionsQuery = useQuery(
        'getTransactions',
        () => payments.getByTrainer()
    )

    useEffect(() => {
        if (transactionsQuery.data) {
            const statementsList: IStatement[] = transactionsQuery.data.map((transaction: ITransaction) => {
                return {
                    trainer_id: transaction.course?.trainer_id,
                    purchased_at: transaction.created,
                    course_title: transaction.course?.title,
                    course_slug: transaction.course?.slug,
                    image_url: persistentStore.apiUrl + transaction.course?.image_url,
                    document: "#" + transaction.invoice?.public_id,
                    amount: transaction?.amount_paid_in_cents
                }
            })

            setTransactionsList(Object.assign(transactionsList, statementsList))
        }
    }, [transactionsQuery, transactionsList])

    const crumbs: IBreadCrumb[] = [
        {
            name: t('Dashboard'),
            active: false,
            link: '/admin/dashboard'
        }
    ]

    return (
        <div className="container-fluid page__container">
            <BreadCrumb crumbs={crumbs}/>

            <h1 className="h2">{t('Dashboard')}</h1>

            {redirectedFromHome && <RedirectMessage/>}

            <Row>
                <Col lg={6}>
                    <SalesGraph/>
                    {transactionsList.length > 0 && (
                        <Transactions statements={transactionsList}/>
                    )}
                </Col>

                <div className="col-lg-6">
                    <SalesTable sales={salesTodayQuery.data || []}/>
                </div>
            </Row>
        </div>
    )
};

export default Dashboard;
