import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {IMenuItem} from "../../interfaces/IMenuItem";

type MenuSettingsProps = {
    className?: string,
}

const MenuSettings = (props: MenuSettingsProps) => {
    const {className} = props
    const [slug, setSlug] = useState<string>(window.location.pathname.substring('/admin/'.length))
    const {t} = useTranslation()
    const [menuOpened, setMenuOpened] = useState<string>()
    const menuId = 'settings'

    const openMenu = (menuItem: string) => {
        const newMenuOpened = (menuOpened === menuItem) ? '' : menuItem
        setMenuOpened(newMenuOpened)
    }

    useEffect(() => {
        if (slug.startsWith('account/edit')) {
            openMenu(menuId)
        }
    // 'openMenu' doesn't need to be in the dependencies, so silence eslint complaining about it
    // eslint-disable-next-line
    }, [])

    const menuItemsSettings: IMenuItem[] = [
        // {
        //     slug: 'account/subscription',
        //     link: '/admin/account/subscription',
        //     text: t('Subscription'),
        // },
        // {
        //     slug: 'account/upgrade',
        //     link: '/admin/account/upgrade',
        //     text: t('Upgrade'),
        // },
        // {
        //     slug: 'account/payment-history',
        //     link: '/admin/account/payment-history',
        //     text: t('Payment History'),
        // },
        {
            slug: 'account/payment-settings',
            link: '/admin/account/payment-settings',
            text: t('Payment settings'),
        },
        {
            slug: 'account/edit',
            link: '/admin/account/edit',
            text: t('Edit account'),
        }
    ]

    return (
        <div className={className}>
            <div className="sidebar-heading">{t('Settings')}</div>
            <ul className="sidebar-menu">
                <li className={`sidebar-menu-item ${(menuOpened === menuId) && 'open'}`}>
                    <div className="sidebar-menu-button sidebar-js-collapse" onClick={() => openMenu(menuId)}>
                        <FontAwesomeIcon
                            icon={faUser}
                            className="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                        />
                        {t('Settings')}
                        <FontAwesomeIcon icon={faChevronRight} className='ml-auto sidebar-menu-toggle-icon'/>
                    </div>

                    <ul className="sidebar-submenu sm-indent collapse" id="account_menu">
                        {menuItemsSettings.map((item: IMenuItem, index: number) => (
                            <li
                                key={index}
                                onClick={() => setSlug(item.slug)}
                                className={`sidebar-menu-item ${(slug === item.slug) && 'active'}`}
                            >
                                <Link className="sidebar-menu-button" to={item.link}>
                                    <span className="sidebar-menu-text">{item.text}</span>
                                </Link>
                            </li>
                        ))}

                        {/*<hr/>*/}

                        {/*<li className={`sidebar-menu-item ${(slug === 'account/edit') && 'active'}`}>*/}
                        {/*    <Link className="sidebar-menu-button" to="/admin/account/edit">*/}
                        {/*        <span className="sidebar-menu-text">{t('Edit Settings')}</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        {/*<li className={`sidebar-menu-item ${(slug === 'account/subscription') && 'active'}`}>*/}
                        {/*    <Link className="sidebar-menu-button" to="/admin/account/subscription">*/}
                        {/*        <span className="sidebar-menu-text">{t('Subscription')}</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        {/*<li className={`sidebar-menu-item ${(slug === 'account/upgrade') && 'active'}`}>*/}
                        {/*    <Link className="sidebar-menu-button" to="/admin/account/upgrade">*/}
                        {/*        <span className="sidebar-menu-text">{t('Upgrade Settings')}</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        {/*<li className={`sidebar-menu-item ${(slug === 'account-billing-payment-information') && 'active'}`} >*/}
                        {/*    <Link className="sidebar-menu-button" to="/admin/account-billing-payment-information">*/}
                        {/*        <span className="sidebar-menu-text">{t('Payment Information')}</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        {/*<li className={`sidebar-menu-item ${(slug === 'student-billing') && 'active'}`}>*/}
                        {/*    <Link className="sidebar-menu-button" to="/admin/billing">*/}
                        {/*        <span className="sidebar-menu-text">{t('Payment History')}</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        {/*<li className={`sidebar-menu-item ${(slug === 'student-invoice') && 'active'}`}>*/}
                        {/*    <Link className="sidebar-menu-button" to="/admin/invoice">*/}
                        {/*        <span className="sidebar-menu-text">{t('Student Invoice')}</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        {/*<li className={`sidebar-menu-item ${(slug === 'instructor-invoice') && 'active'}`}>*/}
                        {/*    <Link className="sidebar-menu-button" to="/admin/invoice">*/}
                        {/*        <span className="sidebar-menu-text">{t('Instructor Invoice')}</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        {/*<li className={`sidebar-menu-item ${(slug === 'edit-invoice') && 'active'}`}>*/}
                        {/*    <Link className="sidebar-menu-button" to="/admin/edit-invoice">*/}
                        {/*        <span>{t('Edit Invoice')}</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                    </ul>
                </li>

                {/*<li className="sidebar-menu-item{% if account_menu %} open{% endif %}">*/}
                {/*    <Link className="sidebar-menu-button" data-toggle="collapse" to="/admin/messages">*/}
                {/*        <FontAwesomeIcon*/}
                {/*            icon={faCommentAlt}*/}
                {/*            className="sidebar-menu-icon sidebar-menu-icon--left material-icons"*/}
                {/*        />*/}
                {/*        {t('Messages')}*/}
                {/*        <FontAwesomeIcon icon={faChevronRight} className='ml-auto sidebar-menu-toggle-icon'/>*/}
                {/*    </Link>*/}
                {/*    <ul className="sidebar-submenu sm-indent collapse{% if messages_menu %} show{% endif %}"*/}
                {/*        id="messages_menu">*/}
                {/*        <li className="sidebar-menu-item{% if slug === 'student-messages' %} active{% endif %}">*/}
                {/*            <Link className="sidebar-menu-button" to="/admin/messages">*/}
                {/*                <span className="sidebar-menu-text">{t('Conversation')}</span>*/}
                {/*                <span*/}
                {/*                    className="sidebar-menu-badge badge badge-primary badge-notifications ml-auto">2</span>*/}
                {/*            </Link>*/}
                {/*        </li>*/}
                {/*        <li className="sidebar-menu-item{% if slug === 'student-messages-2' %} active{% endif %}">*/}
                {/*            <Link className="sidebar-menu-button" to="/admin/messages-2.html">*/}
                {/*                <span className="sidebar-menu-text">{t('Conversation - 2')}</span>*/}
                {/*            </Link>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</li>*/}
            </ul>
        </div>
    )
};

export default MenuSettings;