import React from 'react';
import './TestBadge.scss'
import Button from "react-bootstrap/Button";

type TestBadgeProps = {
    text?: string,
    className?: string,
}

const TestBadge = (props: TestBadgeProps) => {
    const {text, className} = props

    return (
        <Button className={`testBadge ${className}`} type='button' variant='outline-dark'>
            <div className={`badge pulsate`}>&nbsp;</div>
            <div className='ml-3'>{text}</div>
        </Button>
    )
};

export default TestBadge;