import React from 'react';
import {Card} from "react-bootstrap";
import EarningsChart from "./EarningChart";
import {useTranslation} from "react-i18next";

type SalesGraphProps = {}

const SalesGraph = (props: SalesGraphProps) => {
    const {t} = useTranslation()

    return (
        <Card className="card">
            <Card.Header className="card-header d-flex align-items-center pb-0">
                <div className="flex">
                    <h4 className="card-title">{t('Earnings')}</h4>
                    <p className="card-subtitle">{t('Last 7 Days')}</p>
                </div>
            </Card.Header>
            <Card.Body className="pt-0">
                <div id="legend" className="chart-legend mt-0 mb-24pt justify-content-start" >
                    
                </div>
                <div className="chart" style={{height: "auto"}}>
                    <EarningsChart/>
                </div>
            </Card.Body>
        </Card>
    )
};

export default SalesGraph;