import React from 'react';
import {Link} from "react-router-dom";

type AccountQuickMenuItemsProps = {
    activeMenu : string
}

const AccountQuickMenuItems = (props: AccountQuickMenuItemsProps) => {

    const quickMenus = [
        {
            title:'Subscription',
            slug:'subscription',
            link:'/admin/account/subscription'
        },
        {
            title:'Upgrade Account',
            slug:'upgrade_account',
            link:'/admin/account/upgrade'
        },
        {
            title:'Payment Information',
            slug:'payment_information',
            link:'/admin/account/payment-information'
        },
        {
            title:'Payment History',
            slug:'payment_history',
            link:'/admin/account/payment-history'
        },
        {
            title:'Invoice',
            slug:'invoice',
            link:'/admin/account/invoice'
        }
    ]

    return (
        <div id="page-nav" className="col-lg-auto page-nav">
            <div data-perfect-scrollbar>
                <div className="page-section pt-lg-32pt">
                    <ul className="nav page-nav__menu">
                        {quickMenus.length > 0 && (
                            quickMenus.map((menuItem, index) => (
                                <li className="nav-item">
                                    <Link
                                        to={menuItem.link}
                                        className={`nav-link ${props.activeMenu === menuItem.slug ? "active" : ""}`}
                                    >
                                        {menuItem.title}
                                    </Link>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default AccountQuickMenuItems;