import React, {useContext} from 'react';
import './Header.scss'
import HeaderNavigation from "./HeaderNavigation";
import {Link} from "react-router-dom";
import persistentStore from "../../../stores/persistentStore";
import {ImpersonateHeader} from "../../../parts/ImpersonateHeader";
import CourseContext from "../../../contexts/CourseContext";

type HeaderProps = {
    logo?: string
}

const Header = (props: HeaderProps) => {
    const course = useContext(CourseContext)

    return (
        <div className='header'>
            <Link to='/' className='logo'>
                {props.logo && (
                    <img src={persistentStore.apiUrl + props.logo} alt='logo' />
                )}

                {!props.logo && course && (
                    <h2>{course.title}</h2>
                )}
            </Link>

            {persistentStore.isImpersonating && <ImpersonateHeader/>}

            <HeaderNavigation />
        </div>
    )
};

export default Header;