import React, {useContext} from 'react';
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import CourseContext from "../../../contexts/CourseContext";
import {useTranslation} from "react-i18next";
import {IUser} from "../../../interfaces/IUser";
import {Link} from 'react-router-dom'
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";

type MetaInfoProps = {}

const StudentOverview = (props: MetaInfoProps) => {
    const {t} = useTranslation()
    const course = useContext(CourseContext)

    return (
        <Card>
            <Card.Header>
                <div className="flex">
                    <div className='d-flex'>
                        <h4 className="card-title">{t('Students')}</h4>
                        <Link
                            to={`/admin/courses/${course.slug}/students`}
                            className='btn btn-sm btn-primary mt-2'
                            style={{marginLeft: 'auto', height: 'fit-content'}}
                        >
                            <FontAwesomeIcon icon={faUsers} />
                        </Link>
                    </div>
                    <p className="card-subtitle">{t('Enrolled in course')}</p>
                </div>
            </Card.Header>

            <Card.Body>
                {(course.students||[]).map((student: IUser, index: number) =>
                    <div className='d-flex' key={index}>
                        <label className="btn btn-primary btn-circle active">
                            <FontAwesomeIcon icon={faUser} className="material-icons"/>
                        </label>
                        <div className='ml-3 p-2'>
                            <Link to={`/admin/students/${student.id}`}>{student.name || student.email}</Link>
                        </div>
                    </div>
                )}
            </Card.Body>
        </Card>
    )
}

export default StudentOverview;