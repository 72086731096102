import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {VictoryBar, VictoryChart, VictoryLabel, VictoryLegend, VictoryTooltip} from 'victory'
import {payments as paymentsApi} from "../../../api/payments"
import {useTranslation} from "react-i18next";
import uiStore from "../../../stores/uiStore";

type EarningsChartProps = {}

const EarningsChart = (props: EarningsChartProps) => {
    const {t} = useTranslation()
    const [earnings, setEarnings] = useState<IEarning[]>([])
    // todo make this into real earnings data
    const earningsQuery = useQuery(
        ['earnings', paymentsApi],
        () => paymentsApi.getEarnings()
    )

    interface IEarning {
        quarter: string,
        earnings: number,
        label: string
    }

    useEffect(() => {
        if(earningsQuery.data){
            const formattedEarnings = earningsQuery.data.map((earning: IEarning, index: number) => {
                return {
                    quarter: earning.quarter,
                    earnings: earning.earnings/100,
                    label: (earning.earnings/100).toLocaleString(uiStore.locale, uiStore.moneyOptions)
                }
            })

            setEarnings(formattedEarnings)
        }
    }, [earningsQuery.data])

    return (
        <>
            {earnings.length > 0 && (
                <VictoryChart domainPadding={30} height={300} padding={{ top: 50, bottom: 60, left:70 }}>
                    <VictoryLegend x={0} y={0}
                        orientation="horizontal"
                        borderPadding={{right:15}}
                        style={{ border: { stroke: "#B8BDC2" }, title:{fontSize: 20, width:90 }}}
                        data={[
                            { name: t("Earnings"), symbol: { fill: "#2196f3" }, labels: { fill: "#B8BDC2" } }
                        ]}
                    />
                    <VictoryBar
                        labelComponent={<VictoryTooltip
                            flyoutStyle={{
                                fill: "#2196f3",
                                stroke:"#fff",
                            }}
                            labelComponent={<VictoryLabel style={{fill:"#fff"}}/>}
                        />}
                        
                        cornerRadius={{topLeft: 10, topRight: 10}}
                        barWidth={20}                        
                        style={{
                            data: {
                                fill: "#2196F3"
                            }
                        }}
                        data={earnings}
                        x="quarter"
                        y="earnings"

                    />
                </VictoryChart>
            )}
        </>
    )
}

export default EarningsChart;