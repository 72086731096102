import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from "react-dropzone";
import {Image, Alert, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import store from "../../../stores/store";
import {toast} from "react-toastify";
import {courses} from "../../../api/courses";
import persistentStore from "../../../stores/persistentStore";

type CourseLogoProps = {
    logoUrl: string,
    setLogoUrl: (url: string) => void,
}

const CourseLogo = (props: CourseLogoProps) => {
    const {setLogoUrl, logoUrl} = props
    const [selectedFile, setSelectedFile] = useState('')
    const [selectedFileName, setSelectedFileName] = useState('')
    const [selectedFileNamePreview, setSelectedFileNamePreview] = useState('')
    const [uploadError, setUploadError] = useState<string | undefined>()
    const [logoSource, setLogoSource] = useState<string|undefined>()
    const [deletePoster, setDeletePoster] = useState(false)
    const {t} = useTranslation()

    const onDrop = useCallback(acceptedFiles => {
        // clear any upload errors
        setUploadError(undefined)

        const fileToUpload = acceptedFiles[0]
        if (!fileToUpload) {
            return
        }

        const sizeInMB: number = fileToUpload.size / 1000000;
        const fileTooLarge = sizeInMB > store.courseImageSizeLimit

        if (fileTooLarge) {
            const errorMessage: string = t('This file is over the maximum file limit of')
                + ' ' + store.courseImageSizeLimit + 'MB '
                + `): ${sizeInMB.toFixed(2)}M. ` +
                t('Please select a smaller logo and try again')
            // get the file size in standard format
            setUploadError(errorMessage)
            return
        }

        setSelectedFileName(fileToUpload.name)
        setSelectedFile(fileToUpload)
        setSelectedFileNamePreview(URL.createObjectURL(fileToUpload))

        // eslint-disable-next-line
    }, [])

    const removeFile = () => {
        // empty uploaded resources
        setSelectedFileName('')
        setSelectedFile('')
        setSelectedFileNamePreview('')

        // clear out set logo
        setLogoUrl('')
        setLogoSource(undefined)
        setDeletePoster(true)
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png, image/gif, image/bmp'
    });

    // upload logo when was dropped
    useEffect(() => {
        if (!selectedFile || ! selectedFileName) {
            return
        }

        const formData = new FormData();
        formData.append(
            "image",
            selectedFile,
            selectedFileName
        )

        courses.uploadImage(formData as any)
            .then((image: any) => {
                toast.success(t('Course logo updated'))
                setLogoUrl(image.url)
            })
            .catch((error: any) => {
                console.error(error.response)
                toast.error(t('Could not update course logo'))
            })
        // eslint-disable-next-line
    }, [selectedFile, selectedFileName])

    useEffect(() => {
        if(deletePoster){
            setLogoSource(undefined)
            setLogoUrl('')
        }
        else if(selectedFile){
            setLogoSource(selectedFileNamePreview)
        }
        else if(logoUrl){
            setLogoSource(persistentStore.apiUrl + logoUrl)
        }
        // eslint-disable-next-line
    }, [selectedFile, logoUrl, selectedFileNamePreview])

    return (
        <Card>
            {uploadError && <Alert variant='danger'>{uploadError}</Alert>}

            <div className='' {...getRootProps()} style={{minHeight: "150px"}}>
                {logoSource && ! deletePoster && (
                    <>
                        <Image
                            thumbnail
                            width='100%'
                            alt={selectedFileName}
                            src={logoSource}
                        />
                        <span className="d-flex btn text-danger" onClick={removeFile}>
                            {!isDragActive && t('Delete logo')}
                            {isDragActive && isDragReject && t('This file extension is not allowed')}
                            {isDragActive && !isDragReject && t('Drop it')}
                        </span>
                    </>
                )}

                {(!logoSource || deletePoster) && (
                    <div style={{display: 'flex', height: '150px', alignItems: 'center', justifyContent: 'center', backgroundColor: '#89d1f6'}}>
                        <div style={{width: '80%', color: '#fafafa', fontSize: '30px', fontWeight: 'bolder', textAlign: 'center'}}>
                            {!isDragActive && t('Drag a logo here')}
                            {isDragActive && isDragReject && t('This file extension is not allowed')}
                            {isDragActive && !isDragReject && t('Drop it')}
                        </div>
                    </div>
                )}
            </div>
            <input {...getInputProps()} />
        </Card>
    )
};

export default CourseLogo;