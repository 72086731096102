import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";
import {ISetting} from "../interfaces/ISetting";

const settingRequests = () => {
    const add = async (setting: ISetting) => {
        const path = `/settings`

        return axios.post(persistentStore.apiUrl + path, setting, persistentStore.authHeader)
            .then((response:any) => errorChecker(response))
            .then((response:any) => response.data)
    };

    const getForUser = async (userId: string) => {
        const path = `/settings/for-user/${userId}`

        return axios.get(persistentStore.apiUrl + path,  persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.settings) {
                    throw Error('The response did not contain settings');
                }

                return data.settings
            })
    }

    const getForCourse = async (courseSlug: string) => {
        const path = `/settings/for-course/${courseSlug}`

        return axios.get(persistentStore.apiUrl + path,  persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.settings) {
                    throw Error('The response did not contain settings');
                }

                return data.settings
            })
    }

    const update = async (setting: ISetting) => {
        // if there is no id, we need to add the setting
        if(! setting.id){
            return add(setting)
        }

        const path = `/settings/${setting.id}`

        return axios.put(persistentStore.apiUrl + path, setting,persistentStore.authHeader)
            .then((response:any) => errorChecker(response))
            .then((response:any) => response.data)
    };

    const updateMany = async (settings: ISetting[]) => {
        const path = `/settings/update-many`

        return axios.put(persistentStore.apiUrl + path, settings, persistentStore.authHeader)
            .then((response:any) => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.settings) {
                    throw Error('The response did not contain settings');
                }

                return data.settings
            })
    };

    return {
        add,
        update,
        updateMany,
        getForUser,
        getForCourse,
    }
}


export const settings = settingRequests()
