import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Container, Row, Col, Card} from "react-bootstrap";
import BreadCrumb from "../../../components/BreadCrumb";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import AccountQuickMenuItems from './AccountQuickMenuItems';

type TrainerSubscriptionProps = {}

const TrainerSubscription = (props: TrainerSubscriptionProps) => {
    const {t} = useTranslation()

    const path: IBreadCrumb[] = [
        {name: 'Home', link: '#', active: false},
        {name: 'Account', link: '/admin/account/edit', active: false},
        {name: 'Subscription', link: '/admin/account/subscription', active: true},
    ]
    const plan = 'Basic'
    const planPrice = '$9'
    const planPeriod = 'per month'
    const planRenewedAt = 'Aug 20, 2021'
    const planAutoRenew = true
    const planCardInfo = 'Visa ending with 2819'

    return (
        <Container className="page__container p-0">
            <Container fluid>
                <Row className="m-0">
                    <Col lg className="container-fluid page__container">
                        <BreadCrumb crumbs={path}/>
                        <h1 className="h2">{t('Subscription')}</h1>

                        <Card>
                            <div className="list-group list-group-fit">
                                <div className="list-group-item">
                                    <div className="form-group row mb-0">
                                        <label className="col-form-label form-label col-sm-3">{t('Your current plan')}</label>
                                        <div className="col-sm-9 d-flex align-items-center">
                                            <div className="flex">{`${plan} ${planPrice} ${planPeriod}`}</div>
                                            <Link to="/admin/account/upgrade" className="text-secondary">{t('Change plan')}</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="list-group-item">
                                    <div className="form-group row mb-0">
                                        <label className="col-form-label form-label col-sm-3">{t('Billing cycle')}</label>
                                        <div className="col-sm-9">
                                            <p className="mb-1">{t('You will be charged')} {planPrice} on {planRenewedAt}</p>
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" checked={planAutoRenew} id="customCheck1" />
                                                <label className="custom-control-label" htmlFor="customCheck1">
                                                    <strong>{t('Enable automatic renewal')}</strong>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="list-group-item">
                                    <div className="form-group row mb-0">
                                        <label className="col-form-label form-label col-sm-3">{t('Payment information')}</label>
                                        <div className="col-sm-9 d-flex align-items-center">
                                            <img src={`${process.env.PUBLIC_URL}/img/visa.svg`} alt="visa" width="40" className="mr-16pt" />
                                            <div className="flex">{planCardInfo}</div>
                                            <Link to="/admin/account/payment-information" className="text-secondary">{t('Change method')}</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="list-group-item">
                                    <div className="form-group row mb-0">
                                        <label className="col-form-label form-label col-sm-3">{t('Cancel')}</label>
                                        <div className="col-sm-9">
                                            <Link to="#" className="btn btn-outline-secondary">{t('Cancel subscription')}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    {<AccountQuickMenuItems activeMenu="subscription" />}
                </Row>
            </Container>
        </Container>
    )
};

export default TrainerSubscription;