import React, {useCallback, useEffect, useState} from 'react'
import {useHistory, useParams, Link} from 'react-router-dom'
import {observer} from 'mobx-react'
import {users} from '../../api/users'
import {useTranslation} from "react-i18next";
import Alert from "react-bootstrap/Alert";
import FormGroup from "react-bootstrap/FormGroup";
import Spinner from "react-bootstrap/Spinner";
import {useLoading} from "../../utils/useLoading";
import {Button} from "react-bootstrap";
import qs from "querystring";
import {toast} from "react-toastify";
import {ILoginPostData} from "../../interfaces/ILoginPostData";
import {IUser} from "../../interfaces/IUser";
import ChooseCourseModal from "./ChooseCourseModal";
import {ICourse} from "../../interfaces/ICourse";

const ResetPassword = () => {
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [loading, startLoading, stopLoading] = useLoading()
    const [user, setUser] = useState<IUser | undefined>()
    const [courseSlug, setCourseSlug] = useState<string | undefined>()
    const history = useHistory()
    const {t} = useTranslation()
    const {selector, validator} = useParams<{ selector: string, validator: string }>()
    const [redirectLink, setRedirectLink] = useState('/admin/dashboard')

    const [passwordIsReset, setPasswordIsReset] = useState(false)
    const [userHasOneCourse, setUserHasOneCourse] = useState(false)
    const [userHasChosenACourse, setUserHasChosenACourse] = useState(false)

    const loginUser = useCallback((loginData: ILoginPostData) => {
        users.login(loginData)
            .then((result) => {
                if (result.success !== true) {
                    toast.error(t("An error occurred, please try again"))
                    return
                }

                toast.success(t("Hold on, you'll be logged in automatically"))

                if (user && courseSlug) {
                    // we have enough information now to log the user in ourselves
                    loginData.target = 'course'
                    loginData.courseSlug = courseSlug
                    setTimeout(() => {
                        history.push(`/course/${courseSlug}`)
                    }, 2000)
                } else {
                    setTimeout(() => {
                        history.push(redirectLink)
                    }, 2000)
                }
            })
    }, [courseSlug, redirectLink, user, history, t])

    // when there is a url, store that for the redirect
    useEffect(() => {
        const queryString = window.location.search.startsWith('?')
            ? window.location.search.slice(1)
            : window.location.search
        const queryParams = qs.parse(queryString)

        if (queryParams.hasOwnProperty('course') && typeof queryParams.course === 'string') {
            setCourseSlug(queryParams.course)
            console.log(queryParams.course)
        }
    }, [])

    // we can login when the password is set, there is 1 course the user is enrolled for or when the user
    // chose to which course to login
    useEffect(() => {
        if(courseSlug && userHasChosenACourse && (userHasOneCourse || userHasChosenACourse)){
            // we have enough information now to log the user in ourselves
            const loginData: ILoginPostData = {
                target: 'backend',
                username: user?.email || 'unknown@mail.com',
                password: password,
                remember_me: true,
            }

            if (user && courseSlug) {
                // we have enough information now to log the user in ourselves
                loginData.target = 'course'
                loginData.courseSlug = courseSlug
                setRedirectLink(`/course/${courseSlug}`)
            }

            loginUser(loginData)
        }
    }, [passwordIsReset, userHasOneCourse, userHasChosenACourse, courseSlug, loginUser, password, user])

    useEffect(() => {
        setSuccess('')
        setError('')

        // validate the password reset values
        users.validatePasswordResetTokens({selector: selector, validator: validator})
            .then(response => {
                if (response.data.user) {
                    setUser(response.data.user)
                } else {
                    console.error('No user data in response')
                    console.error(response.data)
                }
            })
            .catch(error => {
                console.error(error.response)
                setError(error.response.data.errors?.detail)
            })
    }, [selector, validator])

    const userChoseCourse = (chosenCourse: ICourse) => {
        setCourseSlug(chosenCourse.slug)
        setUserHasChosenACourse(true)
    }

    const handlePasswordChange = (event: any) => {
        event.preventDefault()
        startLoading()

        users.resetPassword({password: password, validator: validator, selector: selector})
            .then((response) => {
                setUser(response.data.user)

                stopLoading()

                if (response.success !== true) {
                    setError(response.error)
                    return
                }

                const successMessage: string = t('Password successfully changed')
                setSuccess(successMessage)

                // if the user has only 1 course, we know where to redirect
                if(user?.Courses?.length === 1){
                    setUserHasOneCourse(true)
                    setCourseSlug(user?.Courses[0].slug)
                }

                setPasswordIsReset(true)
            })
    }

    return (
        <section>
            <form className='login' onSubmit={(event) => handlePasswordChange(event)}>
                {error && <Alert variant='danger' style={{width: '90%'}}>{error}</Alert>}
                {success && <Alert variant='success' style={{width: '90%'}}>
                    {t('Password successfully changed. You will be logged in automatically. Hold on')}
                </Alert>}

                <h2>{t('Change your password')}</h2>

                <FormGroup>
                    <div><strong>{t('Email')}</strong>: {user?.email}</div>
                </FormGroup>

                <FormGroup>
                    <label htmlFor="inputPassword" className="sr-only">{t('Password')}</label>
                    <input
                        type="password"
                        id="inputPassword"
                        className="form-control"
                        placeholder={t("New password")}
                        name="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        disabled={(success !== '') || loading}
                        required
                        autoFocus
                    />
                </FormGroup>

                <Button
                    className="btn btn-primary btn-block mb-4"
                    style={{width: '90%'}}
                    type="submit" disabled={(success !== '') || loading}
                    onClick={handlePasswordChange}
                >
                    {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                    {!loading && t('Change password')}
                </Button>

                <div className='mb-3'>
                    <Link
                        to={courseSlug ? `/course/${courseSlug}/login` : '/admin/login'}
                    >
                        {t('Login')}
                    </Link>
                </div>
            </form>

            <ChooseCourseModal
                show={passwordIsReset && !userHasOneCourse}
                handleSubmit={userChoseCourse}
                courses={user?.Courses || []}
            />
        </section>
    )
}

export default observer(ResetPassword)

