import React from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import persistentStore from "../../../stores/persistentStore";

type HeaderNavigationProps = {}

const HeaderNavigation = (props: HeaderNavigationProps) => {
    const {t} = useTranslation()
    const {courseSlug} = useParams<{ courseSlug: string }>()
    const history = useHistory()

    // @ts-ignore
    const logout = (event: MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault()
        persistentStore.emptyStore()
        history.push(`/course/${courseSlug}/login`)
    }

    return (
        <ul className='navigation'>
            {persistentStore.loggedIn && (
                <li>
                    <Button
                        variant={"link"}
                        onClick={(event) => logout(event)}
                    >
                        {t('Logout')}
                    </Button>
                </li>
            )}
            {!persistentStore.loggedIn && (
                <li>
                    <Button
                        variant={"link"}
                        onClick={() => history.push(`/course/${courseSlug}/login`)}
                    >
                        {t('Login')}
                    </Button>
                </li>
            )}
            {!persistentStore.loggedIn && (
                <li>
                    <Button
                        variant={"link"}
                        onClick={() => history.push(`/enroll/${courseSlug}`)}
                    >
                        {t('Enroll')}
                    </Button>
                </li>
            )}
        </ul>
    )
};

export default HeaderNavigation;