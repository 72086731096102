import React from 'react';
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import {ISale} from "../../../interfaces/ISale";
import {useTranslation} from "react-i18next";

type EarningsProps = {
    sales: ISale[]
}

const SalesTable = (props: EarningsProps) => {
    const {t} = useTranslation()
    const {sales} = props

    return (
        <Card className="card">
            <Card.Header className="card-header d-flex align-items-center">
                <div className="flex">
                    <h4 className="card-title">{t('Sales today')}</h4>
                    <p className="card-subtitle">{t('by course')}</p>
                </div>
            </Card.Header>
            <Card.Body>
                {
                    <ul className="list-group list-group-fit mb-0">
                        {sales.map((sale: ISale, index: number) => (
                            (sale.payments || []).length > 0 && (
                                <li className="list-group-item" key={index}>
                                    <div className="media align-items-center">
                                        <div className="media-body">
                                            <Link to="#" className="text-body">
                                                <strong>{sale.title}</strong>
                                            </Link>
                                        </div>
                                        <div className="media-right">
                                            <div className="text-center">
                                            <span
                                                className="badge badge-pill badge-primary">{sale.payments && (sale.payments.length || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        ))}
                    </ul>
                }
            </Card.Body>
        </Card>
    )
};

export default SalesTable;