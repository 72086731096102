import React, {FormEvent, useEffect, useState} from 'react';
import './LoginStudent.scss'
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useParams, Link} from "react-router-dom";
import {ILoginPostData} from "../../interfaces/ILoginPostData";
import {users} from "../../api/users";
import Alert from "../../components/Alert";
import {courses} from "../../api/courses";
import Spinner from "react-bootstrap/Spinner";
import qs from "querystring";
import {ICourse} from "../../interfaces/ICourse";
import trainingsomgevingLogo from '../../assets/img/trainingsomgvingLogo.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGraduationCap} from "@fortawesome/free-solid-svg-icons/faGraduationCap";

type LoginProps = {}

const LoginStudent = (props: LoginProps) => {
    const {courseSlug} = useParams<{ courseSlug: string }>()
    const {t} = useTranslation()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<string | undefined>()
    const [isPublic, setIsPublic] = useState<boolean | undefined>()
    const [buttonText, setButtonText] = useState('')
    const [enrolled, setEnrolled] = useState(false)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [course, setCourse] = useState<ICourse | undefined>(undefined)
    const history = useHistory()

    const startLoading = () => setLoading(true)
    const stopLoading = () => setLoading(false)

    // when there is a logintoken, try logging in immediately
    useEffect(() => {
        const queryString = window.location.search.startsWith('?')
            ? window.location.search.slice(1)
            : window.location.search
        const queryParams = qs.parse(queryString)

        if (queryParams.hasOwnProperty('token') && typeof queryParams.token === 'string') {
            loginWithToken(queryParams.token)
        }
        // eslint-disable-next-line
    }, [courseSlug])

    useEffect(() => {
        startLoading()
        courses.get(courseSlug)
            .then((courseFromApi: ICourse) => {
                setCourse(courseFromApi)
                stopLoading()
                if (courseFromApi.type === 'public') {
                    setButtonText(t('Send me the loginlink'))
                    setIsPublic(true)
                } else {
                    setButtonText(t('Login'))
                    setIsPublic(false)
                }
            })
            .catch((error) => {
                console.error(error.response)
            })
            .finally(() => stopLoading())
        // eslint-disable-next-line
    }, [courseSlug])

    const setLoggedInCookie = () => {
        const domain = process.env.NODE_ENV === 'development'
            ? 'trainingsomgeving.local'
            : 'trainingsomgeving.nl'
        const d = new Date()
        const validDays = 30
        d.setTime(d.getTime() + (validDays*24*60*60*1000));
        const expires = d.toUTCString();
        const info={path: `/course/${courseSlug}`}
        document.cookie = `logged_in=${JSON.stringify(info)};expires=${expires};domain=${domain}`
    }

    const clearMessages = () => {
        setMessage('')
        setError(undefined)
    }

    const handleLogin = (event: FormEvent) => {
        event.preventDefault()
        startLoading()
        clearMessages()

        if (isPublic) {
            return enrollForFreeTraining()
        }

        return loginToPrivateTraining()
    }

    const loginWithToken = (token: string) => {
        clearMessages()
        startLoading()

        const loginPostData: ILoginPostData = {
            username: '',
            password: '',
            remember_me: true,
            token: token,
            target: "course",
            courseSlug: courseSlug
        }

        return apiLoginRequest(loginPostData)
    }

    const enrollForFreeTraining = () => {
        courses.enroll({course_slug: courseSlug, email: email})
            .then((enrollResult) => {
                setEnrolled(true)
                setMessage(t('Success! Check your email for the loginlink'))
            })
            .catch((response) => {
                const errors = response.response.data?.errors
                if(errors && errors.title){
                    setError(errors.detail)
                }

                if(response.response.status === 500 && response.response.data.message){
                    setError('Error: ' + response.response.data.message)
                }
            })
            .finally(() => stopLoading())
    }

    const loginToPrivateTraining = () => {

        const loginPostData: ILoginPostData = {
            username: email,
            password: password,
            remember_me: true,
            target: "course",
            courseSlug: courseSlug
        }

        return apiLoginRequest(loginPostData)
    }

    const apiLoginRequest = (loginPostData: ILoginPostData) => {
        users.login(loginPostData)
            .then((result: any) => {
                stopLoading()

                setLoggedInCookie()
                history.push(`/course/${courseSlug}`)
            })
            .catch((error: any) => {
                stopLoading()
                if (error.response?.data?.error?.status === "FAILURE_CREDENTIALS_INVALID") {
                    setError(t('Incorrect username or password'))
                    return
                }

                const noAccessMsg = "You have no access to this course"
                if (error.response?.data?.errors?.detail === noAccessMsg) {
                    setError(t('You have no access to this course'))
                    return
                }

                if (error.response?.data?.errors?.title === "Access Denied") {
                    const message = error.response.data.errors.detail
                    setError(message)
                    return
                }

                if (error.response?.status === 404) {
                    const message = error.response.data.errors.detail
                    setError(message)
                    return
                }

                console.error('Unhandled request')
                console.error(error)
            })
            .finally(() => stopLoading())
    }

    return (
        <section className='flex-column'>
            <div className="navbar" style={{width: "100vw", height: 80, backgroundColor: "white"}}>
                {!course?.logo_url && (
                    <h4>{course?.title}</h4>
                )}

                {course?.logo_url && (
                    <img src={course.logo_url} className="ml-5" width='200px' alt='course-logo'/>
                )}
            </div>

            <div className="login-page d-flex align-items-center">
                <div className="col-sm-12 col-md-8 col-lg-4 mx-auto">
                    <Card className="navbar-shadow card-width-500">
                        <Card.Header className="text-center">
                            <h4>{t('Login to')} {course?.title}</h4>
                            {message && <Alert message={message} type={"success"}/>}
                            {error && <Alert message={error} type={"danger"}/>}
                        </Card.Header>

                        <Card.Body className="card-body">
                            {isPublic && (
                                <>
                                    <p>{t('This is a free training')}.</p>
                                    <p>{t('In order to save your progress and let you resume where you left of at another time, we ask you to provide your emailaddress')}.</p>
                                    <p>{t('You\'ll receive a loginlink immediately so you can start the training right away')}.</p>
                                </>
                            )}

                            <form onSubmit={handleLogin}>
                                <div className='form-group'>
                                    <input
                                        id='emailInput'
                                        type='text'
                                        name='email'
                                        placeholder={t('your emailaddress')}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        disabled={enrolled}
                                        className="form-control"
                                    />
                                </div>

                                {(!isPublic) && (
                                    <div className='form-group'>
                                        <label htmlFor='passwordInput'>{t('Password')}</label>
                                        <input
                                            type='password'
                                            name='password'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="form-control"
                                        />
                                    </div>
                                )}

                                <div className="text-center">
                                    <button
                                        className='studentLoginButton mb-5'
                                        onClick={handleLogin}
                                        disabled={enrolled}
                                    >
                                        {!loading && buttonText}
                                        {loading && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </button>

                                    {!isPublic && (
                                        <p className='password-reset'>
                                            <Link to={`/reset-password-request?from=${courseSlug}`}>
                                                {t('Forgot Password?')}
                                            </Link>
                                        </p>
                                    )}
                                </div>
                            </form>
                        </Card.Body>

                        <Card.Footer className="p-0">
                            <div className="d-flex col-12 m-2 p-0">
                                <Link to={`/enroll/${courseSlug}`} className="border-0 rounded-0 text-center col-6">
                                    <div className="d-flex m-2">
                                        {course?.logo_url && (
                                            <img src={trainingsomgevingLogo} className="mr-1" width='20px' alt='login'/>
                                        )}

                                        {!course?.logo_url && (
                                            <FontAwesomeIcon icon={faGraduationCap} className='mt-1 mr-2'
                                                             color='black'/>
                                        )}
                                        {t('Enroll to course')}
                                    </div>
                                </Link>
                                <Link to={'/admin/login'} className="border-0 rounded-0 text-center col-6">
                                    <div className="d-flex p-0 m-2">
                                        <img src={trainingsomgevingLogo} className="mr-1" width='20px' alt='login'/>
                                        {t('Login in with TrainingsOmgeving')}
                                    </div>
                                </Link>
                            </div>
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        </section>
    )
}

export default LoginStudent;