import React, {useEffect, useState} from 'react';
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/BreadCrumb";
import {Col, Row} from "react-bootstrap";
import {useQueryClient} from "react-query";
import {useParams} from "react-router-dom";
import { IQuiz } from "../../../interfaces/IQuiz";
import { IQuestion } from "../../../interfaces/IQuestion";
import {useLoading} from "../../../utils/useLoading";
import {useQuery} from "react-query";
import {toast} from "react-toastify";
import Loader from "../../../components/Loader";
import QuizHeader from "./QuizHeader";
import BasicInformation from "./BasicInformation";
import { quizzes } from '../../../api/quizzes';
import history from "../../../utils/history";
import QuestionsListing from "./QuestionsListing";
import {quizQuestions as questionsApi} from "../../../api/quizQuestions"

type QuizEditProps = {}

const QuizEdit = (props: QuizEditProps) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const {quizSlug} = useParams<{ quizSlug: string }>();
    // get quiz
    const quizQuery = useQuery<IQuiz | undefined>(
        ['quizzes', quizSlug],
        () => quizzes.get(quizSlug),
        {enabled: quizSlug !== undefined}
    );

    const [quizData, setQuizData] = useState<IQuiz>();
    const [questions, setQuestions] = useState<IQuestion[]>([])
    const [title, setTitle] = useState<string>('')
    const [courseId, setCourseId] = useState<string>('')
    const [imageUrl, setImageUrl] = useState<string | undefined>('')
    const [loading, startLoading, stopLoading] = useLoading()
    // const [error, setError] = useState<string | undefined>()

    const crumbs: IBreadCrumb[] = [
        {
            name: t('Home'),
            active: false,
            link: '/admin/dashboard'
        },
        {
            name: t('Quizzes'),
            active: false,
            link: '/admin/quiz-manager'
        },
        {
            name: title || 'quiz',
            active: false,
            link: `/admin/quizzes/${quizSlug}/edit`
        },
    ]

    useEffect(() => {
        if (quizSlug && quizQuery.data) {
            setQuizData(quizQuery.data)
            setTitle(quizQuery.data.name)
            setCourseId(quizQuery.data.course_id)
            setImageUrl(quizQuery.data.image_url)

            if (quizQuery.data.quiz_questions) {
                setQuestions(quizQuery.data.quiz_questions)
            }
        }
    }, [quizQuery.data, quizSlug])

    const saveQuiz = () => {
        startLoading()

        const updatedQuiz: IQuiz = Object.assign(
            quizData,
            {
                name:title,
                image_url: imageUrl,
                slug: quizSlug,
                course_id: courseId
            }
        )

        quizzes.update(updatedQuiz)
            .then((result) => {
                stopLoading()
                toast.success(t('Quiz updated'))
                queryClient.invalidateQueries('quizzes')
                history.push(`/admin/quizzes/${quizSlug}/edit`)
            })
            .catch((error) => {
                stopLoading()
                console.error(error.response)
                toast.error(error.response?.data?.errors?.detail)
            })
    }

    const storeNewQuestionOrder = (questions: (IQuestion)[], question: (IQuestion)) => {
        // first set the new sort_order
        interface IQuestionId {
            questionId: string | null, 
            questions: (IQuestion)[]
        }

        const emptyResult: IQuestionId = {questionId: null, questions: []}
        const updatedQuestions: IQuestionId = questions.reduce((carry, question: (IQuestion), index: number) => {
            question.sort_order = index + 1

            carry.questions.push(question)
            return carry
        }, emptyResult)
        //console.log(updatedQuestions) 
        questionsApi.updateMany(updatedQuestions.questions)
            .then((result) => {
                queryClient.invalidateQueries('quizzes')
            })
            .catch((error) => {
                // error.response
                toast.error(t('Error while updating question order'))
            })  
    }

    if (!quizQuery.data) {
        return <Loader/>
    }

    return (
        <div className="container-fluid page__container">
            
            <BreadCrumb crumbs={crumbs}/>

            {/*{error && <Alert message={error} type='danger'/> }*/}

            <QuizHeader
                loading={loading}
                saveQuiz={saveQuiz}
                title={title}
                quiz={quizQuery.data}
            />

            <Row>
                <Col md={12}>
                    <BasicInformation
                        title={title}
                        setTitle={setTitle}
                        courseId={courseId}
                        setCourseId={setCourseId}
                        imageUrl={imageUrl}
                        setImageUrl={setImageUrl}
                        saveQuiz={saveQuiz}
                    />
                </Col>
            </Row>

            {quizData && (
                <QuestionsListing 
                    quiz_questions={questions}
                    quiz={quizData}
                    storeNewQuestionOrder={storeNewQuestionOrder}
                />    
            )}   
        </div>
    )
};

export default QuizEdit;
