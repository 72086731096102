import React, {FormEvent, useState} from 'react';
import './LoginStudent.scss'
import {useTranslation} from "react-i18next";
import {users} from "../../api/users";
import Alert from "../../components/Alert";
import {Link, useLocation} from "react-router-dom";
import history from "../../utils/history";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

type LoginProps = {}

const ResetPasswordRequest = (props: LoginProps) => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [error, setError] = useState<string | undefined>()
    const [requestSent, setRequestSent] = useState(false)

    const {search} = useLocation()
    const searchParams = new URLSearchParams(search)
    const from = searchParams.get('from')

    const requestPasswordReset = (event: FormEvent) => {
        event.preventDefault()
        setLoading(true)
        setError(undefined)

        users.resetPasswordRequest(email)
            .then((result: any) => {
                setLoading(false)
                // history.push(`/course/${courseSlug}`)
                setRequestSent(true)
            })
            .catch((error: any) => {
                setLoading(false)
                console.error('Unhandled request')
                console.error(error)
            })
    }

    const toLogin = () => {
        let url = ''

        if(from){
            url = from === 'admin'
                ? `/admin/login?email=${encodeURIComponent(email)}`
                : `/course/${from}/login`
        }
        else {
            history.goBack()
            return
        }

        history.push(url)
    }

    return (
        <section>

            <div className='login'>
                {error && (
                    <Alert message={error} type='danger'/>
                )}

                {requestSent && (
                    <>
                        <h2>{t('Request sent to')} {email}</h2>

                        <p>{t('Check your mailbox for the password reset email')}.</p>
                        <p>{t('When the email did not arrive within 5 minutes, check your spambox as well')}.</p>

                        <button className='btn-login mb-4' onClick={toLogin}>
                            {t('To login page')}
                        </button>
                    </>
                )}

                {! requestSent && (
                    <>
                        <h2>{t('Reset password')}</h2>
                        <p>{t('Provide your emailaddress and we\'ll send you a link with which you can reset your password')}.</p>
                        <form onSubmit={requestPasswordReset} noValidate>

                        <div className='form-group'>
                            <label htmlFor='emailInput'>{t('Email')}:</label>
                            <input
                                id='emailInput'
                                type='text'
                                name='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={t('email')}
                                required
                                autoFocus
                            />
                        </div>

                        <Button type="submit" className='btn-login mb-4'>
                            {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                            {!loading && t('Request password reset email')}
                        </Button>

                        </form>
                        <div className='mb-3'>
                            <Link to={'/admin/login'} >
                                {t('Login')}
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </section>
    )
};

export default ResetPasswordRequest