import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Container, Row, Col, Button, Card} from "react-bootstrap";
import BreadCrumb from "../../../components/BreadCrumb";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import Pagination from "../../../parts/Pagination";
import AccountQuickMenuItems from './AccountQuickMenuItems';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {IHistory} from "../../../interfaces/IHistory";

type PaymentHistoryProps = {}

const PaymentHistory = (props: PaymentHistoryProps) => {
    const {t} = useTranslation()
    const [historyToShow, setHistoryToShow] = useState<IHistory[]>([])

    const path: IBreadCrumb[] = [
        {name: 'Home', link: '#', active: false},
        {name: 'Account', link: '/admin/account/edit', active: false},
        {name: 'Subscription', link: '/admin/account/subscription', active: true},
    ]
    const history: IHistory[] = [{
        document: 12199,
        amount: 25,
        status_class: 'text-success',
        status_text: 'paid',
        date: '12 Feb 2021'
    }, {
        document: 8331,
        amount: 25,
        status_class: 'text-danger',
        status_text: 'unpaid',
        date: '12 Jan 2021'
    }, {
        document: 2421,
        amount: 25,
        status_class: 'text-success',
        status_text: 'paid',
        date: '12 Dec 2020'
    }]

    return (
        <Container className="page__container p-0">
            <Container fluid>
                <Row className="m-0">
                    <Col lg className="container-fluid page__container">
                        <BreadCrumb crumbs={path}/>
                        <h1 className="h2">{t('Payment History')}</h1>

                        <div className="card border-left-3 border-left-danger card-2by1">
                            <div className="card-body">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        {t('Please pay your amount due')}
                                        <strong className="text-danger">$25.00</strong> {t('with invoice')} <Link
                                        to="#">#8331</Link>
                                    </div>
                                    <div className="media-right">
                                        <Link to="/admin/account/pay"
                                              className="btn btn-success float-right">{t('Pay Now')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Card className="table-responsive"
                              data-toggle="lists"
                              data-lists-values='[
                            "js-lists-values-document", 
                            "js-lists-values-amount",
                            "js-lists-values-status",
                            "js-lists-values-date"
                            ]'
                              data-lists-sort-by="js-lists-values-document"
                              data-lists-sort-desc="true">
                            <table className="table mb-0">
                                <thead className="thead-light">
                                <tr>
                                    <th colSpan={4}>
                                        <Button
                                            variant='link'
                                            className="sort"
                                            data-sort="js-lists-values-document">{t('Document')}</Button>
                                        <Button
                                            variant='link'
                                            className="sort"
                                            data-sort="js-lists-values-amount">{t('Amount')}</Button>
                                        <Button
                                            variant='link'
                                            className="sort"
                                            data-sort="js-lists-values-status">{t('Status')}</Button>
                                        <Button
                                            variant='link'
                                            className="sort"
                                            data-sort="js-lists-values-date">{t('Date')}</Button>
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="list">
                                {historyToShow.length && historyToShow.map((item, index) => (
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <small className="text-uppercase text-muted mr-2">{t('Invoice')}</small>
                                                <a href="{{ prefix }}invoice.html" className="text-body small">#<span
                                                    className="js-lists-values-document">{item.document}</span></a>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <div className="d-flex align-items-center">
                                                <small className="text-uppercase text-muted mr-2">{t('Amount')}</small>
                                                <small className="text-uppercase">$<span
                                                    className="js-lists-values-amount">{item.amount}</span> USD</small>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <div className="d-flex align-items-center">
                                                <small className="text-uppercase text-muted mr-2">{t('Status')}</small>
                                                <FontAwesomeIcon icon={faCircle}
                                                                 className={`material-icons ${item.status_class} md-18 mr-2`}/>

                                                <small
                                                    className="text-uppercase js-lists-values-status">{item.status_text}</small>
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            <div className="d-flex align-items-center text-right">
                                                <small className="text-uppercase text-muted mr-2">{t('Date')}</small>
                                                <small
                                                    className="text-uppercase js-lists-values-date">{item.date}</small>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                                </tbody>
                            </table>
                        </Card>
                        <Pagination records={history} setPaginatedRecords={setHistoryToShow}/>
                    </Col>

                    <AccountQuickMenuItems activeMenu="payment_history"/>
                </Row>
            </Container>
        </Container>
    )
};

export default PaymentHistory;