import React, {useContext, useEffect, useState} from 'react';
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/BreadCrumb";
import {Col, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import '../../../scss/vendor/nestable.scss'
import {ICourse} from "../../../interfaces/ICourse";
import CoursesContext from "../../../contexts/CoursesContext";
import {useLoading} from "../../../utils/useLoading";
import {useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {courses as coursesApi} from "../../../api/courses";
import CourseHeader from "./CourseHeader";
import BasicInformation from "./BasicInformation";
import LessonListing from "./LessonListing";
import MetaInfo from "./MetaInfo";
import {ILesson} from "../../../interfaces/ILesson";
import {lessons as lessonsApi} from "../../../api/lessons";
import Alert from "../../../components/Alert";
import CourseImage from "./CourseImage";
import CourseLogo from "./CourseLogo";
import {IModule} from "../../../interfaces/IModule";
import {modules} from "../../../api/modules";
import {isLesson} from "../../../utils/isLesson";
import CourseContext from "../../../contexts/CourseContext";
import Loader from "../../../components/Loader";
import ModuleContext from "../../../contexts/ModuleContext";
import StudentOverview from "./StudentOverview";
import {INewQuiz, IQuiz} from "../../../interfaces/IQuiz";
import {quizzes} from "../../../api/quizzes";

type CourseEditProps = {}

const CourseEdit = (props: CourseEditProps) => {
    const {t} = useTranslation()
    const {courseSlug} = useParams<{ courseSlug: string }>();
    const courses = useContext(CoursesContext)
    const [course, setCourse] = useState<ICourse | undefined>()
    const [description, setDescription] = useState<string>('')
    const [duration, setDuration] = useState<number>(0)
    const [priceInCents, setPriceInCents] = useState<number>(0)
    const [title, setTitle] = useState<string>('')
    const [type, setType] = useState<"public" | "private">('public')
    const [loading, startLoading, stopLoading] = useLoading()
    const queryClient = useQueryClient()
    const [error, setError] = useState<string | undefined>()
    const [imageUrl, setImageUrl] = useState('')
    const [logoUrl, setLogoUrl] = useState('')
    const [initialValuesSet, setInitialValuesSet] = useState(false)
    const [lessonOrderUpdated, setLessonOrderUpdated] = useState(false)
    const [moduleOrderUpdated, setModuleOrderUpdated] = useState(false)
    const [editModule, setEditModule] = useState<IModule|undefined>()

    useEffect(() => {
        if (course && !initialValuesSet) {
            setDescription(course.description)
            setTitle(course.title)
            setType(course.type || 'private')
            setDuration(course.duration || 0)
            setPriceInCents(course.price_in_cents || 0)
            setImageUrl(course.image_url || '')
            setLogoUrl(course.logo_url || '')
            setInitialValuesSet(true)
        }

        document.title = t('Edit Course') + ` ${course?.title || ''}`
        // eslint-disable-next-line
    }, [course])

    // find the course we want to edit
    useEffect(() => {
        const courseToEdit = courses?.find((course: ICourse) => course.slug === courseSlug)
        setCourse(courseToEdit)
    }, [courses, courseSlug])

    useEffect(() => {
        if (lessonOrderUpdated && moduleOrderUpdated) {
            queryClient.invalidateQueries('courses')
            setLessonOrderUpdated(false)
            setModuleOrderUpdated(false)
            toast.success(t('Order updated'))
        }
        // eslint-disable-next-line
    }, [lessonOrderUpdated, moduleOrderUpdated])

    const crumbs: IBreadCrumb[] = [
        {
            name: t('Home'),
            active: false,
            link: '/admin/dashboard'
        },
        {
            name: t('Courses'),
            active: false,
            link: '/admin/courses'
        },
        {
            name: course?.title || 'course',
            active: false,
            link: `/admin/courses/${courseSlug}/edit`
        },
    ]

    /**
     *
     * @param lessonsAndModules
     * @param lessonOrModule
     */
    const storeNewLessonOrder = (lessonsAndModules: (ILesson | IModule)[], lessonOrModule: (ILesson | IModule)) => {
        // first set the new sort_order
        interface ILessonsWithModuleId {
            moduleId: string | null,
            lessonsModules: (ILesson | IModule)[]
        }

        const emptyResult: ILessonsWithModuleId = {moduleId: null, lessonsModules: []}
        const updatedLessonsAndModules: ILessonsWithModuleId = lessonsAndModules.reduce((carry, lessonOrModule: (ILesson | IModule), index: number) => {
            lessonOrModule.sort_order = index + 1

            // add the module id to lessons
            if (isLesson(lessonOrModule)) {
                const lessonToBeUpdated: ILesson = lessonOrModule as ILesson
                lessonToBeUpdated.module_id = carry.moduleId
                carry.lessonsModules.push(lessonToBeUpdated)
                return carry
            }

            carry.lessonsModules.push(lessonOrModule)
            carry.moduleId = lessonOrModule.id || null

            return carry
        }, emptyResult)

        const updatedLessons: ILesson[] = updatedLessonsAndModules.lessonsModules
            .filter((lessonOrModuleToSearch) => isLesson(lessonOrModuleToSearch))
            .map((lesson) => lesson as ILesson)

        const updatedModules: IModule[] = updatedLessonsAndModules.lessonsModules
            .filter((lessonOrModuleToSearch) => !isLesson(lessonOrModuleToSearch))
            .map((module) => module as IModule)

        lessonsApi.updateMany(updatedLessons)
            .then((result) => {
                setLessonOrderUpdated(true)
            })
            .catch((error) => {
                // error.response
                toast.success(t('Error while updating lesson order'))
            })

        modules.updateMany(updatedModules)
            .then((result) => {
                setModuleOrderUpdated(true)
            })
            .catch((error) => {
                // error.response
                toast.success(t('Error while updating lesson order'))
            })
    }

    const saveCourse = () => {
        startLoading()

        const updatedCourse = Object.assign(
            {},
            course,
            {
                title: title,
                description: description,
                type: type,
                duration: duration,
                image_url: imageUrl,
                logo_url: logoUrl,
                price_in_cents: priceInCents,
            }
        )

        if (!updatedCourse.title) {
            setError(t('Please provide a title'))
            toast.error(t('You did not provide a title'))
            return
        }

        coursesApi.update(updatedCourse)
            .then((result) => {
                stopLoading()
                toast.success(t('Course updated'))
                queryClient.invalidateQueries('courses')
            })
            .catch((error) => {
                stopLoading()
                console.error(error.response)
                toast.error(error.response?.data?.errors?.detail)
            })
    }

    const addQuiz = () => {
        startLoading()

        const newQuiz: INewQuiz = {
            name: t('new quiz'),
            course_id: course?.id || '',
            sort_order: 0,
        }

        quizzes.add(newQuiz)
            .then((quiz: IQuiz) => {
                stopLoading()
                // setEditQuiz(quiz)
                queryClient.invalidateQueries('courses')
            })
            .catch((error) => {
                stopLoading()
                console.error(error)
            })
    }

    const addModule = () => {
        startLoading()

        if (!course) {
            toast.error('Cannot add module when no course is selected')
            return
        }

        const newModule: IModule = {
            title: t('New module'),
            sort_order: 0,
            course_id: course.id,
            lessons: [],
        }

        modules.add(newModule)
            .then((module: IModule) => {
                stopLoading()
                setEditModule(module)
                queryClient.invalidateQueries('courses')
            })
            .catch((error) => {
                stopLoading()
                console.error(error)
            })
    }

    if (!course) {
        return <Loader/>
    }

    return (
        <div className="container-fluid page__container">
            <CourseContext.Provider value={course}>
                <BreadCrumb crumbs={crumbs}/>

                {error && (
                    <Alert message={error}/>
                )}

                <CourseHeader
                    loading={loading}
                    saveCourse={saveCourse}
                    course={course}
                    title={t('Edit course')}
                />

                <Row>
                    <Col md={8}>
                        <BasicInformation
                            title={title}
                            setTitle={setTitle}
                            type={type}
                            setType={setType}
                            description={description}
                            setDescription={setDescription}
                            saveCourse={saveCourse}
                        />

                        <ModuleContext.Provider value={editModule}>
                            <LessonListing
                                course={course}
                                courseSlug={courseSlug}
                                addModule={addModule}
                                addQuiz={addQuiz}
                                storeNewLessonOrder={storeNewLessonOrder}
                            />
                        </ModuleContext.Provider>
                    </Col>

                    <Col md={4}>
                        {course && (
                            <>
                                <CourseImage
                                    imageUrl={imageUrl}
                                    setImageUrl={setImageUrl}
                                />

                                <CourseLogo
                                    logoUrl={logoUrl}
                                    setLogoUrl={setLogoUrl}
                                />
                            </>
                        )}

                        <MetaInfo
                            priceInCents={priceInCents}
                            setPriceInCents={setPriceInCents}
                            saveCourse={saveCourse}
                        />

                        <StudentOverview />

                    </Col>
                </Row>
            </CourseContext.Provider>
        </div>
    )
};

export default CourseEdit;