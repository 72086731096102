import React from 'react';
import {useQuery} from "react-query";
import {subscriptions} from "../../../api/subscriptions";
import DataTable from "react-data-table-component";
import persistentStore from "../../../stores/persistentStore";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {ISubscription} from "../../../interfaces/ISubscription";
import uiStore from "../../../stores/uiStore";

type SubscriptionTableProps = {
    userId: string,
}

const SubscriptionsTable = (props: SubscriptionTableProps) => {
    const {userId} = props
    const {t} = useTranslation()
    const subscriptionQuery = useQuery('subscriptions', () => subscriptions.ofUser(userId))

    const columns = [
        {
            name: t('Date'),
            selector: (subscription: ISubscription) => subscription.created || '',
            sortable: true,
            // width: '200px',
            cell: (subscription: ISubscription) => moment(subscription.created).format('Y-DD-MM H:mm')
        },
        {
            name: t('Type'),
            selector: (subscription: ISubscription) => subscription.type,
            sortable: true,
        },
        {
            name: t('Amount'),
            selector: (subscription: ISubscription) => (subscription.paid_amount_in_cents / 100).toLocaleString(uiStore.locale, uiStore.moneyOptions),
            sortable: true,
        },
        {
            name: t('Account valid until'),
            selector: (subscription: ISubscription) => subscription.grants_access_until,
            cell: (subscription: ISubscription) => {
                const today = moment()
                const expire = moment(subscription.grants_access_until)
                const diff = expire.diff(today, 'days')
                return `${diff} ${t('days')}`
            }
        },
        {
            name: t('Access Until'),
            selector: (subscription: ISubscription) => subscription.grants_access_until,
            cell: (subscription: ISubscription) => moment(subscription.grants_access_until).format('Y-DD-MM H:mm')
        },
    ]

    return (
        <DataTable
            className=''
            columns={columns}
            data={subscriptionQuery.data || []}
            pagination
            paginationPerPage={persistentStore.paginationLength}
            paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
            onChangeRowsPerPage={(rowCount) => persistentStore.setPaginationLength(rowCount)}
            noHeader={true}
            defaultSortFieldId={'sent_date'}
            defaultSortAsc={false}
            pointerOnHover={true}
            noDataComponent={<div><p className='mt-4'>{t('No subscriptions linked to this user')}</p></div>}
        />
    )
};

export default SubscriptionsTable;