import {IQuestion} from "../interfaces/IQuestion";
import persistentStore from "../stores/persistentStore";
import axios from "axios";
import errorChecker from "./errorchecker";

const quizQuestionsRequests = () => {

    const getAll = async () => {
        const path = `/quiz-questions/`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.quizzes) {
                console.log('The response did not contain quizzes');
                throw Error('The response did not contain quizzes');
            }

            return data.quizzes;
        });
    }

    const getByQuiz = async (quizSlug: string) => {
        const path = `/quiz-questions/get-by-quiz/{quizSlug}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.quiz_questions) {
                console.log('The response did not contain questions');
                throw Error('The response did not contain questions');
            }

            return data.quiz_questions;
        });
    }

    const update = async (question: IQuestion) => {
        const path = `/quiz-questions/${question.id}`;
        const config = persistentStore.authHeader;

        return axios.put(persistentStore.apiUrl + path, question, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.quiz_question) {
                console.log('The response did not contain questions');
                throw Error('The response did not contain questions');
            }

            return data.quiz_questions;
        });
    }

    const add = async (question: IQuestion) => {
        const path = `/quiz-questions/`;
        const config = persistentStore.authHeader;

        return axios.post(persistentStore.apiUrl + path, question, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.quiz_question) {
                console.log('The response did not contain question');
                throw Error('The response did not contain question');
            }

            return data.quiz_question;
        });
    }

    const get = async (quizId: string) => {
        const path = `/quiz-questions/${quizId}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.quiz_question) {
                    console.log('The response did not contain quiz question');
                    throw Error('The response did not contain quiz question');
                }

                return data.quiz_question;
            });
    }

    const del = async (question: IQuestion) => {
        const path = `/quiz-questions/${question?.id}`;
        const config = persistentStore.authHeader;

        return axios.delete(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.quiz_question) {
                    console.log('The response did not contain quiz question');
                    throw Error('The response did not contain quiz question');
                }

                return data.quiz_question;
            });
    }

    const updateMany = async (question: (IQuestion)[]) => {
        const path = `/quiz-questions/update-many`;
        const config = persistentStore.authHeader;

        return axios.put(persistentStore.apiUrl + path, question, config)
            .then(
                response => errorChecker(response)).then(response => {
                const data = response.data.data;
                if (!data.questions) {
                    console.log('The response did not contain questions')
                    throw Error('The response did not contain questions')
                }

                return data.lessons;
            });
    }

    return {
        getAll,
        getByQuiz,
        update,
        add,
        get,
        del,
        updateMany
    }
}

export const quizQuestions = quizQuestionsRequests()
