import {PDFDownloadLink} from '@react-pdf/renderer'
import InvoicePDF from './InvoicePDF'
import './InvoicePage.scss'
import React, {useEffect, useState} from "react";
import {invoice as invoiceApi} from '../../api/invoice'
import {Col, Button, Row, Image} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import persistentStore from "../../stores/persistentStore";
import {IInvoice} from "../../interfaces/IInvoice";
import {useQuery} from "react-query";
import InvoiceView from "./InvoiceView";
import {useParams} from "react-router-dom";

const InvoicePage = () => {
    const {t} = useTranslation()
    const {public_id: publicId} = useParams<{public_id: string}>()
    const invoiceQuery = useQuery('invoice', () => invoiceApi.get(publicId))
    const [invoice, setInvoice] = useState<IInvoice>()

    useEffect(() => {
        if (!invoiceQuery.data) {
            return
        }

        setInvoice(invoiceQuery.data)
    }, [invoiceQuery.data])

    const InvoiceLogo = () => {
        return persistentStore.user?.logo
            ? <Image
                src={`${persistentStore.apiUrl}/img/logo/${persistentStore.user?.logo}`}
                alt='logo'
                className='avatar'
            />
            : persistentStore.user?.logo
    }

    return (
        <div id='invoice-details'>
            <Row className='col-md-12 p-0'>
                <Col className="text-right mb-2 mt-2">

                    {invoice && (
                        <PDFDownloadLink
                            document={<InvoicePDF invoice={invoice}/>}
                            fileName={`factuur-${publicId}`}
                        >
                            <Button variant="primary" >
                                {t('Download PDF')}
                            </Button>
                        </PDFDownloadLink>
                    )}
                </Col>
            </Row>

            {invoice && (
                <InvoiceView logo={InvoiceLogo} invoice={invoice}/>
            )}
        </div>
    )
}

export default InvoicePage;
