import React from 'react';
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import {IStatement} from "../../../interfaces/IStatement";
import {useTranslation} from "react-i18next";
import uiStore from "../../../stores/uiStore";

type TransactionsProps = {
    statements: IStatement[]
}

const Transactions = (props: TransactionsProps) => {
    const {statements} = props
    const {t} = useTranslation()

    console.log(statements)

    return (
        <Card className="card">
            <Card.Header className="card-header d-flex align-items-center">
                <div className="flex">
                    <h4 className="card-title">{t('Transactions')}</h4>
                    <p className="card-subtitle">{t('Latest Transactions')}</p>
                </div>
            </Card.Header>

            <Card.Body>
                <div
                    data-toggle="lists"
                    data-lists-values='["js-lists-values-course", "js-lists-values-document", "js-lists-values-amount", "js-lists-values-date" ]'
                    data-lists-sort-by="js-lists-values-date"
                    data-lists-sort-desc="true"
                    className="table-responsive"
                >
                    <table className="table table-nowrap m-0">
                        <tbody className="list">
                        {(statements || []).map((statement: IStatement, index: number) => (
                            <tr key={index}>
                                <td>
                                    <div className="media align-items-center">
                                        <Link to="#" className="avatar avatar-4by3 avatar-sm mr-3">
                                            <img src={statement.image_url} alt="course"
                                                 className="avatar-img rounded"/>
                                        </Link>
                                        <div className="media-body">
                                            <Link
                                                className="text-body js-lists-values-course"
                                                to={`/admin/courses/${statement.course_slug}/edit`}
                                            >
                                                <strong>{statement.course_title}</strong>
                                            </Link>

                                            <br/>

                                            <small className="text-muted mr-1">
                                                {t('Invoice')}
                                                <Link
                                                    to={`/admin/invoices/${statement.document}`}
                                                    style={{color: "inherit"}}
                                                    className="js-lists-values-document ml-1"
                                                >
                                                    {statement.document}
                                                </Link> -
                                                <span className="js-lists-values-amount">
                                                    {(statement.amount / 100).toLocaleString(uiStore.locale, uiStore.moneyOptions)}
                                                </span>
                                            </small>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-right">
                                    <small className="text-muted text-uppercase js-lists-values-date"/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </Card.Body>
        </Card>
    )
};

export default Transactions;