import React, {useState} from 'react';
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/BreadCrumb";
import {Col, Row} from "react-bootstrap";
import '../../../scss/vendor/nestable.scss'
import {quizzes} from "../../../api/quizzes";
import {useLoading} from "../../../utils/useLoading";
import {toast} from "react-toastify";
import Alert from "../../../components/Alert";
import QuizHeader from "./QuizHeader";
import BasicInformation from "./BasicInformation";
import {INewQuiz} from "../../../interfaces/IQuiz";

type QuizAddProps = {}

const QuizAdd = (props: QuizAddProps) => {
    const {t} = useTranslation()
    const [title, setTitle] = useState<string>('')
    const [courseId, setCourseId] = useState<string>('')
    const [loading, startLoading, stopLoading] = useLoading()
    const [error, setError] = useState<string | undefined>()
    const [imageUrl, setImageUrl] = useState('')

    const crumbs: IBreadCrumb[] = [
        {
            name: t('Home'),
            active: false,
            link: '/admin/dashboard'
        },
        {
            name: t('Quizzes'),
            active: false,
            link: '/admin/quiz-manager'
        },
        {
            name: t('Add'),
            active: true,
            link: '/admin/quizzes/add'
        },
    ]

    const saveQuiz = () => {
        startLoading()

        if (title === '') {
            setError(t('Please provide a name for the quiz'))
            toast.error(t('You did not provide a name'))
            return
        }

        const newQuiz: INewQuiz = Object.assign(
            {},
            {
                id: '',
                name: title,
                image_url: imageUrl,
                course_id: courseId,
                sort_order: 0,
            }
        )

        quizzes.add(newQuiz)
            .then((quiz) => {
                stopLoading()
                toast.success(t('Quiz created'))
                //queryClient.invalidateQueries('courses')

                //history.push(`/admin/quizzes/${quiz.slug}/edit`)
            })
            .catch((error) => {
                stopLoading()
                toast.error(t('Error while creating quiz'))
            })
    }

    return (
        <div className="container-fluid page__container">
            <BreadCrumb crumbs={crumbs}/>


            {error && (
                <Alert message={error} type='danger'/>
            )}

            <QuizHeader
                loading={loading}
                saveQuiz={saveQuiz}
                title={t('Add Quiz')}
            />

            <Row>
                <Col md={12}>
                    <BasicInformation
                        title={title}
                        setTitle={setTitle}
                        courseId={courseId}
                        setCourseId={setCourseId}
                        imageUrl={imageUrl}
                        setImageUrl={setImageUrl}
                        saveQuiz={saveQuiz}
                    />
                </Col>
            </Row>
        </div>
    )
};

export default QuizAdd;