import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import Content from "./Content/Content";
import './Template.scss'
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {useHistory, useParams} from "react-router-dom";
import {courses} from "../../api/courses";
import {ICourse} from "../../interfaces/ICourse";
import CourseContext from "../../contexts/CourseContext";
import {observer} from "mobx-react";
import {lessons as lessonsApi} from "../../api/lessons";
import UserLessonContext from "../../contexts/UserLessonContext";
import LessonsContext from "../../contexts/LessonsContext";
import {IUserLesson} from "../../interfaces/IUserLesson";
import Loader from "../../components/Loader";
import persistentStore from "../../stores/persistentStore";
import PreviewContext from "../../contexts/PreviewContext";

type TemplateProps = {}

const Preview = (props: TemplateProps) => {
    const {courseSlug, lessonSlug} = useParams<{ courseSlug: string, lessonSlug: string }>()
    const [course, setCourse] = useState<ICourse | undefined>(undefined)
    const [lessons, setLessons] = useState<IUserLesson[]>([])
    const [lesson, setLesson] = useState<IUserLesson | undefined>()
    const [isPreview, setIsPreview] = useState<boolean | undefined>(undefined)
    const history = useHistory()

    const courseQuery = useQuery(
        ['course', courseSlug],
        () => courses.getPreview(courseSlug),
    )

    const courseHasPreviewLessonsQuery = useQuery(
        ['courseHasPreviewLessons', courseSlug],
        () => courses.hasPreviewLessons(courseSlug),
        {
            enabled: courseSlug !== undefined,
        }
    )

    // get the titles of all lessons
    const lessonsQuery = useQuery(
        ['lessons', courseSlug],
        () => {
            if (courseSlug) {
                return lessonsApi.getPreviewLessonsByCourseSlug(courseSlug)
            }
        },
    )

    useEffect(() => {
        if (courseQuery.data) {
            setCourse(courseQuery.data)
        }
    }, [courseQuery.data])

    useEffect(() => {
        if (courseHasPreviewLessonsQuery.data === true) {
            setIsPreview(true)
        } else if (courseHasPreviewLessonsQuery.data === false) {
            setIsPreview(false)
        }
    }, [courseHasPreviewLessonsQuery])

    // you need to login when this is not a preview session
    useEffect(() => {
        if (isPreview === undefined) {
            return
        } else if (isPreview === false) {
            console.log('naar login')
            return history.push(`/course/${courseSlug}/login`)
        }

    }, [courseSlug, courseHasPreviewLessonsQuery, history, isPreview])

    useEffect(() => {
        if (lessonsQuery.data) {
            setLessons(lessonsQuery.data)
        }
    }, [lessonsQuery])

    // fetch the current lesson from all lessons in the course
    useEffect(() => {
        // do nothing when loading is not done
        if (!lessonsQuery.data) {
            return
        }

        if (lessons && lessonSlug) {
            const currentLesson: IUserLesson | undefined = lessons.find((courseLesson: IUserLesson) => courseLesson.slug === lessonSlug)

            // if currentLesson is a non-preview lesson, something fishy is going on. In Preview.tsx, we can only
            // watch preview lessons
            if(currentLesson?.preview === false){
                alert('Boefje!')
                // go to the first preview lesson
                pushToFirstPreviewLesson()
            }

            if (currentLesson) {
                currentLesson.courseSlug = courseSlug
                setLesson(currentLesson)

                // ensure the module this lesson is in, is opened
                if (currentLesson.module_id) {
                    persistentStore.openModule(currentLesson.module_id)
                }
            } else {
                console.log('Wow! Ik heb geen current lesson!')
            }
        } else if (lessons && lessons.length > 0) {
            pushToFirstPreviewLesson()
        }
        // eslint-disable-next-line
    }, [lessons, lessonSlug, course, lesson?.title, lessonsQuery.data])

    const pushToFirstPreviewLesson = () => {
        // get the first lesson
        const firstPreviewLesson: IUserLesson | undefined = getFirstPreviewLesson()
        console.log('Sending you to the first lesson')
        console.log(firstPreviewLesson)
        if(firstPreviewLesson){
            history.push(`/course-preview/${courseSlug}/${firstPreviewLesson.slug}`)
        }
        else {
            console.log('There are no preview lessons. Go to the enroll page')
            history.push(`/course-preview/${courseSlug}/enroll`)
        }
    }

    const getFirstPreviewLesson = () => {
        return lessons.find((courseLesson: IUserLesson) => courseLesson.preview === true)
    }

    if (!course || isPreview === undefined) {
        return <Loader/>
    }

    return (
        <PreviewContext.Provider value={isPreview}>
            <CourseContext.Provider value={course}>
                <LessonsContext.Provider value={lessons}>
                    <UserLessonContext.Provider value={lesson}>
                        <div className='template'>
                            <Header logo={course?.logo_url}/>

                            <main>
                                <Sidebar completedQuizzesIds={[]} withoutQuizzes={true}/>
                                <Content/>
                            </main>
                        </div>
                    </UserLessonContext.Provider>
                </LessonsContext.Provider>
            </CourseContext.Provider>
        </PreviewContext.Provider>
    )
};

export default observer(Preview)