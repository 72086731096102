import {makeAutoObservable} from "mobx";

class applicationStore {
    showSidebar: boolean = true

    // when we set this as a fixed lesson, then it will not take any updates that are persisted via
    // this.modules[x].lessons
    // therefore we only store the id and fetch the latest status from this.modules
    selectedLessonId: number | undefined = undefined

    locale: string = 'nl-NL'
    // for options, see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
    localeOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
    }
    moneyOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
    }
    moneyNoSymbol = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'decimal',
    }
    moneyNoSymbolNoDecimals = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'decimal',
        currency: 'EUR',
    }
    moneyNoDecimals = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
    }
    fixedNumberOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'decimal',
    }
    percentageOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'percent',
    }

    constructor() {
        makeAutoObservable(this)
    }

    emptyStore() {
        this.showSidebar = true
    }

    setShowSidebar(show: boolean) {
        this.showSidebar = show
    }

    toggleSidebar() {
        this.showSidebar = !this.showSidebar
    }
}

const uiStore = new applicationStore()
export default uiStore
