import {makeAutoObservable} from "mobx";

class applicationStore {
    courseImageSizeLimit: number = 3
    quizImageSizeLimit: number = 3
    selectedLessonId: string | undefined = undefined
    selectedQuizId: number | undefined = undefined
    deviceType: string = ''
    isLoading: boolean = false

    startLoading() {
        this.isLoading = true
    }

    stopLoading() {
        this.isLoading = false
    }

    isMobile(){
        return this.deviceType === 'mobile'
    }

    constructor() {
        makeAutoObservable(this)
    }

    emptyStore() {
        // this.modules = []
    }

    setDeviceType(type: string){
        this.deviceType = type
    }

    setSelectedLessonId(lessonId: string) {
        this.selectedLessonId = lessonId
    }

    setSelectedQuizId(QuizId: number) {
        this.selectedQuizId = QuizId
    }
}

const store = new applicationStore()
export default store
