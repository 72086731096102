import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useLoading} from "../../../utils/useLoading";
import {IQuiz} from "../../../interfaces/IQuiz";
import {Link} from "react-router-dom";
import {Card, Button} from "react-bootstrap";
import persistentStore from "../../../stores/persistentStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import {faCheckSquare} from '@fortawesome/free-solid-svg-icons';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import emptyQuizImage from "../../../assets/img/empty_video.jpg";
import swal from "sweetalert";
import {toast} from "react-toastify";
import {quizzes as quizzesApi} from '../../../api/quizzes'
import {useQueryClient} from "react-query";

type QuizTileProps = {
    quiz: IQuiz
}

const QuizTile = (props: QuizTileProps) => {
    const {quiz} = props
    const editLink = `/admin/quizzes/${props.quiz.slug}/edit`
    const reviewLink = `/admin/quizzes/${props.quiz.slug}/review`
    const [showOptions, setShowOptions] = useState(false)
    const [loading, startLoading, stopLoading] = useLoading()
    const queryClient = useQueryClient()
    const {t} = useTranslation()

    const toggleShowOptions = () => {
        setShowOptions(!showOptions)
    }

    const closeSubMenu = (event: MouseEvent) => {
        const container = document.getElementById(`quiz-submenu-${quiz.slug}`);
        const toggleElement = document.getElementById(`quiz-toggle-submenu-${quiz.slug}`);
        const clickedElement = event.target as Node
        const clickedToggleElement = toggleElement?.contains(clickedElement)
        const clickedOutside = !container?.contains(clickedElement) && !clickedToggleElement

        if (clickedOutside) {
            // hide the submenu
            setShowOptions(false)
        }
    }

    const deleteCourse = () => {
        startLoading()
        if (!props.quiz.slug) {
            toast.error(t('No slug known for this quiz'))
            return
        }

        quizzesApi.del(props.quiz.slug)
            .then((result) => {
                stopLoading()
                toast.success(t('Quiz removed'))

                // invalidate quiz query
                queryClient.invalidateQueries('quizzes')
            })
            .catch((error) => {
                stopLoading()
                toast.error(t('Error while deleting quiz'))
            })
    }

    const showCourseDeleteMessage = () => {
        swal({
            title: t('Are you sure you want to delete the quiz?'),
            text: t('This cannot be undone'),
            buttons: {
                cancel: {
                    text: t("Cancel"),
                    value: 'cancel',
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: t("OK"),
                    value: 'confirm',
                    visible: true,
                    className: "",
                }
            }
        })
            .then((result) => {
                if (result === 'confirm') {
                    deleteCourse()
                }
            })
    }

    useEffect(() => {
        if (showOptions) {
            // add event listener which listens for a click outside of the opened element
            window.addEventListener('mouseup', closeSubMenu);
        } else {
            // remove event listener
            document.removeEventListener('mouseup', closeSubMenu)
        }

        return document.removeEventListener('mouseup', closeSubMenu)
        // disable eslint as it wants closeSubmenu() inside the dependencies
        // eslint-disable-next-line
    }, [showOptions])

    return (
        <Card className="card-sm">
            <Card.Body className="media">
                <div className="media-left">
                    <Link
                        to={editLink}
                        className="avatar avatar-lg avatar-4by3"
                    >
                        {props.quiz.image_url && (
                            <img
                                src={persistentStore.apiUrl + props.quiz.image_url}
                                alt="quiz poster"
                                className="avatar-img rounded"
                            />
                        )}
                        {!props.quiz.image_url && (
                            <img src={emptyQuizImage} alt="quiz poster" className="avatar-img rounded"/>
                        )}
                    </Link>
                </div>
                <div className="media-body">
                    <h4 className="card-title mb-0">
                        <Link
                            to={editLink}
                        >
                            {props.quiz.name}
                        </Link>
                    </h4>
                    {/* <small className="text-muted">{props.quiz.completed} Completed</small> */}
                </div>
            </Card.Body>
            <div className="card__options dropdown right-0 pr-2">
                <Button
                    id={`quiz-toggle-submenu-${props.quiz.slug}`}
                    variant='link'
                    onClick={toggleShowOptions}
                    className="dropdown-toggle text-muted"
                    data-caret="false"
                    data-toggle="dropdown"
                >
                    <FontAwesomeIcon icon={faEllipsisV} className='material-icons'/>
                </Button>
                <div
                    id={`quiz-submenu-${props.quiz.slug}`}
                    className={`dropdown-menu dropdown-menu-right ${showOptions && 'show'}`}
                >
                    <Link
                        target="_blank"
                        className="dropdown-item"
                        to={`/quizzes/${props.quiz.slug}`}
                    >
                        {t('View quiz')}
                    </Link>
                    <Link
                        className="dropdown-item"
                        // to={`/admin/courses/${quiz.slug}/edit`}
                        to={`/admin/quiz/...../edit`}
                    >
                        {t('Edit quiz')}
                    </Link>

                    <div className="dropdown-divider"/>

                    <Button
                        className="dropdown-item text-danger btn__icon--left"
                        onClick={showCourseDeleteMessage}
                        variant='link'
                    >
                        {loading && t('Deleting quiz') + '...'}
                        {!loading && t('Delete quiz')}
                    </Button>
                </div>
            </div>
            <Card.Footer>
                <Link
                    to={reviewLink}
                    className="btn btn-white btn-sm float-left"
                >
                    <FontAwesomeIcon icon={faCheckSquare} className="material-icons btn__icon--left"/>
                    {t('Review')}
                    <span
                        className="badge badge-dark ml-2"
                        style={{
                            backgroundColor: "#39444d",
                            borderRadius: ".25rem",
                            padding: "0.50em",
                            width: "1.5em",
                            lineHeight: "0.60em"
                        }}
                    >
                        5
                    </span>
                </Link>
                <Link
                    to={editLink}
                    className="btn btn-default btn-sm float-right"
                >
                    <FontAwesomeIcon icon={faPencilAlt} className="material-icons btn__icon--left"/>
                    {t('Edit')}
                </Link>
                <div className="clearfix"></div>
            </Card.Footer>
        </Card>
    )
};

export default QuizTile;