import React, {useEffect, useState} from 'react';
import BreadCrumb from "../../../components/BreadCrumb";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import {useTranslation} from "react-i18next";
import {Card} from "react-bootstrap";
import {IEvent} from "../../../interfaces/IEvent";
import {useQuery} from "react-query";
import {events as eventsApi} from "../../../api/events";
import EventTable from "./EventTable";

type EventsPageProps = {}

const EventsPage = (props: EventsPageProps) => {
    const [events, setEvents] = useState<IEvent[]>([])
    const {t} = useTranslation()

    const eventQuery = useQuery(
        'events',
        () => eventsApi.get(),
    )

    useEffect(() => {
        if(eventQuery.data){
            setEvents(eventQuery.data)
        }
    }, [eventQuery.data])


    const crumbs: IBreadCrumb[] = [
        {
            name: t('Dashboard'),
            active: false,
            link: '/admin/dashboard'
        }
    ]

    return (
        <div className="ml-3 mr-3">
            <BreadCrumb crumbs={crumbs}/>

            <h1 className="h2">{t('Events')}</h1>

            <Card>
                <Card.Body>
                    <EventTable events={events} isLoading={eventQuery.isLoading} />
                </Card.Body>
            </Card>
        </div>
    )
};

export default EventsPage;