import React, {useContext, useEffect, useState} from 'react';
import './Modules.scss'
import {observer} from "mobx-react";
import store from "../../../stores/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IUserLesson} from "../../../interfaces/IUserLesson";
import {Link, useParams} from "react-router-dom";
import {faAdjust} from "@fortawesome/free-solid-svg-icons/faAdjust";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import history from "../../../utils/history";
import LessonsContext from "../../../contexts/LessonsContext";
import CourseContext from "../../../contexts/CourseContext";
import {ICourse} from "../../../interfaces/ICourse";
import {IModule} from "../../../interfaces/IModule";
import persistentStore from "../../../stores/persistentStore";
import {faCaretUp} from "@fortawesome/free-solid-svg-icons/faCaretUp";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons/faCaretDown";
import {faCircle} from "@fortawesome/free-regular-svg-icons/faCircle";
import {faCircle as fasCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";

type ModulesProps = {
    className?: string,
}

const Modules = (props: ModulesProps) => {
    const {courseSlug, lessonSlug} = useParams<{ courseSlug: string, lessonSlug?: string }>()
    const course: ICourse = useContext(CourseContext)
    const [modules, setModules] = useState<IModule[]>(course.modules || [])
    const lessons: IUserLesson[] = useContext<IUserLesson[]>(LessonsContext)
    const [lessonsWithoutModules, setLessonsWithoutModules] = useState<IUserLesson[]>([])
    const isLoggedIn = persistentStore.loggedIn

    // sort modules
    useEffect(() => {
        const lessonsWithoutModule = lessons.filter((lesson: IUserLesson) => lesson.module_id === null)
        setLessonsWithoutModules(lessonsWithoutModule || [])

        const sortedModules = modules.sort((a: IModule, b: IModule) => (
            (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0)
        ))

        setModules(sortedModules)
    }, [modules, lessons])

    const selectLesson = (lesson: IUserLesson) => {
        // persistentStore.setSelectedLessonId(lesson.id)
        if (lesson.id && lesson.slug) {
            store.setSelectedLessonId(lesson.id)
            history.push(lesson.slug)
        }
    }

    const isExpanded = (module: IModule) => module.id && persistentStore.openModules.includes(module.id)

    // if all modules are closed (= default), open the first one so the user sees the lessons of the first module
    const toggleModule = (module: IModule) => {
        if (!module.id) {
            return
        }

        persistentStore.toggleModule(module.id)
    }

    const getLessonsByModule = (lessons: IUserLesson[], moduleId: string): IUserLesson[] => {
        const moduleLessons: IUserLesson[] | undefined = lessons.filter((lesson) => lesson.module_id === moduleId)

        if (!moduleLessons) {
            return []
        }

        return moduleLessons
    }

    return (
        <ul className={`modules ${props.className}`}>
            {course.type === 'public' && (
                lessonsWithoutModules.length > 0 && (
                    <li className='lessons'>
                        {lessonsWithoutModules.map((lesson: IUserLesson, index: number) => (
                            <div
                                key={index}
                                className={`lesson ${lessonSlug === lesson.slug ? 'active' : ''}`}
                            >
                                <Link
                                    to={`/course/${courseSlug}/${lesson.slug}`}
                                    onClick={() => selectLesson(lesson)}
                                >
                                    {(lesson.last_position === 0 && ! lesson.completed) && (
                                        <FontAwesomeIcon className='started mt-1' icon={faCircle}/>
                                    )}
                                    {(lesson.last_position > 0) && !lesson.completed && (
                                        <FontAwesomeIcon className='started' icon={faAdjust}/>
                                    )}
                                    {lesson.completed && <FontAwesomeIcon className='started mt-1' icon={fasCircle}/>}
                                    <div>{lesson.title}</div>
                                    <FontAwesomeIcon icon={faAngleRight}/>
                                </Link>
                            </div>
                        ))}
                    </li>
                )
            )}

            {course.type === 'private' && (
                lessonsWithoutModules.length > 0 && (
                    <li className='lessons'>
                        {lessonsWithoutModules.map((lesson: IUserLesson, index: number) => (
                            <div
                                key={index}
                                className={`lesson ${lessonSlug === lesson.slug ? 'active' : ''}`}
                            >
                                {(lesson.preview || isLoggedIn) && (

                                    <Link
                                        to={`/course${(lesson.preview && ! isLoggedIn) ? '-preview' : ''}/${courseSlug}/${lesson.slug}`}
                                        onClick={() => selectLesson(lesson)}
                                    >
                                        {(lesson.last_position === 0 && ! lesson.completed) && (
                                            <FontAwesomeIcon className='started mt-1' icon={faCircle}/>
                                        )}
                                        {(lesson.last_position > 0) && !lesson.completed && (
                                            <FontAwesomeIcon className='started' icon={faAdjust}/>
                                        )}
                                        {lesson.completed && <FontAwesomeIcon className='started mt-1' icon={fasCircle}/>}
                                        <div>{lesson.title}</div>
                                        <FontAwesomeIcon icon={faAngleRight}/>
                                    </Link>
                                )}

                                {(! isLoggedIn && lesson.preview === false) && (
                                    <Link
                                        to="#"
                                        className="not-accessible"
                                    >
                                        <FontAwesomeIcon className='started mt-1' icon={faLock}/>
                                        <div>{lesson.title}</div>
                                    </Link>
                                )}
                            </div>
                        ))}
                    </li>
                )
            )}

            {modules.map((module: IModule, index: number) => (
                <div key={index} className='mt-3'>
                    <li key={index} className='moduleTitle' onClick={() => toggleModule(module)}>
                        <div>{module.title}</div>
                        {!isExpanded(module) && <FontAwesomeIcon icon={faCaretUp}/>}
                        {isExpanded(module) && <FontAwesomeIcon icon={faCaretDown}/>}
                    </li>
                    {isExpanded(module) && module.id && (
                        <li className='lessons'>
                            {getLessonsByModule(lessons, module.id).map((lesson: IUserLesson, index: number) => (
                                <div
                                    key={index}
                                    className={`lesson ${lessonSlug === lesson.slug ? 'active' : ''}`}
                                >
                                    <Link
                                        to={`/course/${courseSlug}/${lesson.slug}`}
                                        onClick={() => selectLesson(lesson)}
                                    >
                                        {(lesson.last_position === 0 && ! lesson.completed) && (
                                            <FontAwesomeIcon className='started mt-1' icon={faCircle}/>
                                        )}
                                        {(lesson.last_position > 0) && !lesson.completed && (
                                            <FontAwesomeIcon className='started mt-1' icon={faAdjust}/>
                                        )}
                                        {lesson.completed && <FontAwesomeIcon className='started mt-1' icon={fasCircle}/>}
                                        <div>{lesson.title}</div>
                                        <FontAwesomeIcon icon={faAngleRight}/>
                                    </Link>
                                </div>
                            ))}
                        </li>
                    )}
                </div>
            ))}
        </ul>
    )
}

export default observer(Modules);