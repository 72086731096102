import React from 'react';
import './SidebarPreview.scss'
import persistentStore from "../../../stores/persistentStore";

type SidebarPreviewProps = {
    img: string
}

const SidebarPreview = (props: SidebarPreviewProps) => {

    return (
        <div className='sidebar-preview'>
            {props.img && (
                <img src={persistentStore.apiUrl + props.img} alt='' />
            )}
        </div>
    )
};

export default SidebarPreview;