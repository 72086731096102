import {IQuiz} from "../interfaces/IQuiz";
import React, {ChangeEvent, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {Button} from "react-bootstrap";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";

type QuizListItemProps = {
    quiz: IQuiz
}
const QuizListItem = (props: QuizListItemProps) => {
    const {quiz} = props
    // const {t} = useTranslation()
    // const course = useContext(CourseContext)
    const [name, setName] = useState(quiz.name)
    // const queryClient = useQueryClient()
    const [edit, setEdit] = useState(false)

    const deleteQuiz = (id: number) => {
    //     // console.log(id)
    //     // console.log(course.lessons)
    //     // check whether this quiz has lessons
    //     const lessonsInThisQuiz: ILesson[] = course.lessons.filter((lesson) => lesson.quiz_id === id)
    //
    //     if(lessonsInThisQuiz.length > 0){
    //         const lessonsInQuiz = lessonsInThisQuiz
    //             .map((lesson) => lesson.name)
    //             .join(', ')
    //         alert(`Note: the lessons in this quiz will be deleted as well: ${lessonsInQuiz}. Please move them out of the quiz first and try again.`)
    //
    //         return
    //     }
    //
    //     quizs.remove(id)
    //         .then((result) => {
    //             queryClient.invalidateQueries('courses')
    //             toast.success(t('Quiz removed'))
    //         })
    //         .catch((error) => {
    //             console.error(error.response)
    //             toast.error(t('Quiz could not be removed'))
    //         })
    }

    const selectAll = (event: any) => {
        event.target.select()
    }

    // const shouldSave = (event: any) => {
    //     if(event.code === 'Enter'){
    //         event.preventDefault()
    //         saveQuiz()
    //     }
    // }

    const saveQuiz = () => {
    //
    //     const updatedQuiz: IQuiz = Object.assign(
    //         quiz,
    //         {name: name}
    //     )
    //
    //     quizs.update(updatedQuiz)
    //         .then((result) => {
    //             queryClient.invalidateQueries('courses')
    //             setEdit(false)
    //         })
    //         .catch((error) => {
    //             console.error(error)
    //         })
    }

    const updateTitle = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    return <div className="nestable-item nestable-item-handle">
        <div className="nestable-handle">
            <FontAwesomeIcon icon={faBars} className="material-icons"/>
        </div>
        <div className="nestable-content">
            <div className="media align-items-center">
                <div className="media-left"/>
                <div className="media-body">
                    {!edit && (
                        <h5 className="card-title h6 mb-0">
                            Quiz: {quiz.name}
                        </h5>
                    )}

                    {edit && (
                        <input
                            type='text'
                            value={name}
                            onChange={updateTitle}
                            autoFocus={true}
                            onFocus={selectAll}
                            // onKeyUp={shouldSave}
                            onBlur={() => setEdit(false)}
                        />
                    )}
                </div>
                <div className="media-right">
                    {! edit && (
                        <Button variant={"link"} onClick={() => setEdit(true)}>
                            <FontAwesomeIcon icon={faPencilAlt} className="material-icons"/>
                        </Button>
                    )}
                    {edit && (
                        <Button variant={"link"} onClick={() => saveQuiz()}>
                            <FontAwesomeIcon icon={faSave} className="material-icons"/>
                        </Button>
                    )}
                    <Button variant={"link"} onClick={() => quiz.id && deleteQuiz(quiz.id)}>
                        <FontAwesomeIcon icon={faTrashAlt} className="material-icons text-danger"/>
                    </Button>
                </div>
            </div>
        </div>
    </div>
}

export default QuizListItem