import React from 'react';
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import history from "../../../utils/history";
import {ICourse} from "../../../interfaces/ICourse";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import {useMutation, useQueryClient} from "react-query";
import {lessons} from "../../../api/lessons";
import {ILesson} from "../../../interfaces/ILesson";
import {toast} from "react-toastify";

type LessonHeaderProps = {
    title?: string,
    saveLesson: () => void,
    loading: boolean,
    course?: ICourse,
    lessonSlug?: string,
    lesson?: ILesson,
}

const LessonHeader = (props: LessonHeaderProps) => {
    const {t} = useTranslation()
    const {saveLesson, loading, title, course, lessonSlug, lesson} = props
    const queryClient = useQueryClient()

    const deleteLessonMutation = useMutation(
        (lessonId: string) => lessons.remove(lessonId),
        {
            onSuccess: () => {
                toast.success(t('Lesson deleted'))
                history.push(`/admin/courses/${course?.slug}/edit`)
            },
            onError: () => {
                toast.error(t('Lesson could not be deleted'))
            },
            onSettled: () => {queryClient.invalidateQueries('courses')}
        }
    )

    const deleteLesson = (lessonId: string) => {
        deleteLessonMutation.mutate(lessonId)
    }

    return (
        <div className="media align-items-center mb-headings">
            <div className="media-body">
                <h1 className="h2">
                    {title}
                </h1>
            </div>

            <div className="media-left ml-0">
                <Button
                    onClick={() => {
                        lesson?.id && deleteLesson(lesson.id)
                    }}
                    variant='link'
                    className='text-danger'
                >
                    <FontAwesomeIcon icon={faTrashAlt} className={`material-icons md-18 mr-2 text-danger`} />
                    {! deleteLessonMutation.isLoading && t('delete')}
                    {deleteLessonMutation.isLoading && t('deleting') + '...'}
                </Button>
            </div>

            <div className="media-right">
                {course && lessonSlug && (
                    <Link
                        to={`/course/${course.slug}/${lessonSlug}`}
                        target='_blank'
                    >
                        {t('preview')}
                    </Link>
                )}
                <Button
                    onClick={() => history.goBack()}
                    variant='link'
                >
                    {t('cancel')}
                </Button>
                <Button
                    onClick={() => saveLesson()}
                >
                    {loading && t('SAVING') + '...'}
                    {!loading && t('SAVE')}
                </Button>
            </div>
        </div>
    )
};

export default LessonHeader;