import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons/faCheckCircle";

type ForgotPasswordProps = {}

const ForgotPassword = (props: ForgotPasswordProps) => {
    const {t} = useTranslation()

    return (
        <div className="d-flex align-items-center" style={{minHeight: "100vh"}}>
            <div className="col-sm-8 col-md-6 col-lg-4 mx-auto" style={{minWidth: "300px;"}}>
                <div className="text-center mt-5 mb-1">
                    <div className="avatar avatar-lg">
                        <img src="assets/images/logo/primary.svg" className="avatar-img rounded-circle"
                             alt="LearnPlus"/>
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-5 navbar-light">
                    <a href="{{ prefix }}dashboard.html" className="navbar-brand m-0">
                        LearnPlus
                    </a>
                </div>
                <div className="card navbar-shadow">
                    <div className="card-header text-center">
                        <h4 className="card-title">Forgot Password?</h4>
                        <p className="card-subtitle">Recover your account password</p>
                    </div>
                    <div className="card-body">

                        <div className="alert alert-light border-1 border-left-3 border-left-primary d-flex"
                             role="alert">
                            <FontAwesomeIcon icon={faCheckCircle} style={{color: 'green'}}  className="material-icons text-success mr-3"/>
                            <div className="text-body">
                                An email with password reset instructions has been sent to your email address, if it exists on our system.
                            </div>
                        </div>

                        <form action="student-dashboard.html" noValidate method="get">
                            <div className="form-group">
                                <label className="form-label" htmlFor="email">Email address:</label>
                                <div className="input-group input-group-merge">
                                    <input id="email" type="email" required
                                           className="form-control form-control-prepended"
                                           placeholder="Your email address"/>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <span className="far fa-envelope"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" className="btn btn-primary btn-block">Send instructions</button>
                        </form>
                    </div>
                    <div className="card-footer text-center text-black-50">
                        Remember your password?
                        <Link to={'/login'}>{t('Login')}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ForgotPassword;