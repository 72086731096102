import React, {useContext} from 'react';
import ContentHeader from "./ContentHeader";
import ContentVideo from "./ContentVideo";
import ContentText from "./ContentText";
import {Helmet} from "react-helmet";
import './Content.scss'
import {observer} from "mobx-react";
import UserLessonContext from '../../../contexts/UserLessonContext';
import CourseContext from "../../../contexts/CourseContext";
import {ILesson} from "../../../interfaces/ILesson";
import {ICourse} from '../../../interfaces/ICourse';
import persistentStore from "../../../stores/persistentStore";

type ContentProps = {}

const Content = (props: ContentProps) => {
    const lesson: ILesson | undefined = useContext(UserLessonContext)
    const course: ICourse | undefined = useContext(CourseContext)


    if(course.lessons && course.lessons.length === 0){
        return <div>Deze training heeft geen lessen</div>
    }

    return (
        <>
            {(course) && (
                <div className='content'>
                    <Helmet>
                        <title>{`${course?.title}: ${lesson?.title || ''}` || 'TrainingsOmgeving'}</title>
                        <meta
                            property="og:title"
                            content={`${course?.title}: ${lesson?.title || ''}` || 'TrainingsOmgeving'}
                        />
                        <meta
                            property="og:description"
                            content={`${course?.description}` || 'TrainingsOmgeving'}
                        />
                        <meta property="og:image"
                              content={`${persistentStore.apiUrl}${course?.image_url}` || `${process.env.PUBLIC_URL}/img/trainingsomgeving.jpeg`}
                        />
                        <meta property="og:url" content="https://trainingsomgeving.nl"/>
                        <meta name="twitter:title"
                              content={`${course?.title}: ${lesson?.title}` || 'TrainingsOmgeving'}/>
                        <meta name="twitter:description" content={`${course?.description}` || 'TrainingsOmgeving'}/>
                        <meta name="twitter:image"
                              content={`${persistentStore.apiUrl}${course?.image_url}` || `${process.env.PUBLIC_URL}/img/trainingsomgeving.jpeg`}/>

                        <meta name="description" content={`${course?.description}` || 'TrainingsOmgeving'}/>

                    </Helmet>

                    <ContentHeader/>
                    <ContentVideo/>
                    <ContentText/>
                </div>
            )}
        </>
    )
};

export default observer(Content)