import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";

type PaginationProps = {
    records: any[],
    pageSize?: number,
    setPaginatedRecords: (records: any[]) => void
}

const Pagination = (props: PaginationProps) => {
    const {records, pageSize} = props
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const [paginatedRecords, setPaginatedRecords] = useState<unknown[]>([])
    const {t} = useTranslation()

    const chunk = (inputArray: any[], length: number): any[] => {
        if (inputArray.length === 0) {
            return inputArray
        }

        return inputArray.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / length)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])
    }

    useEffect(() => {
        if (currentPage) {
            setCurrentPageIndex(currentPage - 1)
        }
    }, [currentPage])

    const updatePageWithRecords = (records: unknown[]) => {
        props.setPaginatedRecords(records)
    }

    // cut the records up in pieces of pageSize size
    useEffect(() => {
        if(records.length > 0){
            const chunkedRecords = chunk(records, pageSize || 10)
            setPaginatedRecords(chunkedRecords)

            // if a specific page is requested, return that
            if (currentPageIndex > 0 && currentPageIndex < chunkedRecords.length) {
                props.setPaginatedRecords(chunkedRecords[currentPageIndex])
            }
            // return the first page of paginated results
            else if (chunkedRecords.length > 0) {
                props.setPaginatedRecords(chunkedRecords[0])
            }
        }
        // eslint-disable-next-line
    }, [records])

    const pageClicked = (page: number) => {
        setCurrentPage(page)
        setCurrentPageIndex(page-1)
        const newPage: unknown[] = paginatedRecords[page-1] as unknown[]
        updatePageWithRecords(newPage)
    }

    const previousePage = () => {
        pageClicked(currentPage-1)
    }

    const nextPage = () => {
        pageClicked(currentPage+1)
    }

    return (
        <>
        {pageSize && records.length > pageSize && (
        <ul className="pagination justify-content-center pagination-sm">
            <li className={`page-item ${currentPageIndex <= 0 ? 'disabled' : ''}`}>
                <Button variant='link' className="page-link" onClick={previousePage}>
                    <FontAwesomeIcon icon={faChevronLeft} className='material-icons mr-2'/>
                    <span>{t('Prev')}</span>
                </Button>
            </li>

            {paginatedRecords.map((element: unknown, index: number) => (
                <li className={`page-item ${currentPageIndex === (index) ? 'active' : ''}`} key={index}>
                    <Button className="page-link" variant='link' onClick={() => pageClicked(index + 1)}>
                        <span>{index + 1}</span>
                    </Button>
                </li>
            ))}

            <li className={`page-item ${currentPageIndex >= (paginatedRecords.length-1) ? 'disabled' : ''}`}>
                <Button variant='link' className="page-link" onClick={nextPage}>
                    <span>{t('Next')}</span>
                    <FontAwesomeIcon icon={faChevronRight} className='material-icons ml-2'/>
                </Button>
            </li>
        </ul>)}

        </>
    )
}

export default Pagination;