import React, {useEffect, useState} from 'react';
import {Switch} from "react-router-dom";
import AdminCoursesPage from "../pages/Admin/Courses/CourseManager";
import NavBar from "../parts/NavBar/NavBar";
import SideBar from "../parts/SideBar/SideBar";
import CourseEdit from "../pages/Admin/Courses/CourseEdit";
import AccountEdit from "../pages/Admin/Account/AccountEdit";
import CourseAdd from "../pages/Admin/Courses/CourseAdd";
import LessonAdd from "../pages/Admin/Lessons/LessonAdd";
import LessonEdit from "../pages/Admin/Lessons/LessonEdit";
import Dashboard from "../pages/Admin/Dashboard/Dashboard";
import QuizManager from "../pages/Admin/Quizzes/QuizManager";
import QuizEdit from "../pages/Admin/Quizzes/QuizEdit";
import QuizAdd from "../pages/Admin/Quizzes/QuizAdd";
import TrainerSubscription from "../pages/Admin/Account/TrainerSubscription";
import PaymentHistory from "../pages/Admin/Account/PaymentHistory";
import CoursesContext from "../contexts/CoursesContext";
import SettingContext from "../contexts/SettingContext";
import UserContext from "../contexts/UserContext";
import PrivateRoute from "../utils/PrivateRoute";
import {ICourse} from "../interfaces/ICourse";
import {useQuery} from "react-query";
import persistentStore from "../stores/persistentStore";
import {courses as coursesApi} from "../api/courses";
import history from "../utils/history";
import {toJS} from "mobx";
import CourseStudents from "../pages/Admin/CourseStudents/CourseStudents";
import PaymentSettings from "../pages/Admin/Account/PaymentSettings";
import {ISetting} from "../interfaces/ISetting";
import {settings as settingsApi} from "../api/settings";
import EventsPage from "../pages/Admin/Events/EventsPage";
import UsersPage from "../pages/Admin/Users/UsersPage";
import UsersDetailsPage from "../pages/Admin/Users/UsersDetailsPage";
import StudentDetailsPage from "../pages/Admin/Users/StudentDetailsPage";

const AdminRoutes = () => {
    const [courses, setCourses] = useState<ICourse[]>([])
    const [settings, setSettings] = useState<ISetting[]>([])

    // get courses
    const coursesQuery = useQuery(
        'courses',
        () => {
            if (persistentStore.user) {
                return coursesApi.getFromTrainer(persistentStore.user.id)
            }
        },
        {
            enabled: persistentStore.user !== undefined,
            onSuccess: (data) => {
                // console.log('Success!')
                // console.log(data)
                setCourses(data || [])
            }
        }
    )

    const settingsQuery = useQuery(
        'settings',
        () => {
            if (persistentStore.user?.id) {
                return settingsApi.getForUser(persistentStore.user?.id)
            }
        },
        {
            enabled: persistentStore.user !== undefined,
            onSuccess: (data) => {
                // console.log('Success!')
                // console.log(data)
                setSettings(data || [])
            }
        }
    )

    useEffect(() => {
        if (coursesQuery.data) {
            setCourses(coursesQuery.data)
        }

        if(settingsQuery.data){
            setSettings(settingsQuery.data)
        }

    }, [coursesQuery.data, settingsQuery.data])

    if (!persistentStore.user) {
        return history.push('/admin/login')
    }

    return (
        <div className="mdk-header-layout js-mdk-header-layout">
            <UserContext.Provider value={toJS(persistentStore.user)}>
                <SettingContext.Provider value={settings}>

                    <div
                        id="header"
                        className="mdk-header js-mdk-header mb-0"
                    >
                        <div className="mdk-header__bg">
                            <div className="mdk-header__bg-front"/>
                            <div className="mdk-header__bg-rear"/>
                        </div>
                        <div className="mdk-header__content">
                            <NavBar UserLoggedIn={true}/>
                        </div>
                    </div>

                    <div className="mdk-header-layout__content" style={{paddingTop: '64px'}}>

                        <CoursesContext.Provider value={courses}>
                            <div
                                data-responsive-width="992px"
                                className="mdk-drawer-layout js-mdk-drawer-layout"
                            >
                                <div className="mdk-drawer-layout__content page">

                                    <Switch>
                                        <PrivateRoute exact path='/admin' component={Dashboard}/>
                                        <PrivateRoute exact path='/admin/account/edit' component={AccountEdit}/>
                                        <PrivateRoute exact path='/admin/account/subscription'
                                                      component={TrainerSubscription}/>
                                        <PrivateRoute exact path='/admin/account/payment-history'
                                                      component={PaymentHistory}/>
                                        <PrivateRoute exact path='/admin/account/payment-settings'
                                                      component={PaymentSettings}/>
                                        <PrivateRoute exact path='/admin/courses' component={AdminCoursesPage}/>
                                        <PrivateRoute exact path='/admin/course-manager' component={AdminCoursesPage}/>
                                        <PrivateRoute exact path='/admin/courses/add' component={CourseAdd}/>
                                        <PrivateRoute exact path='/admin/courses/:courseSlug/edit'
                                                      component={CourseEdit}/>
                                        <PrivateRoute exact path='/admin/courses/:courseSlug/students'
                                                      component={CourseStudents}/>
                                        <PrivateRoute exact path='/admin/courses/:courseSlug/lesson/add'
                                                      component={LessonAdd}/>
                                        <PrivateRoute exact path='/admin/courses/:courseSlug/lessons/edit/:lessonSlug'
                                                      component={LessonEdit}/>
                                        <PrivateRoute exact path='/admin/dashboard' component={Dashboard}/>
                                        <PrivateRoute exact path='/admin/events' component={EventsPage}/>
                                        <PrivateRoute exact path='/admin/lessons/edit/:lessonSlug'
                                                      component={LessonEdit}/>
                                        <PrivateRoute exact path='/admin/users/:userId' component={UsersDetailsPage}/>
                                        <PrivateRoute exact path='/admin/students/:userId' component={StudentDetailsPage}/>
                                        <PrivateRoute exact path='/admin/users' component={UsersPage}/>

                                        {/***** Quizzes Routes ******/}
                                        <PrivateRoute exact path='/admin/quiz-manager' component={QuizManager}/>
                                        <PrivateRoute exact path='/admin/quizzes/add/' component={QuizAdd}/>
                                        <PrivateRoute exact path='/admin/quizzes/:quizSlug/edit' component={QuizEdit}/>
                                        
                                    </Switch>
                                </div>

                                <SideBar/>

                                {/*{{ mainSidebar.component(*/}
                                {/*        prefix=prefix,*/}
                                {/*        slug=slug,*/}
                                {/*        account_menu=account_menu,*/}
                                {/*        components_menu=components_menu,*/}
                                {/*        plugins_menu=plugins_menu,*/}
                                {/*        messages_menu=messages_menu,*/}
                                {/*        community_menu=community_menu*/}
                                {/*    ) }}*/}
                            </div>
                        </CoursesContext.Provider>
                    </div>
                </SettingContext.Provider>
            </UserContext.Provider>
        </div>
    )
}

export default AdminRoutes;
