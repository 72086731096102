import React from 'react';

type PageNotFoundProps = {}

const PageNotFound = (props: PageNotFoundProps) => {

    return (
        <div>page not found</div>
    )
};

export default PageNotFound;