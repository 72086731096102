import axios from "axios";
import persistentStore from "../stores/persistentStore";
import errorChecker from "./errorchecker";

const subscriptionRequests = () => {

    const ofUser = async (userId: string) => {
        const path = `/subscriptions/of-user/${userId}`

        return axios.get(persistentStore.apiUrl + path,  persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.subscriptions) {
                    throw Error('The response did not contain subscriptions');
                }

                return data.subscriptions
            })
    }

    return {
        ofUser,
    }
}

export const subscriptions = subscriptionRequests()
