import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {users as usersApi} from "../../../api/users";
import {useQuery, useQueryClient} from "react-query";
import {useHistory, useParams, Link} from "react-router-dom";
import {ICourse} from "../../../interfaces/ICourse";
import {IUser} from "../../../interfaces/IUser";
import Loader from "../../../components/Loader";
import EventTable from "../Events/EventTable";
import Button from "react-bootstrap/Button";
import MailTable from "./MailTable";
import SubscriptionsTable from "./SubscriptionsTable";
import {IBreadCrumb} from "../../../interfaces/ICrumb";
import BreadCrumb from "../../../components/BreadCrumb";

const UsersDetailsPage = () => {
    const {t} = useTranslation()
    const [user, setUser] = useState<IUser>()
    const {userId} = useParams<{ userId: string }>()
    const history = useHistory()
    const queryClient = useQueryClient()

    const userQuery = useQuery(
        ['getUser', userId],
        () => usersApi.getOne(userId)
    )

    const impersonate = () => {
        if (userQuery.data?.id === undefined) {
            console.error('No id known for user. Cannot impersonate')
            return
        }

        usersApi.impersonate(userQuery.data?.id)
            .then((result) => {
                const user: IUser = result.data.user

                // clear cached querydata
                queryClient.invalidateQueries('courses')
                queryClient.invalidateQueries('settings')

                // trainers/admins: push to dashboard
                if (user.is_admin || result.data.user.is_trainer) {
                    return history.push('/admin/dashboard')
                }

                // students: push to the first course they have access to
                if (user.Courses && user.Courses.length > 0) {
                    const firstCourse: ICourse = user.Courses[0]
                    return history.push(`/course/${firstCourse.slug}`)
                }

                console.error('Impersonation happened, but user is neither admin, trainer or student')
                console.error(result.data)
            })
    }

    useEffect(() => {
        if(userQuery.data){
            setUser(userQuery.data)
        }
    }, [userQuery.data])

    if(!user){
        return <Loader />
    }

    const crumbs: IBreadCrumb[] = [
        {
            name: t('Home'),
            active: false,
            link: '/admin/dashboard'
        },
        {
            name: t('User Details'),
            active: false,
            link: ''
        },
    ]

    return (
        <div className="{{ containerClass }} page__container p-0">
            <div className="row m-0">
                <div className="col-lg container-fluid page__container">
                    <BreadCrumb crumbs={crumbs}/>

                    <h1 className="h2">{t('User Details')}</h1>
                    <div className="card">
                        <div className="list-group list-group-fit">
                            <div className="list-group-item">
                                <div className="form-group m-0" role="group" aria-labelledby="label-firstname">
                                    <div className="form-row">
                                        <label
                                            id="label-firstname"
                                            htmlFor="firstname"
                                            className="col-md-3 col-form-label form-label"
                                        >
                                            {t('Name')}
                                        </label>
                                        <div className="col-md-9">
                                            {user.name}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="list-group-item">
                                <div className="form-group m-0" role="group" aria-labelledby="label-lastname">
                                    <div className="form-row">
                                        <label
                                            id="label-lastname"
                                            htmlFor="lastname"
                                            className="col-md-3 col-form-label form-label"
                                        >
                                            {t('Email')}
                                        </label>
                                        <div className="col-md-9">
                                            {user.email}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="list-group-item">
                                <div className="form-group m-0" role="group" aria-labelledby="label-lastname">
                                    <div className="form-row">
                                        <label
                                            id="label-lastname"
                                            htmlFor="lastname"
                                            className="col-md-3 col-form-label form-label"
                                        >
                                            {t('Courses')}
                                        </label>
                                        <div className="col-md-9">
                                            <ul>
                                                {user.Courses?.map((course, index) => (
                                                    <li key={index}>
                                                        <Link to={`/course/${course.slug}`}>{course.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="list-group-item">
                                <div className="form-group m-0" role="group" aria-labelledby="label-lastname">
                                    <div className="form-row">
                                        <label
                                            id="label-lastname"
                                            htmlFor="lastname"
                                            className="col-md-3 col-form-label form-label"
                                        >
                                            {t('Type')}
                                        </label>
                                        <div className="col-md-9">
                                            {user.is_admin && 'Admin'}
                                            {(!user.is_admin && user.is_trainer) && 'Trainer'}
                                            {(!user.is_admin && ! user.is_trainer) && 'Student'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h4>{t('Subscriptions')}</h4>

                    <div className="card">
                        <SubscriptionsTable userId={user.id} />
                    </div>

                    <h4>{t('Mails')}</h4>

                    <div className="card">
                        <MailTable userId={user.id} />
                    </div>

                    <h4>{t('Events')}</h4>

                    <div className="card">
                        <EventTable events={user.Events || []} isLoading={userQuery.isLoading} />
                    </div>
                </div>

                <div
                    id="page-nav"
                    className="col-lg-auto page-nav">
                    <div data-perfect-scrollbar>
                        <div className="page-section pt-lg-32pt">
                            <ul className="nav page-nav__menu">
                                <li className="nav-item">
                                    <a href="{{ prefix }}account-edit-basic.html"
                                       className="nav-link{% if 'basic' in slug %} active{% endif %}">Basic
                                        Information</a>
                                </li>
                                <li className="nav-item">
                                    <a href="{{ prefix }}account-edit-profile.html"
                                       className="nav-link{% if 'profile' in slug %} active{% endif %}">Profile &amp; Privacy</a>
                                </li>
                            </ul>
                            <div className="page-nav__content">
                                <button className="btn btn-success">{t('Save Changes')}</button>
                            </div>

                            <div className="page-nav__content mt-2">
                                <Button className="btn btn-success" onClick={impersonate}>
                                    {t('Impersonate')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersDetailsPage;
